import React, { Component } from "react";
import { connect } from "react-redux";
import Editpage from "../Components/editpage";

import {
  viewOldCarById,
  viewOverviewById,
  viewSpecificationById,
  viewFeturesById,
  editCarinfo,
  editCarOverview,
  editCarSpecification,
  editCarFeature,
} from "../action";
export class Controller extends Component {
  render() {
    return <Editpage {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewOldCarById: (token, user_id, id) => {
      dispatch(viewOldCarById(token, user_id, id));
    },

    viewOverviewById: (token, user_id, id) => {
      dispatch(viewOverviewById(token, user_id, id));
    },

    viewSpecificationById: (token, user_id, id) => {
      dispatch(viewSpecificationById(token, user_id, id));
    },
    viewFeturesById: (token, user_id, id) => {
      dispatch(viewFeturesById(token, user_id, id));
    },

    editCarinfo: (
      token,
      user_id,
      id,
      brand,
      carName,
      fuelType,
      carType,
      price,
      discountedPrice,
      kilometer,
      images,
      oldImages
    ) => {
      dispatch(
        editCarinfo(
          token,
          user_id,
          id,
          brand,
          carName,
          fuelType,
          carType,
          price,
          discountedPrice,
          kilometer,
          images,
          oldImages
        )
      );
    },

    editCarOverview: (
      token,
      user_id,
      id,
      registerationYear,
      manufacturingYear,
      owners,
      transmission,
      color,
      insurance,
      registerationType,
      description
    ) => {
      dispatch(
        editCarOverview(
          token,
          user_id,
          id,
          registerationYear,
          manufacturingYear,
          owners,
          transmission,
          color,
          insurance,
          registerationType,
          description
        )
      );
    },
    editCarSpecification: (
      token,
      user_id,
      id,
      engine,
      // engineType,
      maxPower,
      maxTorque,
      mileage,
      driveTrain,
      // turbocharger,
      length,
      width,
      height,
      wheelbase,
      groundClearance,
      kerbWeight,
      doors,
      seatingCapacity,
      rows,
      bootspace,
      fuelTankCapacity,
      frontSuspension,
      rearSuspension,
      frontbrakeType,
      rearBrakeType,
      minTurningRadius,
      steeringType,
      wheels,
      spareWheel,
      frontTyres,
      rearTyres
    ) => {
      dispatch(
        editCarSpecification(
          token,
          user_id,
          id,
          engine,
          // engineType,
          maxPower,
          maxTorque,
          mileage,
          driveTrain,
          // turbocharger,
          length,
          width,
          height,
          wheelbase,
          groundClearance,
          kerbWeight,
          doors,
          seatingCapacity,
          rows,
          bootspace,
          fuelTankCapacity,
          frontSuspension,
          rearSuspension,
          frontbrakeType,
          rearBrakeType,
          minTurningRadius,
          steeringType,
          wheels,
          spareWheel,
          frontTyres,
          rearTyres
        )
      );
    },
    editCarFeature: (
      token,
      user_id,
      id,
      airbag,
      tyrePresssure,
      childSeatAnchorPoints,
      seatBeltWarning,
      // des1,
      abs,
      ebd,
      ba,
      esp,
      fourWheel,
      hillHoldControl,
      tcs,
      // rideHeightAdjustment,
      hillDescentControl,
      // des2,
      engineImmobiliser,
      centeralLocking,
      speedSensingDoorLock,
      childSafetyLock,
      ac,
      frontac,
      rearac,
      heater,
      vanityMirror,
      antiglareMirror,
      parkingAssist,
      parkingSensor,
      cruisecontrol,
      headlightIgnition,
      keylessstart,
      steeringAdjustment,
      power,
      // des3,
      applecarplay,
      androidauto,
      // des4,
      seatUpholestry,
      drivesideAdjust,
      leatherWrapped,
      driverArmrest,
      // rearPassengerSeatType,
      // interiors,
      // interiorsColor,
      foldingRearSeat,
      splitRearSeat,
      splitThirdRowSeat,
      frontSeatbackPockets,
      headrests,
      // des5,
      cupholders,
      driverArmrestStorage,
      cooledGloveBox,
      sunGlassHolder,
      thirdRowCupHolder,
      // des6,
      orvm,
      powerWindow,
      oneTouchDown,
      oneTouchUp,
      adjustableOrvm,
      exteriorDoorHandle,
      chromeexteriorDoorHandle,
      doorPockets,
      bootlidOpener,
      // des7,
      sunroof,
      roofMountedAntenna,
      bodyColouredBumpers,
      chromeFinish,
      // des8,
      projectorheadlight,
      autoheadlamps,
      homeheadlamps,
      drl,
      tailights,
      cabinLamps,
      lightonVanityMirror,
      rearreadinglamp,
      gloveBoxlamp,
      headlightHeightAdjuster,
      // des9,
      // instantaneousConsumption,
      // instrumentCluster,
      tripMeter,
      averageFuelConsumption,
      // averageSpeed,
      distancetoEmpty,
      clock,
      lowFuelWarning,
      doorAjarWarning,
      adjustableCluster,
      gearIndicator,
      shiftIndicator,
      tachometer,
      // des10,
      // display,
      musicSystem,
      speaker,
      steeringmountedctrls,
      voiceCommand,
      gps,
      bluetooth,
      aux,
      radio,
      usb,
      headUnitSize,
      // iPod,
      // des11,
      // warrrantyyear,
      // warrrantykm,
      des12
    ) => {
      dispatch(
        editCarFeature(
          token,
          user_id,
          id,
          airbag,
          tyrePresssure,
          childSeatAnchorPoints,
          seatBeltWarning,
          // des1,
          abs,
          ebd,
          ba,
          esp,
          fourWheel,
          hillHoldControl,
          tcs,
          // rideHeightAdjustment,
          hillDescentControl,
          // des2,
          engineImmobiliser,
          centeralLocking,
          speedSensingDoorLock,
          childSafetyLock,
          ac,
          frontac,
          rearac,
          heater,
          vanityMirror,
          antiglareMirror,
          parkingAssist,
          parkingSensor,
          cruisecontrol,
          headlightIgnition,
          keylessstart,
          steeringAdjustment,
          power,
          // des3,
          applecarplay,
          androidauto,
          // des4,
          seatUpholestry,
          drivesideAdjust,
          leatherWrapped,
          driverArmrest,
          // rearPassengerSeatType,
          // interiors,
          // interiorsColor,
          foldingRearSeat,
          splitRearSeat,
          splitThirdRowSeat,
          frontSeatbackPockets,
          headrests,
          // des5,
          cupholders,
          driverArmrestStorage,
          cooledGloveBox,
          sunGlassHolder,
          thirdRowCupHolder,
          // des6,
          orvm,
          powerWindow,
          oneTouchDown,
          oneTouchUp,
          adjustableOrvm,
          exteriorDoorHandle,
          chromeexteriorDoorHandle,
          doorPockets,
          bootlidOpener,
          // des7,
          sunroof,
          roofMountedAntenna,
          bodyColouredBumpers,
          chromeFinish,
          // des8,
          projectorheadlight,
          autoheadlamps,
          homeheadlamps,
          drl,
          tailights,
          cabinLamps,
          lightonVanityMirror,
          rearreadinglamp,
          gloveBoxlamp,
          headlightHeightAdjuster,
          // des9,
          // instantaneousConsumption,
          // instrumentCluster,
          tripMeter,
          averageFuelConsumption,
          // averageSpeed,
          distancetoEmpty,
          clock,
          lowFuelWarning,
          doorAjarWarning,
          adjustableCluster,
          gearIndicator,
          shiftIndicator,
          tachometer,
          // des10,
          // display,
          musicSystem,
          speaker,
          steeringmountedctrls,
          voiceCommand,
          gps,
          bluetooth,
          aux,
          radio,
          usb,
          headUnitSize,
          // iPod,
          // des11,
          // warrrantyyear,
          // warrrantykm,
          des12
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
