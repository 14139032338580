import React, { Component } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { withStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const styles = {
  container: {
    paddingTop: 20,
    paddingBottom: 20,
    width: "100%",
  },
  paper: {
    padding: 20,
    textAlign: "left",
    backgroundColor: "#f5f5f5",
    width: "100%",
    boxSizing: "border-box",
  },
  title: {
    marginBottom: 20,
    fontWeight: "bold",
  },
  textField: {
    marginBottom: 20,
  },
  button: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  formRow: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    marginBottom: "20px",
    flexDirection: "column",
    "@media (min-width:600px)": {
      flexDirection: "row",
    },
  },
  halfField: {
    flex: 1,
    minWidth: 200,
  },
  formCheckRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    flexDirection: "row",
    "@media (min-width:600px)": {
      flexDirection: "row",
    },
  },
};

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname:'',
      lname:'',
      mobile:'',
      email:'',
      pincode:'',
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      pincode: "",
      buyDays: "",
      consentChecked: false,
      errors: {},
    };
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handleCheckboxChange = (event) => {
    this.setState({ consentChecked: event.target.checked });
  };

  validateForm = () => {
    const {
      firstName,
      lastName,
      mobile,
      email,
      pincode,
      buyDays,
      consentChecked,
    } = this.state;
    const errors = {};

    if (!firstName) errors.firstName = "First Name is required.";
    if (!lastName) errors.lastName = "Last Name is required.";
    if (!mobile) errors.mobile = "Mobile Number is required.";
    if (!email) errors.email = "Email ID is required.";
    if (!pincode) errors.pincode = "Pincode is required.";
    if (!buyDays) errors.buyDays = "Please select when you plan to buy.";
    if (!consentChecked) errors.consentChecked = "Consent is required.";

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleSubmit = (event) => {
    const {
      firstName,
      lastName,
      mobile,
      email,
      pincode,
      buyDays,
      consentChecked,
    } = this.state;
    event.preventDefault();
    if (this.validateForm()) {
      console.log("Form data:", this.state);
      // Perform further actions, like sending the data to a backend server
      this.props.kepInTouch(
        firstName,
        lastName,
        mobile,
        email,
        pincode,
        buyDays,
        consentChecked
      );
      this.setState({
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        pincode: "",
        buyDays: "",
        consentChecked: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { errors } = this.state;

    return (
      <Container className={classes.container}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={8} md={10}>
            <Paper className={classes.paper}>
              <Typography
                variant="h5"
                className={classes.title}
                sx={{ color: "#CF2230", textAlign: "center" }}
              >
                Keep In Touch
              </Typography>

              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={this.handleSubmit}
              >
                <div className={classes.formRow}>
                  <div className={classes.halfField}>
                    <Typography>
                      First Name <sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      variant="standard"
                      value={this.state.firstName}
                      onChange={(e) =>
                        this.handleChange("firstName", e.target.value)
                      }
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                  </div>
                  <div className={classes.halfField}>
                    <Typography>
                      Last Name <sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      variant="standard"
                      value={this.state.lastName}
                      onChange={(e) =>
                        this.handleChange("lastName", e.target.value)
                      }
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </div>
                </div>

                <div className={classes.formRow}>
                  <div className={classes.halfField}>
                    <Typography>
                      Mobile Number <sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      variant="standard"
                      value={this.state.mobile}
                      onChange={(e) =>
                        this.handleChange("mobile", e.target.value)
                      }
                      error={!!errors.mobile}
                      helperText={errors.mobile}
                    />
                  </div>
                  <div className={classes.halfField}>
                    <Typography>
                      Email ID (name@example.com){" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      variant="standard"
                      value={this.state.email}
                      onChange={(e) =>
                        this.handleChange("email", e.target.value)
                      }
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </div>
                </div>

                <div className={classes.formRow}>
                  <div className={classes.halfField}>
                    <Typography>
                      Pincode <sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <TextField
                      fullWidth
                      required
                      variant="standard"
                      value={this.state.pincode}
                      onChange={(e) =>
                        this.handleChange("pincode", e.target.value)
                      }
                      error={!!errors.pincode}
                      helperText={errors.pincode}
                    />
                  </div>

                  <div className={classes.halfField}>
                    <Typography>
                      When do you plan to buy?{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <FormControl
                      fullWidth
                      required
                      variant="standard"
                      error={!!errors.buyDays}
                    >
                      <InputLabel>Select Days</InputLabel>
                      <Select
                        value={this.state.buyDays}
                        onChange={(e) =>
                          this.handleChange("buyDays", e.target.value)
                        }
                      >
                        <MenuItem value="15">0-15 days</MenuItem>
                        <MenuItem value="30">15-30 days</MenuItem>
                        <MenuItem value="60">30-60 days</MenuItem>
                        <MenuItem value="90">60-90 days</MenuItem>
                      </Select>
                      <Typography color="error">{errors.buyDays}</Typography>
                    </FormControl>
                  </div>
                </div>

                <div className={classes.formCheckRow}>
                  <Checkbox
                    checked={this.state.consentChecked}
                    onChange={this.handleCheckboxChange}
                    color="primary"
                  />
                  <Typography>
                    I authorize Super Sales Automobiles Private Limited(MG
                    Central) to save my contact details.
                  </Typography>
                </div>
                {errors.consentChecked && (
                  <Typography color="error">{errors.consentChecked}</Typography>
                )}

                <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(ContactUs);
