import React from 'react';
import {
  Typography, Container, Grid, Card, CardMedia, CardContent, CardActions, Button, Box, 
} from '@mui/material';
import './Pricing.css';
import Hectorimg from '../../images/Pricing/Hector.webp';
import HectorPrice from '../../Price downloads/Price List Hector.pdf';
import Astorimg from '../../images/Pricing/Astor.webp';
import AstorPrice from '../../Price downloads/Price List Astor.pdf'
import Zsimg from '../../images/Pricing/zs.webp';
import ZsPrice from '../../Price downloads/Price List ZS EV.pdf';
import Cometimg from '../../images/Pricing/Comet.jpg';
import CometPrice from '../../Price downloads/Price List Comet EV.pdf';
import Windsorimg from '../../images/Pricing/windsor.avif'
import WindsorPrice from '../../Price downloads/Price List Windsor EV.pdf';
import Glosterimg from '../../images/Pricing/gloster.avif'

const cars = [
  {
    id: 1,
    name: "MG HECTOR",
    image: Hectorimg,
    pdf: HectorPrice,
  },
  {
    id: 2,
    name: "MG ASTOR",
    image: Astorimg,
    pdf: AstorPrice,
  },
  {
    id: 3,
    name: "MG ZS EV",
    image: Zsimg,
    pdf: ZsPrice ,
  },
  {
    id: 4,
    name: "MG COMET EV",
    image: Cometimg,
    pdf: CometPrice,
  },
  {
    id: 5,
    name: "MG WINDSOR EV",
    image: Windsorimg,
    pdf: WindsorPrice,
  },
  {
    id: 6,
    name: "MG Gloster",
    image: Glosterimg,
    pdf: WindsorPrice,
  },
];

const PricePage = () => {
  return (
    <div style={{ marginTop: '30px',backgroundColor:'black', }}>
        <div id="banner-image-price">
        </div>
      {/* Car Price List */}
      <Container >
        <Grid container spacing={3}>
          {cars.map((car) => (
            <Grid item xs={12} sm={6} md={4} key={car.id}>
              <Card sx={{marginBottom:'20px'}}>
                <CardMedia
                  component="img"
                  height="200"
                  image={car.image}
                  alt={car.name}
                />
                <CardContent>
                  <Typography variant="h6" sx={{fontWeight:'bolder',textAlign:'center'}}>{car.name}</Typography>
                </CardContent>
                <CardActions>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    fullWidth 
                    onClick={() => window.open(car.pdf, "_blank")}
                    sx={{backgroundColor:'#C62828',fontWeight:'900'}}
                  >
                    Price
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default PricePage;