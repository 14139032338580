import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import redhect from '../../../../images/REHECTOR.webp';
import yellowhect from '../../../../images/hector-image.webp';
import whitehect from '../../../../images/whitehector.webp';
import greyhect from '../../../../images/greyhector.webp';
import whiteblackhect from '../../../../images/White-blacector.webp';
import blackhect from '../../../../images/blackhector.webp';
import silverhect from '../../../../images/silverhector.webp';

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [fixedImage, setFixedImage] = useState(redhect); 

  const carImages = [
    { src: redhect, alt: "redhector", color: "#F64752" },
    { src: yellowhect, alt: "yellowhector", color: "#594B3F" },
    { src: whitehect, alt: "Whitehector", color: "#FFF" },
    { src: greyhect, alt: "greyhector", color: "#3D3941" },
    { src: whiteblackhect, alt: "white-blackhector", color: "#E9E9E9" },
    { src: blackhect, alt: "blackhector", color: "black" },
    { src: silverhect, alt: "silverhector", color: "#A1A4AD" },
  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
      <Carousel 
        activeIndex={activeIndex} 
        onSelect={handleSelect} 
        interval={null} 
        pause={false}
        indicators={false}
        controls={false}
      >
        {carImages.map((car, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={fixedImage} alt={car.alt} />
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Custom Circle Indicators */}
      <div style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
        {carImages.map((car, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            style={{
              width: "23px",
              height: "22px",
              borderRadius: "50%",
              backgroundColor: activeIndex === index ? car.color : car.color, 
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;