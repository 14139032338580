import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Components/Dashboard";

import {
    viewTestDriveLength,
    viewContactUsLength,
    viewOldCarLength,
  uploadupdatesImages,
  deleteContact,
} from "../action";
export class Controller extends Component {
  render() {
    return <Dashboard {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewOldCarLength: (token, user_id) => {
      dispatch(viewOldCarLength(token, user_id));
    },

    viewTestDriveLength: (token, user_id) => {
      dispatch(viewTestDriveLength(token, user_id));
    },
    viewContactUsLength: (token, user_id) => {
      dispatch(viewContactUsLength(token, user_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
