// import React, { Component } from 'react';

// class GeelyCar360 extends Component {
//     componentDidMount() {
//         const script = document.createElement("script");
//         script.src = "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js";
//         script.async = true;
//         document.body.appendChild(script);

//         script.onload = () => {
//             console.log("Lazysizes script loaded successfully");
//         };

//         script.onerror = () => {
//             console.error("Failed to load lazysizes script");
//         };
//     }

//     render() {
//         return (
//             <div
//                 style={{
//                     margin: 0,
//                     padding: 0,
//                     boxSizing: "border-box",
//                     backgroundColor: "#f5f5f5",
//                     height: "100vh",
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     overflow: "hidden",
//                 }}
//             >
//                 <div
//                     className="geely-car"
//                     style={{
//                         width: "75%",
//                         height: "70%",
//                         maxWidth: "100%",
//                         maxHeight: "100%",
//                     }}
//                 >
//                     <div
//                         className="cloudimage-360"
//                         data-folder="https://scaleflex.cloudimg.io/v7/demo/geely-car/"
//                         data-filename-x="Okavango-3-{index}.jpg"
//                         data-amount-x="24" 
//                         data-bottom-circle
//                         data-lazyload
//                         data-lazy-selector="lazyload"
//                         data-responsive="scaleflex"
//                         style={{
//                             width: "100%",
//                             height: "100%",
//                         }}
//                     ></div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default GeelyCar360;


//--------------------------------------second rule-------------------------------------------------------//

import React, { Component } from 'react';
import './GeelyCar360.css'; // Assuming external CSS for styling

class GeelyCar360 extends Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.images = [];
        this.currentFrame = 0;
        this.totalFrames = 8; // Update this based on the number of images
    }

    componentDidMount() {
        // Lazysizes script dynamically load
        const lazysizesScript = document.createElement("script");
        lazysizesScript.src = "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js";
        lazysizesScript.async = true;
        document.body.appendChild(lazysizesScript);

        // Cloudimage 360 script dynamically load
        const cloudImage360Script = document.createElement("script");
        cloudImage360Script.src = "https://cdn.scaleflex.it/cloudimage-360/2.0.1/cloudimage-360.min.js"; // Ensure you use the correct version
        cloudImage360Script.async = true;
        document.body.appendChild(cloudImage360Script);

        // Handle script load events
        cloudImage360Script.onload = () => {
            console.log("Cloudimage 360 script loaded successfully");
            if (window.CloudImage360) {
                // Initialize CloudImage360 after script is loaded
                window.CloudImage360.init();
            } else {
                console.error("CloudImage360 library is not available");
            }
        };

        lazysizesScript.onload = () => {
            console.log("Lazysizes script loaded successfully");
        };

        lazysizesScript.onerror = () => {
            console.error("Failed to load lazysizes script");
        };

        cloudImage360Script.onerror = () => {
            console.error("Failed to load cloudimage-360 script");
        };
    }

    render() {
        return (
            <div
                style={{
                    margin: 0,
                    padding: 0,
                    boxSizing: "border-box",
                    backgroundColor: "#f5f5f5",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                }}
            >
                <div
                    className="geely-car"
                    style={{
                        width: "75%",
                        height: "70%",
                        maxWidth: "100%",
                        maxHeight: "100%",
                    }}
                >
                    <div
                        className="cloudimage-360"
                        data-folder="https://scaleflex.cloudimg.io/v7/demo/geely-car/"
                        data-filename-x="Okavango-3-{index}.jpg" // Ensure this pattern matches your images
                        data-amount-x="24" // Total number of images
                        data-bottom-circle
                        data-lazyload
                        data-lazy-selector="lazyload"
                        data-responsive="scaleflex"
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    ></div>
                </div>
            </div>
        );
    }
}

export default GeelyCar360;

//------------------------------------------Third rule-------------------------------------------------------//


// import React, { useEffect } from 'react';
// import PhotoSphereViewer from 'photo-sphere-viewer';
// import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
// import './GeelyCar360.css'; // Assuming external CSS for styling

// // Import images
// import Image1 from '../../../Common/Image/exterior-image-164.webp';
// import Image2 from '../../../Common/Image/exterior-image-165.webp';
// import Image3 from '../../../Common/Image/exterior-image-166.webp';
// import Image4 from '../../../Common/Image/exterior-image-167.webp';

// // Create an array of image imports
// const imageUrls = [Image1, Image2, Image3, Image4];

// const GeelyCar360 = () => {
//     useEffect(() => {
//         const viewer = new PhotoSphereViewer({
//             container: '.geely-car-360-container',
//             panorama: imageUrls,
//             loadingImg: '/images/loading-image.jpg', // Optional: Loading image
//             navbar: ['zoom', 'fullscreen'],
//             loop: true,
//             // Additional configuration options
//         });

//         return () => viewer.destroy(); // Cleanup on component unmount
//     }, []);

//     return (
//         <div className="geely-car-360-container">
//             {/* The viewer will be initialized in this container */}
//         </div>
//     );
// }

// export default GeelyCar360;




