import React, { Component } from "react";
import { connect } from "react-redux";
import Addspecification from "../Components/specification";

import { addSpecification } from "../action";
export class Controller extends Component {
  render() {
    return <Addspecification {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    addSpecification: (
      token,
      user_id,
      oldCar_id,
      engine,
     // engineType,
      maxPower,
      maxTorque,
      mileage,
      driveTrain,
     // turbocharger,
      length,
      width,
      height,
      wheelbase,
      groundClearance,
      kerbWeight,
      doors,
      seatingCapacity,
      rows,
      bootspace,
      fuelTankCapacity,
      frontSuspension,
      rearSuspension,
      frontbrakeType,
      rearBrakeType,
      minTurningRadius,
      steeringType,
      wheels,
      spareWheel,
      frontTyres,
      rearTyres
    ) => {
      dispatch(
        addSpecification(
          token,
          user_id,
          oldCar_id,
          engine,
          //engineType,
          maxPower,
          maxTorque,
          mileage,
          driveTrain,
         // turbocharger,
          length,
          width,
          height,
          wheelbase,
          groundClearance,
          kerbWeight,
          doors,
          seatingCapacity,
          rows,
          bootspace,
          fuelTankCapacity,
          frontSuspension,
          rearSuspension,
          frontbrakeType,
          rearBrakeType,
          minTurningRadius,
          steeringType,
          wheels,
          spareWheel,
          frontTyres,
          rearTyres
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
