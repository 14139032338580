import React, { Component } from "react";
import { connect } from "react-redux";
import Specifications from "../Component/specifications";

import {
  viewOverviewByOldCarId,
  viewOldCarById,
} from "../../Preownedcars/action";
export class Controller extends Component {
  render() {
    return <Specifications {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
    oldcar: store.oldcar,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewOverviewByOldCarId: (oldcar_id) => {
      dispatch(viewOverviewByOldCarId(oldcar_id));
    },
    viewOldCarById: (oldcar_id) => {
      dispatch(viewOldCarById(oldcar_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
