import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import access1 from '../../../../../images/Gloster/access1.webp';
import access2 from '../../../../../images/Gloster/access2.webp';
import access3 from '../../../../../images/Gloster/access3.webp';
import access4 from '../../../../../images/Gloster/access4.webp';
import inte1 from '../../../../../images/Gloster/inte1.webp';


class Accessories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'EXTERIOR',
      screenSize: window.innerWidth, 
      selectedImage: this.getTabImages('EXTERIOR')[0],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ screenSize: window.innerWidth });
  };

  getTabImages = (tab) => {
    const tabData = {
      EXTERIOR: [
        {
          src: access1,
          label:'Head Lamp Garnish',
          title: 'HEAD LAMP GARNISH',
          subtitle: 'Designed For Beauty And Toughness',
          description: 'High-quality additions to compliment your car',
        },
        {
          src: access2,
          label:'Chrome Door Handle With Bowl Cover Kit',
          title: 'CHROME DOOR HANDLE WITH BOWL COVER KIT',
          subtitle: 'Designed For Beauty And Toughness',
          description: 'Behold an ultra-premium feel from the moment you begin your journeys.',
        },
        {
          src: access3,
          label: 'ORVM Garnish',
          title:'ORVM GARNISH',
          subtitle: 'Designed For Beauty And Toughness',
          description: 'Reflect a bold style.',
        },
        {
          src: access4,
          label: 'Tail Lamp Garnish',
          title:'TAIL LAMP GARNISH',
          subtitle: 'Designed For Beauty And Toughness',
          description: 'A sleek look that turns all heads around.',
        }
      ],
      INTERIOR: [
        {
          src: inte1,
          title: 'REFRIGERATOR 18L THERMOELECTRIC',
          subtitle: 'Epitome Of Pristine Luxury',
          description: 'Professional cooling product, designed for the Indian conditions and consumes even lesser power than headlight lamps.',
        },
      ],
     
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0],
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage, screenSize } = this.state;
    const images = this.getTabImages(selectedTab);

    return (
      <Box sx={{ width: '80%', height: '100%',
      }}>
        <Box
          sx={{
            height:'90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' } ,
            marginTop:{xs:'15px'},
           
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '95vw',
              maxWidth: '500px',
              height: 'auto',
              
            }}
            alt={selectedImage.title}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: {md:'space-around', xs:'start',sm:'start'},
              alignItems:'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-35px', md: '0px' } 
            }}
          >
            <Typography variant="h5"
              sx={{
                width: { xs: '90%', md: '330px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}>
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '270px', md: '330px',sm:'100%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw' ,sm:'2.5vw'},
                marginBottom: '12px',
              }}>
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '270px', md: '290px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw',sm:'2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
              }}>
              {selectedImage.description}
            </Typography>
          </Box>
        </Box>
        <Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '10px',
    marginTop: { xs: '30px', md: '50px' },
    marginLeft: { xs: '-25px', md: '0px' } 
  }}
>
  {images.map((img, index) => (
    <Box
      key={index}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { md: 'center', xs: 'start' }
      }}
    >
      <img
        src={img.src}
        style={{
          borderRadius: '10px',
          width: '20vw',
          maxWidth: '250px',
          height: 'auto',
          marginTop: { xs: '10px', md: '40px', sm: '20px' }
        }}
        alt={img.label}
        onClick={() => this.handleImageClick(img)}
      />
      <Typography
        sx={{
          fontFamily: 'Roboto-Medium, Arial, sans-serif',
          fontSize: { xs: '3.5vw', md: '1.04167vw' },
          color: '#000',
          paddingTop: '1.04167vw',
          margin: 0,
          display:{md:'flex',xs:'none'},
          textAlign: { xs: 'left', md: 'left' }, 
          width: '100%' 
        }}
      >
        {img.label}
      </Typography>
    </Box>
  ))}
</Box>

      </Box>
    );
  };

  render() {
    const { selectedTab, screenSize } = this.state;
    const tabs = ['EXTERIOR', 'INTERIOR'];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: '20px', md: '0' }, 
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' },
            color: '#cf2230',
            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop: '15px',
          }}
        >
          ACCESSORIES
        </Typography>

        {screenSize <= 768 ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px' }}>
            <Select
              style={{fontWeight:'700'}}
              value={selectedTab}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '75%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' },
                  color:'black',
                  fontWeight: selectedTab === tab ? 'bolder' : '500',
                  backgroundColor: selectedTab === tab ? '#D8DDD8' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#D8DDD8',
                    color: 'white',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display: { md: 'flex', xs: 'none' }, border: '4px solid #E32735', margin: '20px 0' }} />

        {this.renderContent()}
      </Box>
    );
  }
}

export default Accessories;
