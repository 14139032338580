import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import access1 from '../../../images/Windsor/access1.webp';
import access2 from '../../../images/Windsor/access2.webp';
import access3 from '../../../images/Windsor/access3.webp';
import access4 from '../../../images/Windsor/access4.webp';
import inte1 from '../../../images/Windsor/inte1.webp';
import inte2 from '../../../images/Windsor/inte2.webp';
import inte3 from '../../../images/Windsor/inte3.webp';
import inte4 from '../../../images/Windsor/inte4.webp';
import safe1 from '../../../images/Windsor/safety1.webp';
import safe2 from '../../../images/Windsor/safety2.webp';
import safe3 from '../../../images/Windsor/safety3.webp';
import safe4 from '../../../images/Windsor/safety4.webp';
import ess1 from '../../../images/Windsor/ess1.webp';
import ess2 from '../../../images/Windsor/ess2.webp';
import ess3 from '../../../images/Windsor/ess3.webp';
import ess4 from '../../../images/Windsor/ess4.webp';  

class Accessories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'EXTERIOR',
      screenSize: window.innerWidth, 
      selectedImage: this.getTabImages('EXTERIOR')[0],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ screenSize: window.innerWidth });
  };

  getTabImages = (tab) => {
    const tabData = {
      ESSENTIALS: [
        {
          src: ess1,
          label:'Mud Flaps',
          title: 'MUD FLAPS',
          subtitle: 'EXCITING ESSENTIALS',
          description: 'Protect your car from splashes of mud and water while driving.',
        },
        {
          src: ess2,
          label:'3D Mats (CABIN+BOOT)',
          title: "3D MATS (CABIN+BOOT)",
          subtitle: 'EXCITING ESSENTIALS',
          description: 'Get a combination of protection and style for the car floor.',
        },
        {
          src: ess3,
          label:'Screen Guard - Infotainment​',
          title: 'SCREEN GUARD - INFOTAINMENT​',
          subtitle: 'EXCITING ESSENTIALS',
          description: 'Keep your screen smudge and fingerprint-free so that your entertainment stays unhampered.',
        },
        {
          src: ess4,
          label:'Car Cover',
          title: 'CAR COVER',
          subtitle: 'EXCITING ESSENTIALS',
          description: 'Keep your car dust-free.',
        }
      ],
     
      EXTERIOR: [
        {
          src: access1,
          label:'Body Side Moulding',
          title: 'BODY SIDE MOULDING',
          subtitle: 'ELITE EXTERIORS',
          description: 'Get style and protection together with accents that prevent scratches and minor damages.',
        },
        {
          src: access2,
          label:'Front Grill Element',
          title: 'FRONT GRILL ELEMENT',
          subtitle: 'ELITE EXTERIORS',
          description: 'Keep your car cool from the inside while showing off its beauty.',
        },
        {
          src: access3,
          label: 'Roof Rail Garnish',
          title:'ROOF RAIL GARNISH',
          subtitle: 'ELITE EXTERIORS',
          description: 'Add an extra expression of style to your roof rail.',
        },
        {
          src: access4,
          label: 'Rear Window Garnish',
          title:'REAR WINDOW GARNISH',
          subtitle: 'ELITE EXTERIORS',
          description: 'Make your car more pleasing to look at with a stylish design element for your rear window.',
        }
      ],
     "SAFETY" : [
        {
          src: safe1,
          label:'Drive Mate Pro+',
          title: 'DRIVE MATE PRO+',
          subtitle: 'SUPERIOR SAFETY',
          description: 'Keep an eye on the road ahead and behind to drive worry-free.',
        },
        {
          src: safe2,
          label:'Weather Deflector',
          title: 'WEATHER DEFLECTOR',
          subtitle: 'SUPERIOR SAFETY',
          description: 'Stay shielded from weather conditions making every drive a good experience.',
        },
        {
          src: safe3,
          label: 'Door Edge Guard',
          title:'DOOR EDGE GUARD',
          subtitle: 'SUPERIOR SAFETY',
          description: 'Prevent your doors from getting scratches and dents.​',
        },
        {
          src: safe4,
          label: 'Hood Branding',
          title:'HOOD BRANDING',
          subtitle: 'MG BADGES AND PROTECTION',
          description: 'Give your Astor a well deserving makeover with the premium MG badges.',
        }
      ],
      INTERIOR: [
        {
          src: inte1,
          label:'Sill Plates',
          title: 'SILL PLATES',
          subtitle:'IMPRESSIVE INTERIORS',
          description: 'Protect your car doors from wear and tear..',
        },
        {
          src: inte2,
          label:'Spare Wheel Kit',
          title: "SPARE WHEEL KIT",
          subtitle: 'IMPRESSIVE INTERIORS',
          description: 'Be prepared for any surprises on the road with a change of wheel.',
        },
        {
          src: inte3,
          label:'Rear seat entertainment unit',
          title: 'REAR SEAT ENTERTAINMENT UNIT',
          subtitle: 'IMPRESSIVE INTERIORS',
          description: 'Rear seat screens that won’t let your entertainment take the back screen.',
        },
        {
          src: inte4,
          label:'Dashboard organizer',
          title: 'DASHBOARD ORGANIZER',
          subtitle: 'IMPRESSIVE INTERIORS',
          description: 'Keep your phone and other valuables safely on the dashboard.',
        }
      ],
    
    
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0],
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage, screenSize } = this.state;
    const images = this.getTabImages(selectedTab);

    return (
      <Box sx={{ width: '80%', height:'100%',
     
      }}>
        <Box
          sx={{
            height: '90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' } ,
            marginTop:{xs:'15px'},
            
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '95vw',
              maxWidth: '500px',
              height: 'auto',
              
            }}
            alt={selectedImage.title}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: {md:'space-around', xs:'start',sm:'start'},
              alignItems:'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-35px', md: '0px' } 
            }}
          >
            <Typography variant="h5"
              sx={{
                width: { xs: '90%', md: '330px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}>
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '300px', md: '330px',sm:'100%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw' ,sm:'2.5vw'},
                marginBottom: '12px',
              }}>
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '270px', md: '290px',sm:'550px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw',sm:'2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
              }}>
              {selectedImage.description}
            </Typography>
          </Box>
        </Box>
        <Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '10px',
    marginTop: { xs: '30px', md: '50px' },
    marginLeft: { xs: '-25px', md: '0px' } 
  }}
>
  {images.map((img, index) => (
    <Box
      key={index}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { md: 'center', xs: 'start' }
      }}
    >
      <img
        src={img.src}
        style={{
          borderRadius: '10px',
          width: '20vw',
          maxWidth: '250px',
          height: 'auto',
          marginTop: { xs: '10px', md: '40px', sm: '20px' }
        }}
        alt={img.label}
        onClick={() => this.handleImageClick(img)}
      />
      <Typography
        sx={{
          fontFamily: 'Roboto-Medium, Arial, sans-serif',
          fontSize: { xs: '3.5vw', md: '1.04167vw' },
          color: '#000',
          paddingTop: '1.04167vw',
          margin: 0,
          display:{md:'flex',xs:'none'},
          textAlign: { xs: 'left', md: 'left' }, 
          width: '100%' 
        }}
      >
        {img.label}
      </Typography>
    </Box>
  ))}
</Box>

      </Box>
    );
  };

  render() {
    const { selectedTab, screenSize } = this.state;
    const tabs = ['EXTERIOR','INTERIOR','SAFETY','ESSENTIALS'];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: '20px', md: '0' }, 
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' },
            color: '#cf2230',
            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop: '15px',
          }}
        >
          ACCESSORIES
        </Typography>

        {screenSize <= 768 ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px' }}>
            <Select
              style={{fontWeight:'700'}}
              value={selectedTab}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '75%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' },
                  color: selectedTab === tab ? 'white' : 'black',
                  backgroundColor: selectedTab === tab ? '#2F444D' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#2F444D',
                    color: 'white',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display: { md: 'flex', xs: 'none' }, border: '4px solid #2F444D', margin: '20px 0' }} />

        {this.renderContent()}
      </Box>
    );
  }
}

export default Accessories;
