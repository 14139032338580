import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: '3.55556vw',
    flexDirection: 'column',
    fontSize:{xs:'18px'}
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: '5.55556vw',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'GillSansSemiBold',
            fontSize: { xs: '2.7vw', md: '1.55556vw', }, // Increase font size for mobile
            backgroundColor: 'transparent',
            color: '#000',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: '#CF2230' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Roboto-Regular',
              fontSize: {md:'1.3vw',xs:'2.7vw',sm:'2.4vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
    'The starting price of MG Hector is ₹13.99 Lakhs* onwards.',
    'Yes, MG Hector has panoramic sunroof control from touch screen.',
    "MG Hector is a luxurious 5 seater car with India's largest 35.56 cm (14 inch) HD Portrait Infotainment system with an intuitive multi-tasking smart hub, Digital Bluetooth key sharing.",
    'MG Hector is available in 7 colors Dune Brown, Glaze Red, Aurora Silver, Starry Black, Dual Tone White & Black, Candy White, Havana Grey.',
    'ADAS (Advanced Driver Assistance Systems) in MG Hector is a modern safety technology that assists drivers in driving and parking functions.',
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
  sx={{
    width: '100%',
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    
  }}
>
  <Typography
    variant="h2"
    sx={{
      fontSize: { md: '2.08333vw', xs: '4.7vw' },
      color: '#cf2230',
      fontFamily: 'GillSansSemiBold, Arial, sans-serif',
      paddingBottom: { md: '3.125vw', xs: '5.4vw' },
      fontWeight: '400',
      marginTop: { xs: '50px' },
    }}
  >
    Frequently Asked Questions
  </Typography>
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          fontFamily: 'GillSansSemiBold',
          fontSize: { md: '5.55556vw' },
          backgroundColor: 'transparent',
          color: '#000',
          paddingRight: '4%',
          paddingLeft: '0',
        }}
      >
        {[
          'What is the starting price of new MG Hector car?',
          'Does MG Hector car have sunroof?',
          'What are the key features of MG Hector car?',
          'How many colors are available in MG Hector?',
          'What is ADAS Autonomous Level-2 technology in MG Hector Plus?',
        ].map((question, index) => (
          <React.Fragment key={index}>
            <QuestionBox
              question={question}
              content={contents[index]}
              expanded={expanded === index}
              onToggle={() => handleToggle(index)}
            />
            {index < contents.length - 1 && (
              <Divider
                sx={{
                  margin: '10px 0',
                  backgroundColor: 'black',
                  height: '2px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Grid>
  </Grid>
</Box>

    </Container>
  );
}
