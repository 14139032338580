import React, { Component } from "react";
import { connect } from "react-redux";
import LoginController from "../Components/Drawer";
import { setAdminLogin, fetchToken, onLogout, getTestDriveReport } from "../../Auth/Actions";


export class Controller_con extends Component {

  render() {
    return (
      <LoginController {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    setAdminLogin: (payload) => {
      dispatch(setAdminLogin(payload));
    },
    fetchToken: (token) => {
      dispatch(fetchToken(token));
    },
    getTestDriveReport: (token,startDate, endDate,) => {
      dispatch(getTestDriveReport(token,startDate, endDate,));
    },
    onLogout: () => {
      dispatch(onLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controller_con);