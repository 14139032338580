import React, { Component } from 'react';
import { Typography, Button, Grid, Box } from '@mui/material';
import './CityPricing.css'; // Import the CSS file for styling

class CityPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [
        'MG ZS EV price in Guntur', 'MG ZS EV price in Visakhapatnam',
        'MG ZS EV price in Krishna', 'MG ZS EV price in Kamrup',
        'MG ZS EV price in Nellore', 'MG ZS EV price in Muzaffarpur',
        'MG ZS EV price in Tirupati', 'MG ZS EV price in Patna',
        'MG ZS EV price in Chandigarh', 'MG ZS EV price in New Delhi',
        'MG ZS EV price in Ambikapur', 'MG ZS EV price in North Goa',
        'MG ZS EV price in Bilaspur', 'MG ZS EV price in South Goa',
        'MG ZS EV price in Raipur', 'MG ZS EV price in Ahmedabad'
      ],
      visibleCities: 8,
    };
  }

  handleLoadMore = () => {
    this.setState(prevState => ({
      visibleCities: prevState.visibleCities + 8
    }));
  };

  render() {
    const { cities, visibleCities } = this.state;
    return (
      <Box id="price" sx={{ textAlign: 'center',display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <Box sx={{display:'flex',justifyContent:'center'}}>
        <Typography variant="h6" sx={{ marginBottom: '20px', fontFamily: 'GillSansSemiBold, sans-serif',fontWeight:'600',textAlign:{md:'center',xs:'left',sm:'center'}}}>
          MG ZS EV City Wise Pricing
        </Typography>
        </Box>
        <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
          {cities.slice(0, visibleCities).map((city, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <a href="#" className="city-link" >
                {city}
              </a>
            </Grid>
          ))}
        </Grid>
        <Button
  onClick={this.handleLoadMore}
  sx={{
    width: '200px',
    marginTop: '20px',
    backgroundColor: '#CF2230',
    color: 'white',
    fontWeight: '700',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: { xs: '2.5vw', sm: '0.9vw' },
    marginLeft: { xs: '10px', sm: '0px' }, // Add margin on the left for mobile screens
    alignSelf: { xs: 'flex-start', sm: 'center' }, // Left align on mobile, center align on larger screens
    '&:hover': { backgroundColor: 'black' },
  }}
>
  Load More
</Button>

      </Box>
    );
  }
}

export default CityPricing;
