import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import access1 from '../../../images/Astor/exter1.webp';
import access2 from '../../../images/Astor/exter2.webp';
import access3 from '../../../images/Astor/exter3.webp';
import access4 from '../../../images/Astor/exter4.webp';
import inte1 from '../../../images/Astor/inte1.webp';
import inte2 from '../../../images/Astor/inte2.jpg';
import inte3 from '../../../images/Astor/inte3.webp';
import inte4 from '../../../images/Astor/inte4.webp';
import ess1 from '../../../images/Astor/safety1.webp';
import ess2 from '../../../images/Astor/safety2.webp';
import ess3 from '../../../images/Astor/safety3.webp';
import ess4 from '../../../images/Astor/safety4.webp';
import badge1 from '../../../images/Astor/badge1.webp';
import badge2 from '../../../images/Astor/badge2.webp';
import badge3 from '../../../images/Astor/badge3.webp';
import badge4 from '../../../images/Astor/badge4.webp';  

class Accessories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'EXTERIOR',
      screenSize: window.innerWidth, 
      selectedImage: this.getTabImages('EXTERIOR')[0],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ screenSize: window.innerWidth });
  };

  getTabImages = (tab) => {
    const tabData = {
      ESSENTIALS: [
        {
          src: ess1,
          label:'Weather Deflector Chrome',
          title: 'WEATHER DEFLECTOR CHROME',
          subtitle: 'THOUGHTFULLY CRAFTED',
          description: 'Designed to protect you from harsh weather while adding style to your car, the weather deflector comes with high-grade chrome and fine finish.',
        },
        {
          src: ess2,
          label:'Weather Deflector Black',
          title: "WEATHER DEFLECTOR BLACK",
          subtitle: 'THOUGHTFULLY CRAFTED',
          description: 'Designed to protect you from harsh weather while adding style to your car, the weather deflector comes with high-grade chrome and black finish.',
        },
        {
          src: ess3,
          label:'Side Step Board',
          title: 'SIDE STEP BOARD',
          subtitle: 'THOUGHTFULLY CRAFTED',
          description: 'Designed to keep the convenience of the elderly in the family, the side step provides additional support while getting in and out of the car.',
        },
        {
          src: ess4,
          label:'Mud Flaps',
          title: 'MUD FLAPS',
          subtitle: 'THOUGHTFULLY CRAFTED',
          description: 'Branded mud flaps which can withstand water splashes, debris and mud. Its high durability makes these the car’s must-haves.',
        }
      ],
     
      EXTERIOR: [
        {
          src: access1,
          label:'Chrome Headlamp',
          title: 'CHROME HEADLAMP',
          subtitle: 'EXCLUSIVE STYLING',
          description: 'Give your car a makeover with exclusive styling elements from MG.',
        },
        {
          src: access2,
          label:'Chrome ORVM',
          title: 'CHROME ORVM',
          subtitle: 'EXCLUSIVE STYLING',
          description: 'Give your car a makeover with exclusive styling elements from MG.',
        },
        {
          src: access3,
          label: 'Chrome Door Handle',
          title:'CHROME DOOR HANDLE',
          subtitle: 'EXCLUSIVE STYLING',
          description: 'Give your car a makeover with exclusive styling elements from MG.',
        },
        {
          src: access4,
          label: 'Chrome Front Lower Surround',
          title:'CHROME FRONT LOWER SURROUND',
          subtitle: 'EXCLUSIVE STYLING',
          description: 'Give your car a makeover with exclusive styling elements from MG.',
        }
      ],
     "BADGES & PROTECTION" : [
        {
          src: badge1,
          label:'AI Inside',
          title: 'AI INSIDE',
          subtitle: 'MG BADGES AND PROTECTION',
          description: 'Elevate your ride with the premium looking AI Inside badge.',
        },
        {
          src: badge2,
          label:'Autonomous L2',
          title: 'AUTONOMOUS L2',
          subtitle: 'MG BADGES AND PROTECTION',
          description: 'Give your Astor a well deserving makeover with the premium MG badges.',
        },
        {
          src: badge3,
          label: 'Internet Inside',
          title:'INTERNET INSIDE',
          subtitle: 'MG BADGES AND PROTECTION',
          description: 'A technologically advanced car deserves all the right additions to its look.',
        },
        {
          src: badge4,
          label: 'Hood Branding',
          title:'HOOD BRANDING',
          subtitle: 'MG BADGES AND PROTECTION',
          description: 'Give your Astor a well deserving makeover with the premium MG badges.',
        }
      ],
      INTERIOR: [
        {
          src: inte1,
          label:'Steering Wheel Covers',
          title: 'STEERING WHEEL COVERS',
          subtitle:'EXCLUSIVE STYLING',
          description: 'Available in Black and Ivory, these genuine MG Steering Covers offer you a comfortable grip and add a plush look.',
        },
        {
          src: inte2,
          label:'Seat Cover',
          title: "SEAT COVER",
          subtitle: 'EXCLUSIVE STYLING',
          description: 'Crafted out of fine Vegan Leather, these covers are airbag compatible and include side openings to safeguard your seats; while also being antifungal and fire retardant. Drape your Astor in Ivory Geometric, Black and Ivory and Black luxury! ',
        },
        {
          src: inte3,
          label:'Illuminated Scuff Plate',
          title: 'ILLUMINATED SCUFF PLATE',
          subtitle: 'EXCLUSIVE STYLING',
          description: 'Now enter and exit your Astor in style with added MG branded Door Sill Plates.',
        },
        {
          src: inte4,
          label:'Screen Guard',
          title: 'SCREEN GUARD',
          subtitle: 'EXCLUSIVE STYLING',
          description: 'Enjoy unhindered entertainment by protecting your 25.7 cm HD touchscreen from unwanted damages and scratches.',
        }
      ],
    
    
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0],
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage, screenSize } = this.state;
    const images = this.getTabImages(selectedTab);

    return (
      <Box sx={{ width: '80%', height: '100%',}}>
        <Box
          sx={{
            height: '95%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' } ,
            marginTop:{xs:'15px'},
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '95vw',
              maxWidth: '500px',
              height: 'auto',
              
            }}
            alt={selectedImage.title}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: {md:'space-around', xs:'start',sm:'start'},
              alignItems:'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-35px', md: '0px' } 
            }}
          >
            <Typography variant="h5"
              sx={{
                width: { xs: '90%', md: '330px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}>
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '300px', md: '330px',sm:'100%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw' ,sm:'2.5vw'},
                marginBottom: '12px',
              }}>
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '270px', md: '290px',sm:'550px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw',sm:'2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
              }}>
              {selectedImage.description}
            </Typography>
          </Box>
        </Box>
        <Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '10px',
    marginTop: { xs: '30px', md: '50px' },
    marginLeft: { xs: '-25px', md: '0px' } 
  }}
>
  {images.map((img, index) => (
    <Box
      key={index}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { md: 'center', xs: 'start' }
      }}
    >
      <img
        src={img.src}
        style={{
          borderRadius: '10px',
          width: '20vw',
          maxWidth: '250px',
          height: 'auto',
          marginTop: { xs: '10px', md: '40px', sm: '20px' }
        }}
        alt={img.label}
        onClick={() => this.handleImageClick(img)}
      />
      <Typography
        sx={{
          fontFamily: 'Roboto-Medium, Arial, sans-serif',
          fontSize: { xs: '3.5vw', md: '1.04167vw' },
          color: '#000',
          paddingTop: '1.04167vw',
          margin: 0,
          display:{md:'flex',xs:'none'},
          textAlign: { xs: 'left', md: 'left' }, 
          width: '100%' 
        }}
      >
        {img.label}
      </Typography>
    </Box>
  ))}
</Box>

      </Box>
    );
  };

  render() {
    const { selectedTab, screenSize } = this.state;
    const tabs = ['ESSENTIALS','EXTERIOR', 'BADGES & PROTECTION','INTERIOR'];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: '20px', md: '0' }, 
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' },
            color: '#cf2230',
            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop: '15px',
          }}
        >
          ACCESSORIES
        </Typography>

        {screenSize <= 768 ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px' }}>
            <Select
              style={{fontWeight:'700'}}
              value={selectedTab}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '75%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' },
                  color: selectedTab === tab ? 'white' : 'black',
                  backgroundColor: selectedTab === tab ? '#6B6F73' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#6B6F73',
                    color: 'white',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display: { md: 'flex', xs: 'none' }, border: '4px solid #6B6F73', margin: '20px 0' }} />

        {this.renderContent()}
      </Box>
    );
  }
}

export default Accessories;
