import * as CONSTANT from "./Constants";
const initial_state = {
  type: "",
  isHome: true,
  user_id: '',
  mobile: '',
  PasswordGenerate:'',
  editpassword:''
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case CONSTANT.LOGIN:
      return state = {
        ...state,
        isHome: false,
        user_id: action.payload._id,
        mobile: action.payload.mobile,
        name: action.payload.name,
        email: action.payload.email,
        emp_id: action.payload.emp_id,
        token: action.payload.token,
        type: action.payload.access,
      };

    case CONSTANT.LOGOUT:
      return state = {
        ...state,
        isHome: true,
        user_id: '',
        mobile: '',
        name: '',
        email: '',
        emp_id: '',
        token: '',
        type: '',
      };

    case CONSTANT.SET_ALL_ORG:
      return state = { ...state, organizations: action.payload };
    case CONSTANT.SET_ORG_USER_DETAILS:
      return state = {
        ...state,
        org_user: action.payload,
        // user_type: action.payload.type
      };

    case CONSTANT.SET_USER_DETAILS:
      return state = { ...state, user_details: action.payload };
    case CONSTANT.SET_ALL_DATA:
      return state = { ...state, alldata: action.payload };
    case CONSTANT.SET_ORG_DETAILS2:
      return state = { ...state, org_details2: action.payload };
    case CONSTANT.SET_ORG_DETAILS:
      return state = { ...state, org_details: action.payload };
    case CONSTANT.SET_ORG_USER_DEATILS:
      return state = { ...state, org_user_details: action.payload };
    case CONSTANT.SET_ORG_ID:
      return state = { ...state, org_id: action.payload };
    case CONSTANT.SETLOGINORG:
      return state = { ...state, orglogin: action.payload };
    case CONSTANT.SETMOBILE:
      return state = { ...state, otpmobile: action.payload };
    case CONSTANT.SET_DOC:
      return state = { ...state, doc: action.payload };
    case CONSTANT.SET_WALET_BY_ID:

      return state = { ...state, view_wallet_by_id: action.payload };
    case CONSTANT.SET_USER:
      return state = {
        ...state,
        useremail: action.payload.email,
        usermobile: action.payload.phone,
        usertype: action.payload.type,
        username: action.payload.user_name,

      };
    case CONSTANT.SET_ORG:
      return state = {
        ...state,
        org_id: action.payload.org_id,
        type: action.payload.type,
        orgHome: true
      };

    case CONSTANT.SET_ORGANIZATIONS:
      return state = { ...state, all_org: action.payload };



      case CONSTANT.PASSWORD_GENERATE:
        // 
      
      return state = {
        ...state,
        PasswordGenerate: action.payload,
       
      };


      case CONSTANT.EDITPASSWORD:
      
    
    return state = {
      ...state,
      editpassword: action.payload,
     
    };
    default:
      return state;
  }
}
