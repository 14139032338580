import { Component } from 'react';
import React from 'react';
import './Glosterblackstorm.css';
import {
  Box,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Navbar from '../../../../Home/Components/Nav';
import Footer from '../../../../Home/Components/Footer';
// import Pricing from '../../Gloster Blackstorm/Components/CityPricing';
import 'bootstrap/dist/css/bootstrap.min.css';
class Hector extends Component {
   
  render() {
    return (
      <div id='container'>
        <Navbar />
        {/* Banner */}
        <div id="banner-image-glosterblack">
  <div id="glost-left">
    <Typography
      variant="h4"
      sx={{
        color: 'white',
        marginTop: '120px',
        fontSize: '16px',
        fontFamily: 'GillSansBold, sans-serif',
        fontWeight: '700',
      }}
    >
      PRICE STARTS AT ₹ 41.05 LAKH*
    </Typography>
    {/* <div className="glost">
      <button>Book Now</button>
      <button>Download Brochure</button>
    </div>
    <div className="glost">
      <button>Locate A Dealer</button>
      <button>Visit Tour</button>
    </div> */}
  </div>
</div>

<div style={{height:'40px',width:'100%',marginTop:'8px'}}>
<Link to="/" style={{ textDecoration: 'none', color: 'grey',cursor:'pointer' }}>
&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;<b>Home</b> 
</Link>
<Link to="/Glosterblackstorm" style={{ textDecoration: 'none', color: 'grey',cursor:'pointer' }}>
  &nbsp;&nbsp; &nbsp;&nbsp;MG Gloster BlackStorm
</Link></div>
<div id="part2"></div>
<div id="part3"></div>
<div id="part4"></div>
<div id="part5"></div>

       {/* Pricing Section */}
        {/* <Pricing/> */}

        <Footer />
      </div>
    );
  }
}

export default Hector;
