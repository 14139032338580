import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import {
  Grid,
  Box,
  Typography,
  Collapse,
  CardMedia,
  Container,
  Card,
  Button,
  Modal,
  TextField,
  CardContent,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import car1a from "../../images/preownedcars/pre1.webp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SpeedIcon from "@mui/icons-material/Speed";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BuildIcon from "@mui/icons-material/Build";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Car1 from "../../images/cardetail/car1.webp";
import Car2 from "../../images/cardetail/car2.webp";
import Car3 from "../../images/cardetail/car3.webp";
import Car4 from "../../images/cardetail/car4.webp";
import Car5 from "../../images/cardetail/car5.webp";
import Car6 from "../../images/cardetail/car6.webp";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import UpdateIcon from "@mui/icons-material/Update";
import Faqs from "./faq";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Specification from "./specifications";
import Carrouselsection from "./carrouselcards";
import { useParams } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import { green } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
//specificaton and feature
const styles = {
  questionBox: {
    padding: "10px",
    backgroundColor: "#F2F2F2",
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
  },
  questionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "black",
  },
  expandableContent: {
    padding: "10px",
    backgroundColor: "#fff",
  },
  downloadButton: {
    backgroundColor: "#E32735",
    color: "white",
    marginTop: "20px",
    marginBottom: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  listColumn: {
    flex: 1,
    marginRight: "10px",
  },
  listItem: {
    marginBottom: "10px",
  },
  listPoint: {
    fontWeight: "bold",
  },
  listValue: {
    marginLeft: "10px",
  },
};

// carrousel
const cars = [
  { image: Car1, name: "Tata Tiago", price: "Rs. 5.61 Lakh" },
  { image: Car2, name: "Hyundai Grand i10 Nios", price: "Rs. 6.62 Lakh" },
  { image: Car3, name: "Maruti Suzuki Alto K10", price: "Rs. 4.43 Lakh" },
  { image: Car4, name: "Renault Kwid", price: "Rs. 5.32 Lakh" },
  { image: Car5, name: "Maruti Suzuki Wagon R", price: "Rs. 6.17 Lakh" },
  { image: Car6, name: "Maruti Suzuki Celerio", price: "Rs. 5.89 Lakh" },
];
const testCars = [
  {
    _id: "1",
    carName: "Car One",
    discountedPrice: "300000",
    price: "350000",
    image: [Car1],
  },
  {
    _id: "2",
    carName: "Car Two",
    discountedPrice: "250000",
    price: "300000",
    image: [Car2],
  },
  {
    _id: "3",
    carName: "Car Three",
    discountedPrice: "280000",
    price: "320000",
    image: [Car3],
  },
];

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Adjust this to show multiple cards at once
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const CarDetailPage = (props) => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const goToPrevious = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const [expanded, setExpanded] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Specifications");

  const QuestionBox = ({ question, expanded, onToggle, list1, list2 }) => (
    <Box
      sx={styles.questionBox}
      onClick={onToggle}
      style={{ cursor: "pointer" }}
    >
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{ fontSize: { md: "18px", xs: "14px" }, fontWeight: "bold" }}
        >
          {question}
        </Typography>
        <Button
          onClick={onToggle}
          sx={{ color: "black", pointerEvents: "none" }}
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>
      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} alignItems="center">
              <Box sx={styles.listContainer}>
                {/* list1 handling */}
                <Box sx={styles.listColumn}>
                  {selectedTab === "Specifications" ? (
                    <ul>
                      {list1.map((item, index) => (
                        <li key={index} style={styles.listItem}>
                          <Typography variant="body2">{item}</Typography>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Box>
                      {list1.map((item, index) => (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          style={styles.listItem}
                        >
                          {/*<CheckIcon sx={{ fontSize: 16, mr: 1, color: 'green' }} />*/}
                          <Typography variant="body2">{item}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                {list2.length > 0 && (
                  <Box sx={styles.listColumn}>
                    {selectedTab === "Features" ? (
                      <Box>
                        {list2.map((item, index) => (
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            style={styles.listItem}
                          >
                            {/* <CheckIcon sx={{ fontSize: 16, mr: 1, color: 'green' }} /> */}
                            <Typography variant="body2">{item}</Typography>
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Typography>
                        {list2.map((item, index) => (
                          <Typography key={index} style={styles.listItem}>
                            <Typography variant="body2">{item}</Typography>
                          </Typography>
                        ))}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );

  const specifications = [
    {
      question: "Engine & Transmission",
      list1: [
        "Engine",
        "Fuel Type",
        "Max Power (bhp@rpm)",
        "Max Torque (Nm@rpm)",
        "Mileage (ARAI)",
        "Drivetrain",
        "Transmission",
      ],
      list2: [
        props.oldcar.viewSpecificationByOldcar.engine || "N/A", // Replace with actual value or "N/A" if undefined
        props.oldcar.viewOldcarById.fuelType || "N/A", // Replace with actual value or "N/A" if undefined
        props.oldcar.viewOldcarById.maxPower || "N/A", // Replace with actual value or "N/A" if undefined
        props.oldcar.viewOldcarById.maxTorque || "N/A", // Replace with actual value or "N/A" if undefined
        (props.oldcar.viewOldcarById.mileage || "N/A") + " kmpl", // Replace with actual value or "N/A" if undefined
        props.oldcar.viewOldcarById.driveTrain || "N/A", // Replace with actual value or "N/A" if undefined
        props.oldcar.viewOverviewByOldcar.transmission || "N/A", // Replace with actual value or "N/A" if undefined
      ],
    },
    {
      question: "Dimensions & Weight",
      list1: [
        "Length",
        "Width",
        "Height",
        "Wheelbase",
        "Ground Clearance",
        "Kerb Weight",
      ],
      list2: [
        props.oldcar.viewSpecificationByOldcar.length || "N/A",
        props.oldcar.viewSpecificationByOldcar.width || "N/A",
        props.oldcar.viewSpecificationByOldcar.height || "N/A",
        props.oldcar.viewSpecificationByOldcar.wheelbase || "N/A",
        props.oldcar.viewSpecificationByOldcar.groundClearance || "N/A",
        props.oldcar.viewSpecificationByOldcar.kerbWeight || "N/A",
      ],
    },
    {
      question: "Capacity",
      list1: [
        "Doors",
        "Seating Capacity",
        "No of Rows",
        "Bootspace",
        "Fuel Tank Capacity",
      ],
      list2: [
        (props.oldcar.viewSpecificationByOldcar.doors || "N/A") + " Doors",
        (props.oldcar.viewSpecificationByOldcar.seatingCapacity || "N/A") +
          " Person",
        (props.oldcar.viewSpecificationByOldcar.rows || "N/A") + " Rows",
        props.oldcar.viewSpecificationByOldcar.bootspace || "N/A",
        props.oldcar.viewSpecificationByOldcar.fuelTankCapacity || "N/A",
      ],
    },
    {
      question: "Suspensions, Brakes, Steering & Tyres",
      list1: [
        "Front Suspension",
        "Rear Suspension",
        "Front Brake Type",
        "Rear Brake Type",
        "Minimum Turning Radius",
        "Steering Type",
        "Wheels",
        "Spare Wheel",
        "Front Tyres",
        "Rear Tyres",
      ],
      list2: [
        props.oldcar.viewSpecificationByOldcar.frontSuspension || "N/A",
        props.oldcar.viewSpecificationByOldcar.rearSuspension || "N/A",
        props.oldcar.viewSpecificationByOldcar.frontbrakeType || "N/A",
        props.oldcar.viewSpecificationByOldcar.rearBrakeType || "N/A",
        props.oldcar.viewSpecificationByOldcar.minTurningRadius || "N/A",
        props.oldcar.viewSpecificationByOldcar.steeringType || "N/A",
        props.oldcar.viewSpecificationByOldcar.wheels || "N/A",
        props.oldcar.viewSpecificationByOldcar.spareWheel || "N/A",
        props.oldcar.viewSpecificationByOldcar.frontTyres || "N/A",
        props.oldcar.viewSpecificationByOldcar.rearTyres || "N/A",
      ],
    },
  ];

  const features = [
    {
      question: "Safety",
      list1: [
        props.oldcar.viewFeturesByOldcar.tyrePresssure ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Tyre Pressure Monitoring
            System (TPMS)
          </>
        ) : (
          ""
        ),

        props.oldcar.viewFeturesByOldcar.seatBeltWarning ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Seat Belt Warning
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.childSeatAnchorPoints ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Child Seat Anchor Points
          </>
        ) : (
          ""
        ),
      ],
    },
    {
      question: "Braking & Traction",
      list1: [
        props.oldcar.viewFeturesByOldcar.abs ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Anti-Lock Braking System
            (ABS)
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.ba ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Brake Assist (BA)
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.hillHoldControl ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Hill Hold Control
          </>
        ) : (
          ""
        ),

        // props.oldcar.viewFeturesByOldcar.hillHoldControl ? (
        //   <><CheckIcon sx={{ color: green[500] }} /> Limited Slip Differential (LSD)</>
        // ) : "",
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.ebd ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Electronic Brake-force
            Distribution (EBD)
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.esp ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Electronic Stability
            Program (ESP)
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.tcs ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Traction Control System (TC/TCS)
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Locks & Security",
      list1: [
        props.oldcar.viewFeturesByOldcar.engineImmobiliser ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Engine Immobiliser
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.speedSensingDoorLock ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Speed Sensing Door Lock
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.centeralLocking ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Central Locking
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Comfort & Convenience",
      list1: [
        props.oldcar.viewFeturesByOldcar.ac ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Air Conditioner
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.heater ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Heater
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.antiglareMirror ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Anti-glare Mirrors
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.parkingSensor ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Parking Sensors
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.headlightIgnition ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Headlight and Ignition On Reminder
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.steeringAdjustment ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Steering Adjustment
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.rearac ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Rear AC
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.vanityMirror ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Vanity Mirrors on Sun Visors
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.parkingAssist ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Parking Assist
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.cruisecontrol ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Cruise Control
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.keylessstart ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Keyless Start/ Button Start
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.power ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            12V Power Outlets
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Mobile App Features",
      list1: [
        props.oldcar.viewFeturesByOldcar.applecarplay ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Apple Carplay
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.androidauto ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Android Auto
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Seats & Upholstery",
      list1: [
        props.oldcar.viewFeturesByOldcar.seatUpholestry ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Seat Upholstery
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.leatherWrapped ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Leather-wrapped Gear Knob
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.headrests ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Headrest
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.leatherWrapped ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Leather-wrapped Steering Wheel
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.driverArmrest ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Driver Armrest
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.frontSeatbackPockets ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Front Seatback Pockets
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Storage",
      list1: [
        props.oldcar.viewFeturesByOldcar.cupholders ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Cup Holders
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.cooledGloveBox ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Cooled Glovebox
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.driverArmrestStorage ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Driver Armrest Storage{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.sunGlassHolder ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Sunglass Holder{" "}
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Doors, Windows, Mirrors & Wipers",
      list1: [
        props.oldcar.viewFeturesByOldcar.orvm ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> ORVM Colour{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.oneTouchDown ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> One Touch Down{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.adjustableOrvm ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Electric Adjustable ORVMs{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.exteriorDoorHandle ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Body Coloured Exterior Door Handles{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.chromeexteriorDoorHandle ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Chrome Exterior Door
            Handles{" "}
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.powerWindow ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Power Windows{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.oneTouchUp ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> One Touch Up{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.oneTouchUp ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> One Touch Up{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.doorPockets ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Door Pockets
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.bootlidOpener ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Bootlid Opener{" "}
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Exterior",
      list1: [
        props.oldcar.viewFeturesByOldcar.sunroof ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Sunroof / Moonroof{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.roofMountedAntenna ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Roof-mounted Antenna
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.bodyColouredBumpers ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Body-coloured Bumpers{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.chromeFinish ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Chrome Finish Exhaust Pipe
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Lighting",
      list1: [
        props.oldcar.viewFeturesByOldcar.projectorheadlight ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Projector Headlights{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.homeheadlamps ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Follow Me Home Headlamps
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.tailights ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            LED Taillights{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.lightonVanityMirror ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Light on Vanity Mirrors
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.gloveBoxlamp ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Glovebox Lamp{" "}
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.autoheadlamps ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Automatic Headlamps{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.drl ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> DRL{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.cabinLamps ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Cabin Lamps{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.rearreadinglamp ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Rear Reading Lamp
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.headlightHeightAdjuster ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Headlight Height Adjuster{" "}
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Instrumentation",
      list1: [
        props.oldcar.viewFeturesByOldcar.averageFuelConsumption ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Average Fuel Consumption{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.tripMeter ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Trip Meter{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.distancetoEmpty ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Distance to Empty
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.clock ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Clock{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.lowFuelWarning ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Low Fuel Level Warning{" "}
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.doorAjarWarning ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Door Ajar Warning{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.adjustableCluster ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Adjustable Cluster
            Brightness{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.gearIndicator ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Gear Indicator{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.shiftIndicator ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Shift Indicator{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.tachometer ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            Tachometer{" "}
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Entertainment, Information & Communication",
      list1: [
        props.oldcar.viewFeturesByOldcar.musicSystem ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Integrated (in-dash) Music
            System{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.speaker ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Speakers{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.steeringmountedctrls ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Steering-mounted controls{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.voiceCommand ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Voice Command{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.gps ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> GPS Navigation System{" "}
          </>
        ) : (
          ""
        ),
      ],
      list2: [
        props.oldcar.viewFeturesByOldcar.bluetooth ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Bluetooth Compatibility{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.aux ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> AUX Compatibility{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.radio ? (
          <>
            <CheckIcon sx={{ color: green[500] }} />
            AM/FM Radio{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.usb ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> USB Compatibility{" "}
          </>
        ) : (
          ""
        ),
        props.oldcar.viewFeturesByOldcar.headUnitSize ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Head Unit Size{" "}
          </>
        ) : (
          ""
        ),
      ],
    },

    {
      question: "Manufacturer Warranty",
      list1: [
        props.oldcar.viewFeturesByOldcar.des12 ? (
          <>
            <CheckIcon sx={{ color: green[500] }} /> Warranty{" "}
          </>
        ) : (
          ""
        ),
      ],
      list2: [],
    },
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleTabChange = (tab) => {
    setExpanded(null);
    setSelectedTab(tab);
  };

  const car = {
    id: 1,
    name: "2012 Maruti Suzuki Wagon R LXi CNG",
    fuel: "55,387 km  |  CNG  |  Azad Nagar, Mumbai",
    price: "Rs. 2.95 Lakh",
    image: car1a,
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(); // Formats date as "MM/DD/YYYY" or based on locale

    // Calculate "days ago"
    const daysAgo = formatDistanceToNow(date, { addSuffix: true }); // Shows "x days ago"

    return `${formattedDate} (${daysAgo})`;
  };
  const overviewData = [
    {
      icon: <CurrencyRupeeIcon />,
      label: "Price",
      value: `₹ ${props.oldcar.viewOldcarById?.discountedPrice || "N/A"}`,
    },
    {
      icon: <SpeedIcon />,
      label: "Kilometer",
      value: `${props.oldcar.viewOldcarById?.kilometer || "N/A"} km`,
    },
    {
      icon: <LocalGasStationIcon />,
      label: "Fuel type",
      value: props.oldcar.viewOldcarById?.fuelType || "N/A",
    },
    {
      icon: <CalendarTodayIcon />,
      label: "Registration year",
      value:
        props.oldcar.viewOverviewByOldcar?.registerationYear || "Not Available",
    },
    {
      icon: <BuildIcon />,
      label: "Manufacturing year",
      value: props.oldcar.viewOverviewByOldcar?.manufacturingYear || "N/A",
    },
    {
      icon: <PersonIcon />,
      label: "No. of owners",
      value: props.oldcar.viewOverviewByOldcar?.owners || "N/A",
    },
    {
      icon: <SettingsIcon />,
      label: "Transmission",
      value: props.oldcar.viewOverviewByOldcar?.transmission || "N/A",
    },
    {
      icon: <ColorLensIcon />,
      label: "Color",
      value: props.oldcar.viewOverviewByOldcar?.color || "N/A",
    },
    // {
    //   icon: <LocationOnIcon />,
    //   label: "Car Available at",
    //   value: props.oldcar.viewOldcarById?.location || 'N/A',
    // },
    {
      icon: <VerifiedUserIcon />,
      label: "Insurance",
      value: props.oldcar.viewOverviewByOldcar?.insurance || "N/A",
    },
    {
      icon: <VerifiedUserIcon />,
      label: "Registration type",
      value: props.oldcar.viewOverviewByOldcar?.registerationType || "N/A",
    },
    {
      icon: <UpdateIcon />,
      label: "Last Updated",
      value: props.oldcar.viewOverviewByOldcar?.createdAt
        ? formatDate(props.oldcar.viewOverviewByOldcar.createdAt)
        : "N/A",
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const { id } = useParams();
  const { brand } = useParams();
  const handleOpenModal = (car) => {
    setSelectedCar(car);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleImageClick = (carId, brand) => {
    sessionStorage.setItem("scrollPosition", window.scrollY);
    navigate(`/car-details/${carId}/${brand}`);
  };
  const images = props?.oldcar?.viewOldcarById?.image || [];
  useEffect(() => {
    props.viewOverviewByOldCarId(id);
    props.viewOldCarById(id);
    props.viewSpecificationByOldCarId(id);
    props.viewFeturesById(id);
    props.viewCarsByBrand(id, brand);
    window.scrollTo({ top: 0, behavior: "smooth" });

    // console.log("20pxccccccccccccc", props.oldcar.viewOverviewByOldcar);
    // console.log("20pxcxxxxx", props.oldcar.viewOldcarById);

    //     console.log("20pxccssssssss", props.oldcar.viewFeturesByOldcar);
    console.log("aaaaaaaaaa", props.oldcar.carByBrand);
  }, [id, brand]);
  return (
    <Container sx={{ marginTop: "120px" }}>
      <Card>
        <Grid container spacing={2}>
          {/* Left Side: Car Image */}
          <Grid item xs={12} md={6}>
            <CardMedia
              component="img"
              image={images[0]} // Display the first image
              alt={props?.oldcar?.viewOldcarById?.carName}
              sx={{ height: "350px", objectFit: "cover", cursor: "pointer" }}
              onClick={handleClickOpen} // Open dialog on click
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ padding: 3 }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "15px",
                  fontSize: { md: "22px", sm: "20px", xs: "17px" },
                }}
              >
                {props.oldcar.viewOldcarById.carName}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  fontSize: { md: "18px", sm: "16px", xs: "13px" },
                }}
              >
                {props.oldcar.viewOldcarById.fuelType}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  fontSize: { md: "25px", sm: "22px", xs: "19px" },
                  marginBottom: "19px",
                }}
              >
                Rs. {props.oldcar.viewOldcarById.discountedPrice}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  fontSize: { md: "25px", sm: "22px", xs: "19px" },
                  marginBottom: "19px",
                }}
              >
                Rs. {props.oldcar.viewOldcarById.price}
              </Typography>
              {/* <Button
                variant="contained"
                width="100%"
                sx={{
                  backgroundColor: "#E03012",
                  color: "white",
                  fontWeight: "900",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenModal(car);
                }}
              >
                Get Seller Details
              </Button> */}
            </Box>
            <Modal open={openModal} onClose={handleCloseModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "800" }}
                >
                  Fill in your Details
                </Typography>
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ fontSize: "12px" }}
                >
                  Provide your contact details to get seller's contact
                </Typography>
                <TextField
                  label="Enter Your Full Name"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Enter Mobile Number"
                  fullWidth
                  margin="normal"
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#E03012",
                    color: "white",
                    FontWeight: "900",
                  }}
                  fullWidth
                >
                  Submit
                </Button>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Card>

      {/* Car Overview */}
      <Box sx={{ margin: "20px" }}>
        <Typography
          variant="h4"
          sx={{
            color: "#cf2230",
            fontWeight: "800",
            paddingBottom: "20px",
            fontSize: { lg: "22px", md: "22px", sm: "17px", xs: "17px" },
            fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
          }}
        >
          Car Overview
        </Typography>
        <Card sx={{ padding: 2, backgroundColor: "#F9F9F9" }}>
          <Grid container spacing={0} sx={{ borderRadius: "8px" }}>
            {overviewData.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "16px",
                  gap: "5px",
                  borderRight: index % 3 !== 2 ? "1px solid #ddd" : "none",
                  borderBottom: index < 9 ? "1px solid #ddd" : "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "10px", color: "#616161" }}>
                    {item.icon}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#484848",
                      fontFamily: "LatoGoogle, Lato, LatoWeb, sans-serif",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
                {/* Content */}
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#565A5C",
                      fontWeight: "bold",
                      fontSize: { md: "15px", sm: "14px", xs: "12px" },
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Box>

      {/* Features and Specifications */}
      <Container>
        <Typography
          variant="h4"
          sx={{
            color: "#cf2230",
            fontWeight: "800",
            fontSize: { lg: "22px", md: "22px", sm: "17px", xs: "17px" },
            fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
          }}
        >
          Specifications and Features
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: { md: "15px", sm: "14px", xs: "15px" },
              fontWeight: "800",
              cursor: "pointer",
              marginTop: "20px",
              ...styles.tab,
              ...(selectedTab === "Specifications" ? styles.activeTab : {}),
            }}
            onClick={() => handleTabChange("Specifications")}
          >
            Specifications
          </Typography>
          &nbsp;&nbsp;
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              backgroundColor: "#000",
              marginTop: "20px",
              height: "22px",
            }}
          />
          &nbsp;&nbsp;
          <Typography
            variant="h6"
            sx={{
              fontSize: { md: "15px", sm: "14px", xs: "15px" },
              fontWeight: "800",
              cursor: "pointer",
              marginTop: "20px",
              ...styles.tab,
              ...(selectedTab === "Features" ? styles.activeTab : {}),
            }}
            onClick={() => handleTabChange("Features")}
          >
            Features
          </Typography>
        </Box>

        {/* Render Specifications or Features */}
        {selectedTab === "Specifications" && (
          <Box>
            {specifications.map((item, index) => (
              <QuestionBox
                key={index}
                question={item.question}
                expanded={expanded === `spec-${index}`}
                onToggle={() => handleToggle(`spec-${index}`)}
                list1={item.list1}
                list2={item.list2}
                // Add this style to remove bullet points
                sx={{ listStyleType: "none", paddingLeft: 0 }}
              />
            ))}
          </Box>
        )}

        {selectedTab === "Features" && (
          <Box>
            {features.map((item, index) => (
              <QuestionBox
                key={index}
                question={item.question}
                expanded={expanded === `feat-${index}`}
                onToggle={() => handleToggle(`feat-${index}`)}
                list1={item.list1}
                list2={item.list2}
                // Add this style to remove bullet points
                sx={{ listStyleType: "none", paddingLeft: 0 }}
              />
            ))}
          </Box>
        )}
      </Container>
      {/* FAQs */}
      <Faqs />
      {/* cards section */}
      <Box sx={{ maxWidth: "100%", padding: "20px", position: "relative" }}>
        <Typography
          sx={{
            color: "#333333",
            fontSize: { md: "25px", sm: "20px", xs: "18px" },
            fontWeight: "bolder",
          }}
        >
          Similar New Cars
        </Typography>

        <Slider ref={sliderRef} {...carouselSettings}>
          {props.oldcar.carByBrand.map((item) => (
            <Card
              key={item._id} // Use a unique identifier for the key
              sx={{ maxWidth: 300, margin: "10px", height: "auto" }}
              onClick={() => handleImageClick(item._id, item.brand)}
            >
              <CardMedia
                component="img"
                image={item.image[0]} // Display the first image in the array
                alt={item.carName}
                sx={{ height: 200, objectFit: "cover" }} // Adjust image size as needed
              />
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "8px",
                    color: "#333333",
                    fontSize: { md: "16px", sm: "15px", xs: "13px" },
                  }}
                >
                  {item.carName}
                </Typography>
                {/* Display discounted price with ₹ symbol */}
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "#d32f2f", // Change this color for the discounted price
                    fontSize: { md: "16px", sm: "15px", xs: "13px" },
                    fontWeight: "800",
                  }}
                >
                  ₹{item.discountedPrice}
                </Typography>
                {/* Display original price with strikethrough and ₹ symbol */}
                <Typography
                  variant="h5"
                  sx={{
                    textDecoration: "line-through",
                    color: "#999999", // Gray color for the original price
                    fontSize: { md: "16px", sm: "15px", xs: "13px" },
                    fontWeight: "400",
                  }}
                >
                  ₹{item.price}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Slider>

        <ChevronLeftIcon
          sx={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "black",
            zIndex: 1000,
          }}
          onClick={goToPrevious}
        />
        <ChevronRightIcon
          sx={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "black",
            zIndex: 1000,
          }}
          onClick={goToNext}
        />
      </Box>

      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
        <DialogTitle>
          Images
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    overflow: "hidden",
                    boxShadow: 3, // Adding shadow for depth
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.05)", // Scale effect on hover
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={image}
                    alt={`Image ${index + 1}`}
                    sx={{
                      height: "auto",
                      width: "100%",
                      maxHeight: "400px", // Limit max height
                      objectFit: "contain", // Maintain aspect ratio
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CarDetailPage;
