import { Component } from "react";
import React from "react";
import "./About.css";
import {
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import Navbar from "../../Home/Components/Nav";
import Footer from "../../Home/Components/Footer";
import heritage from "../../images/aboutheritage.webp";
import company from '../../images/parentcompany.webp'
import operations from "../../images/opeartions.webp";
import girl from '../../images/girledu.webp';
import roadsafety from '../../images/roadsafety.jpg'
import history from '../../images/history.jpg';
import innovation from '../../images/innovation.webp';
import brand from '../../images/brand.webp'
class About extends Component {
 
  render() {
   
    return (
      <div id="container">
        <Navbar />
        {/* Banner */}
        <div id="banner-image-about">
          <div id="write">
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: { md: "70px", xs: "16px", sm: "50px" },
                position: "relative",
                marginTop:{md:'350px',sm:'200px',xs:'180px'},
                bottom: "50px",
                color:'white'
              }}
            >
              DEDICATED TO MOTORING EXPERIENCES SINCE 1924
            </Typography>
          </div>
        </div>
        {/* Heritage */}
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                

              }}
            >
              <img
                src={heritage}
                alt="heritage"
                style={{ width: "100%", height: "auto", objectFit: "cover",marginTop:'30px', }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                ABOUT US
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "LatoGoogle,Lato,LatoWeb,sans-serif",
                  textAlign: "left",
                  fontSize: { xs: "12px", sm: "16px", md: "15px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
              Welcome to MG Central, your premier MG showroom and trusted destination for the latest MG cars in Odisha. We proudly showcase a wide range of MG models, including cutting-edge SUVs and stylish sedans, each designed to offer superior technology, performance, and style. Our goal is to provide you with a smooth, transparent car-buying experience that helps you find the perfect MG vehicle to match your lifestyle.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "LatoGoogle,Lato,LatoWeb,sans-serif",
                  textAlign: "left",
                  fontSize: { xs: "12px", sm: "16px", md: "15px" },
                  marginTop:'2px',
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
                At MG Central, we prioritize exceptional customer service, with a team ready to guide you through every step—from exploring our MG models to understanding financing options. Located in Bhubaneswar, MG Central is dedicated to making your car-buying journey as seamless and enjoyable as possible.
                Discover the reliability, innovation, and quality that define MG. Visit MG Central in Khandagiri, Bhubaneswar today, and experience a new level of driving confidence and satisfaction.
              </Typography>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box> */}
            </Grid>
          </Grid>
        </Box>

        {/*CENTERAL GROUP */}
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                    ABOUT CENTERAL GROUP
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "LatoGoogle,Lato,LatoWeb,sans-serif",
                  textAlign: "left",
                  fontSize: { xs: "12px", sm: "16px", md: "14px" },
                  marginBottom: "10px",
                  width:{md:'550px'}
                }}
              >
             Central Group is a premier automotive network serving Odisha and beyond, known for representing top automobile brands such as Kia, Honda, Renault, Tata Small Commercial Vehicle, and MG Motors & BYD. Our extensive dealership network includes showrooms in Sambalpur, Angul, Jharsuguda, Jajpur, Bhubaneswar, Cuttack, Puri, Nayagarh, Berhampur, Ganjam, Phulbani, and Vizag. We provide exceptional customer service and a wide selection of new and certified pre-owned vehicles, catering to a diverse range of needs and preferences.
             </Typography>
             <Typography
                variant="body1"
                sx={{
                  fontFamily: "LatoGoogle,Lato,LatoWeb,sans-serif",
                  textAlign: "left",
                  fontSize: { xs: "12px", sm: "16px", md: "14px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
              With a commitment to quality and transparency, Central Group ensures a premium car-buying experience where customers can explore the latest models and find reliable, budget-friendly options. Our deep industry expertise and dedication to customer satisfaction have helped us build a strong reputation in the automotive sector. Proudly serving the community across multiple locations, we help drivers and families find vehicles that match their lifestyle, budget, and taste. Discover why so many choose Central Group for a seamless and rewarding car-buying journey backed by reliability, variety, and trusted support.
              </Typography>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={company}
                alt="parentcompany"
                style={{ width: "100%", height: "auto", objectFit: "cover",marginTop:'30px'
                }}
                
              />
            </Grid>
          </Grid>
        </Box>

        {/* operations */}
        {/* <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={operations}
                alt="operations"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                INDIA OPERATIONS
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
              With the inauguration of its first manufacturing facility in September 2017 in Halol, Gujarat, MG is now fully operational in India. If the future of the automobile truly excites you, follow us on our digital channels to stay updated.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box> */}

        {/* Girl child educations */}
        {/* <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                    GIRL CHILD EDUCATION
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
              MG has joined hands with IIMPACT to provide access to quality education to at least 1,000 girl children in 2019. IIMPACT is an NGO engaged in providing quality education to the girl child through its learning centres in remote villages of India.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={girl}
                alt="girledu"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        </Box> */}

        {/* Road Safety */}
        {/* <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={roadsafety}
                alt="safety"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems:'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                ROAD SAFETY
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                  width:{md:'550px'}
                }}
              >
             As part of the commitment towards road safety, Haryana Government, MG Motor India and TRAX NGO have joined hands to train teachers and educate school students in Gurugram and Faridabad to focus on spreading road safety awareness.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                >
                  Know More
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box> */}

        {/* Image gallery */}
        {/* <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid 
        container 
        spacing={1} 
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={4} md={2.5}>
          <Box textAlign="center">
            <img src={history} alt="history" width={250} height={150} />
            <Typography variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",}}>HISTORY</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={2.5}>
          <Box textAlign="center">
            <img src={innovation} alt="innovation" width={250} height={150} />
            <Typography variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",}}>INNOVATIONS</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={2.5}>
          <Box textAlign="center">
            <img src={brand} alt="brand" width={250} height={150} />
            <Typography variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",}}>BRAND PILLARS</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>       */}
        {/* Footer Section */}
        <Footer />
      </div>
    );
  }
}

export default About;
