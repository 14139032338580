import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import ext1 from '../../../../../images/Gloster/ext1.webp';
import ext2 from '../../../../../images/Gloster/ext2.webp';
import ext3 from '../../../../../images/Gloster/ext3.webp';
import ext4 from '../../../../../images/Gloster/ext4.webp';
import int1 from '../../../../../images/Gloster/int1.webp'; 
import int2 from '../../../../../images/Gloster/int2.webp';
import int3 from '../../../../../images/Gloster/int3.webp';
import safe1 from '../../../../../images/Gloster/safety1.webp';
import safe2 from '../../../../../images/Gloster/safety2.webp';
import safe3 from '../../../../../images/Gloster/safety3.webp';
import adas1 from '../../../../../images/Gloster/adas1.webp';
import adas2 from '../../../../../images/Gloster/adas2.webp';
import adas3 from '../../../../../images/Gloster/adas3.webp';
import adas4 from '../../../../../images/Gloster/adas4.webp';
import i1 from '../../../../../images/Gloster/i1.webp';
import terrain from '../../../../../images/Gloster/terrain.webp';
import power from '../../../../../images/Gloster/4ft.webp'


class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'EXTERIOR',
      isMobile: window.innerWidth <= 900, // Detect initial mobile view
      selectedImage: this.getTabImages('EXTERIOR')[0], 
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };


  getTabImages = (tab) => {
    const tabData = {
      EXTERIOR: [
        {
          src: ext1,
          label:'Exterior Body',
          title: 'Exterior body',
          subtitle: 'A Design that roars on the roads',
          description: 'Defining a new meaning of luxury. The Advanced Gloster is inspired by multiple facets of the world around us, which are translated into its unique design language that magnifies the dynamics of power and luxury to complement just one – YOU.',
        },
        {
          src: ext2,
          label:'Floating Roof Design',
          title: 'Floating Roof Design',
          subtitle: 'A Design that roars on the roads',
          description: 'The Advanced Gloster comes with a floating roof design to glide you through every scenic adventures.',
        },
        {
          src: ext3,
          label: 'Tail Lamp',
          title:'Tail lamp',
          subtitle: 'A Design that roars on the roads',
          description: 'The diffused light guide in the advanced Gloster’s tail lamp is inspired by unique racetrack patterns celebrating its uniqueness.',
        },
        {
          src: ext4,
          label: 'Octagon Signature Grille',
          title:'Octagon Signature Grille',
          subtitle: 'A Design that roars on the roads',
          description: 'The octagon signature grille is inspired by an oriental philosophy that signifies good health and fortune.',
        }
      ],
      INTERIOR: [
        {
          src: int1,
          label:'Interior Dashboard',
          title: 'Interior Dashboard',
          subtitle: 'Aesthetic Interiors Powerful Demeanour',
          description: 'Every inch of The Advanced Gloster is thoughtfully and aesthetically designed around only one central emotion– YOU. Find yourself embraced by a world of curated and personalised luxury as you step inside.',
        },
        {
          src: int2,
          label:'Ambient Lighting',
          title: "Ambient Lighting",
          subtitle: 'Aesthetic Interiors Powerful Demeanour',
          description: 'Set your tone for the journey from the 64 customisable lighting options, just like you set on your own adventures.',
        },
        {
          src: int3,
          label:'31.2cm HD Touchscreen Infotainment',
          title: '31.2cm HD Touchscreen Infotainment',
          subtitle: 'Aesthetic Interiors Powerful Demeanour',
          description: 'Explore more escapes but never run out of entertainment on your adventures with the 31.2 cm grand display screen of the Gloster which serves as a cinematic canvas for your journeys.',
        },

      ],
      SAFETY: [
        {
          src: safe1,
          label:'6 Airbags | HHC | ESP | HDC',
          title: '6 Airbags | HHC | ESP | HDC',
          subtitle: 'Safety That is Adventure-ready',
          description: 'Face the uncertainties of the road with the empowering Gloster by your side. Leverage next-generation car technology and more than 30 standard safety features that meet the highest and toughest international certifications to deliver a system that never compromises your best interests.6 Airbags,Hill Hold Control (HHC),Electronic Stability Program (ESP),Hill Descent Control (HDC)​​',
        },
        {
          src: safe2,
          label:'TCS | RMI | ABS/ EBD/ Brake Assist | Electric Parking Brake with Autohold',
          title: 'TCS | RMI | ABS/ EBD/ Brake Assist | Electric Parking Brake with Autohold',
          subtitle: 'Safety That is Adventure-ready',
          description: 'Face the uncertainties of the road with the empowering Gloster by your side. Leverage next-generation car technology and more than 30 standard safety features that meet the highest and toughest international certifications to deliver a system that never compromises your best interests.Traction Control System (TCS),Roll Movement Intervention (RMI),ABS + EBD + Brake Assist,Electric parking Brake with Autohold',
        },
        {
          src: safe3,
          label: 'Driver fatigue reminder system | AVH | 360˚ Camera | EDL',
          title:'Driver fatigue reminder system | AVH | 360˚ Camera | EDL',
          subtitle: 'Safety That is Adventure-ready',
          description: 'Face the uncertainties of the road with the empowering Gloster by your side. Leverage next-generation car technology and more than 30 standard safety features that meet the highest and toughest international certifications to deliver a system that never compromises your best interests.',
          description2:'Driver fatigue reminder system: Automatic Vehicle Hold (AVH),360˚ Around View Camera,Electro-mechanical Differential Lock (EDL)'
        },
       
      ],
      ADAS: [
        {
          src: adas1,
          label:'Lane Change Assist',
          title: 'LANE CHANGE ASSIST',
          subtitle: 'Assisted and Intuitive Driving',
          description: 'Lane Change Assist (LCA) aids drivers in changing lanes safely by utilizing sensors to detect and warn about approaching cars in adjacent lanes. It can help alert you about vehicles approaching your blind spots, which can prevent lane change accidents on the road. ',
        },
        {
          src: adas2,
          label:'Rear Cross Traffic Alert',
          title: "REAR CROSS TRAFFIC ALERT",
          subtitle: 'Assisted and Intuitive Driving',
          description: 'Whenever there is a danger of collision during reversing and leaving a parking area, then the warning light on the corresponding side flashes along with an alarm icon, which is displayed on the reversing image screen for 3 seconds to alert you of the incoming danger.',
        },
        {
          src: adas3,
          label:'Door Open Warning',
          title: 'DOOR OPEN WARNING',
          subtitle: 'Assisted and Intuitive Driving',
          description: 'When you pull over and park, the rear radar on the side of the Gloster detects any moving vehicle on its adjacent sides in real time. When it senses that there is a risk of impact between the moving target on the rear side and the door of the Gloster, the DOW system alerts you of the risk of a door opening collision.',
        },
        {
          src: adas4,
          label:'Blind Spot Detection',
          title: 'BLIND SPOT DETECTION',
          subtitle: 'Assisted and Intuitive Driving',
          description: 'Blind Spot Detection systems use sensors to provide drivers with important information that is otherwise difficult or impossible to obtain. This feature detects and alerts the driver of any sudden movements in their blind spot – be it over-taking vehicles or side-lane strafing by motorcyclists.',
        }
      ],
      "i-SMART": [
        {
          src: i1,
          title: 'i-SMART 2.0',
          subtitle: 'Next-gen Technology',
          description: 'The Advanced Gloster takes technological benchmarks to a new notch with its vast set of upgraded and smart-tech features. It is powered by a network of intuitive, intelligent and futuristic technology - a leading testament to our thoughtful, YOU-first engineering approach.',
        },
       
      ],
      '7 TERRAIN MODES': [
        {
          src: terrain,
          title:'7 TERRAIN MODES',
          subtitle: 'All Terrain System',
          description: 'Equipped with BorgWarner transfer case, The Advanced Gloster’s on demand or real-time intelligent 4WD drive train adeptly provides top class torque control and distribution.Snow,Sand,Mud,Rock,Eco,Auto,Sports.',
        },

      ],
      "4x4": [
        {
          src: power,
          title: 'INTELLIGENT 4x4',
          subtitle: 'Capable Beyond Convention',
          description: 'With great power and capability that The Advanced Gloster comes with, it stands true to the values it reflects and delivers on what its made for – the outdoors and the adventures. Built on the next generation intelligent 4 wheel drive system, it takes on and tackles all kinds of terrains that come its way.',
        },
      
        
      ],
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0], // Default to first image of the selected tab
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage } = this.state;
    const images = this.getTabImages(selectedTab);
  
    return (
      <Box sx={{ width: '80%', height:'100%',
      }}>
        <Box
          sx={{
            height:'90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' },
     
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '97vw',
              maxWidth: '500px',
              height: 'auto',
            }}
            alt={selectedImage.title}
          />
  
          <style>
            {`
              @media (max-width: 768px) { /* Tablet and smaller */
                img {
                  width: 80vw; /* Adjust the width for tablet view */
                  max-width: 100%; /* Ensure it doesn't exceed container width */
                }
              }
  
              @media (max-width: 480px) { /* Mobile view */
                img {
                  width: 90vw; /* Increase width for mobile view */
                  max-width: 100%;
                }
              }
            `}
          </style>
  
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { md: 'space-around', xs: 'start', sm: 'start' },
              alignItems: 'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-25px', md: '0px' }
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: { xs: '90%', md: '510px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '90%', md: '330px', sm: '90%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.5vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '100%', md: '460px', lg: '450px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.1vw' },
                color: '#000',
                marginBottom: '0.333vw',
                paddingTop: '1.30208vw',
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                  width: '280px',
                },
              }}
            >
              {selectedImage.description}
            </Typography>
            <Typography
              sx={{
                width: { xs: '100%', md: '460px', lg: '450px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '0.30208vw',
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                  width: '280px',
                },
              }}
            >
              {selectedImage.description2}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: { xs: 'center', md: 'flex-start' },
            gap: '10px',
            marginTop: { xs: '10px', md: '50px', lg: '20px' },
            marginLeft: { xs: '-10px', md: '0px' },
          }}
        >
          {images.map((img, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { md: 'center', xs: 'start' }
              }}
            >
              <img
                src={img.src}
                style={{
                  borderRadius: '10px',
                  width: '20vw',
                  maxWidth: '250px',
                  height: 'auto',
                  marginTop: { xs: '10px', md: '40px', sm: '20px' }
                }}
                alt={img.label}
                onClick={() => this.handleImageClick(img)}
              />
              <Typography
                sx={{
                  fontFamily: 'Roboto-Medium, Arial, sans-serif',
                  fontSize: { xs: '3.5vw', md: '1.04167vw' },
                  color: '#000',
                  paddingTop: '1.04167vw',
                  margin: 0,
                  fontWeight: '550',
                  display: { md: 'flex', xs: 'none' },
                  textAlign: { xs: 'none', md: 'left' },
                  width: '100%'
                }}
              >
                {img.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  


  render() {
    const { selectedTab, isMobile } = this.state;
    const tabs = ['EXTERIOR', 'INTERIOR', 'SAFETY', 'ADAS', 'i-SMART', '7 TERRAIN MODES','4x4',];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          // minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' }, 
            color: '#cf2230',
            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop:'20px'
          }}
        >
          A Car That Has It All
        </Typography>

        {isMobile ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px', }}>
            <Select
              value={selectedTab}
              style={{fontWeight:'700'}}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' }, 
                  color:'black',
                  fontWeight: selectedTab === tab ? 'bolder' : '500',
                  backgroundColor: selectedTab === tab ? '#D8DDD8' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#D8DDD8',
                    color: 'white',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display:{md:'flex',xs:'none'},border: '4px solid #E32735', margin: '20px 0' }} />

        {this.renderContent()}

        {/* <Button
          sx={{
            backgroundColor: '#CF2230',
            color: 'white',
            fontWeight: '700',
            fontFamily: 'GillSansSemiBold , sans-serif',
            fontSize: { xs: '3.5vw', md: '0.95vw',sm:'2.2vw' }, 
            cursor: 'pointer',
            marginTop:'30px',
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
            },
          }}
        >
          Virtual Showroom
        </Button> */}
      </Box>
    );
  }
}

export default Features;
