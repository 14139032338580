import React, { Component } from "react";
import { connect } from "react-redux";
import Addoldcar from "../Components/addoldcar";

import {
    addOldCar,
} from "../action";
export class Controller extends Component {
    render() {
        return (
            <Addoldcar {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        client: store.client,
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        addOldCar: (token, user_id, brand,carName,
            fuelType,
            carType,
            price,
            discountedPrice,
            kilometer, images) => {
            dispatch(addOldCar(token, user_id, brand,carName,
                fuelType,
                carType,
                price,
                discountedPrice,
                kilometer, images));
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
