import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import greygloster from '../../../../images/Gloster/greygloster.webp';
import greyishgloster from '../../../../images/Gloster/greyish.webp';
import blackgloster from '../../../../images/Gloster/blackgloster.webp';
import whitegloster from '../../../../images/Gloster/whitegloster.webp';

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [fixedImage, setFixedImage] = useState(greygloster); 

  const carImages = [
    { src: greygloster, alt: "greygloster", color: "#6F6667" },
    { src: greyishgloster, alt: "greyishgloster", color: "#3F4C50" },
    { src: blackgloster, alt: "blackgloster", color: "black" },
    { src: whitegloster, alt: "whitegloster", color: "#9CA2A5" },

  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
      <Carousel 
        activeIndex={activeIndex} 
        onSelect={handleSelect} 
        interval={null} 
        pause={false}
        indicators={false}
        controls={false}
      >
        {carImages.map((car, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={fixedImage} alt={car.alt} />
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Custom Circle Indicators */}
      <div style={{ position: "absolute", bottom: "20px", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
        {carImages.map((car, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            style={{
              width: "23px",
              height: "22px",
              borderRadius: "50%",
              backgroundColor: activeIndex === index ? car.color : car.color, 
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
