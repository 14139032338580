import { Component } from "react";
import React from "react";
import "./Service.css";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import Navbar from "../../Home/Components/Nav";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobile: "",
      model: "",
      carRegisterationNumber: "",
      prefferedServiceCenterLocation: "",
      preferredDateforService: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService,
    } = this.state;
    this.props.AddServiceForm(
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService
    );
    this.setState({
      name: "",
      email: "",
      mobile: "",
      model: "",
      carRegisterationNumber: "",
      prefferedServiceCenterLocation: "",
      preferredDateforService: "",
    });
  };
  render() {
    const {
      name,
      email,
      mobile,
      model,
      carRegisterationNumber,
      prefferedServiceCenterLocation,
      preferredDateforService,
    } = this.state;
    return (
      <div id="container">
        <Navbar />
        {/* Banner */}
        <div id="banner-image-service">
          {/* <div id="write">
            <Typography
              variant="h4"
              sx={{
                color: "grey",
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: { md: "40px", xs: "16px", sm: "30px" },
                position: "relative",
                bottom: "50px",
              }}
            >
              SERVICE APPOINTMENT BOOKING 
            </Typography>
          </div> */}
        </div>
        {/* CONTENT */}
        <Grid container spacing={4} sx={{ width: "90%", margin: "20px" }}>
          {/* Title Section */}
          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
              }}
              gutterBottom
            >
              MG Central Service Appointment Booking
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontFamily: "LatoGoogle,Lato,LatoWeb,sans-serif" }}
            >
              Welcome to MG Central’s Service Appointment Booking page, your
              first step to ensuring your MG vehicle receives top-notch care.
              Our experienced technicians are dedicated to providing exceptional
              service to keep your vehicle running smoothly. Whether you need
              routine maintenance, repairs, or a thorough inspection, we’re here
              to help.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
              }}
              gutterBottom
            >
              Why Choose us?
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontFamily: "LatoGoogle,Lato,LatoWeb,sans-serif" }}
            >
              MG Central is a reliable and trusted brand known for its
              transparency and commitment to excellence. At MG Central
              Authorised Service Centre, we have skilled manpower who possess
              in-depth knowledge of MG cars. This helps us ensure better
              customer satisfaction by delivering high-quality car service in a
              timely manner. Furthermore, MG’s vast service network extends a
              strong sense of assurance to its customers and also provides the
              convenience of doorstep pickup and delivery for car servicing. And
              to elevate the overall car ownership experience, MG Central offers
              loyalty and reward programs which can be redeemed at MG Central
              Authorised Service Centre’s. Download the MG Central Rewards app
              to avail these rewards and avail additional savings.
            </Typography>
          </Grid>

          {/* Steps Section */}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
              }}
            >
              How to Book Your Service Appointment
            </Typography>
            <ol>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: "10px",
                    fontFamily: "LatoGoogle,Lato,LatoWeb,sans-serif",
                  }}
                >
                  <strong>Fill Out the Form:</strong> Complete our simple online
                  booking form with your contact information, vehicle details,
                  and service needs.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: "5px",
                    fontFamily: "LatoGoogle,Lato,LatoWeb,sans-serif",
                  }}
                >
                  <strong>Select a Date and Time:</strong> Choose a convenient
                  date and time for your appointment.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: "5px",
                    paddingBottom: "20px",
                    fontFamily: "LatoGoogle,Lato,LatoWeb,sans-serif",
                  }}
                >
                  <strong>Confirmation:</strong> Receive a confirmation call
                  from us for your service booking with all the details of your
                  appointment.
                </Typography>
              </li>
            </ol>
          </Grid>
        </Grid>

        {/*  FORM SECTION*/}
        <Container
          maxWidth="md"
          style={{ paddingBottom: "20px", backgroundColor: "#F9F9F9" }}
        >
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "6vw", md: "2.08333vw" },
                color: "#cf2230",
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                fontWeight: "400",
                marginTop: "15px",
              }}
            >
              Service Appointment Form
            </Typography>
          </Box>

          <form onSubmit={this.handleSubmit}>
            <Box noValidate autoComplete="off" sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {/* Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Enter Your Name"
                    type="text"
                    variant="standard"
                    name="name"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.name}
                    onChange={(e) => {
                      this.setState({ name: e.target.value });
                    }}
                  />
                </Grid>

                {/* Email */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Enter Your Email"
                    type="email"
                    variant="standard"
                    name="email"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                </Grid>

                {/* Mobile Number */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Mobile Number"
                    type="number"
                    variant="standard"
                    name="mobile"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.mobile}
                    onChange={(e) => {
                      this.setState({ mobile: e.target.value });
                    }}
                  />
                </Grid>

                {/* Model Dropdown */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <InputLabel
                      sx={{ "& .MuiInputLabel-asterisk": { color: "#d32f2f" } }}
                    >
                      Model
                    </InputLabel>
                    <Select
                      label="Model"
                      name="model"
                      value={this.state.model}
                      onChange={(e) => {
                        this.setState({ model: e.target.value });
                      }}
                    >
                      <MenuItem value="Astor">Astor</MenuItem>
                      <MenuItem value="Hector">Hector</MenuItem>
                      <MenuItem value="Gloster">Gloster</MenuItem>
                      <MenuItem value="Comet">Comet EV</MenuItem>
                      <MenuItem value="Windsor">Windsor EV</MenuItem>
                      <MenuItem value="Zs">ZS EV</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/*Registeration number */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Car Registration Number "
                    type="text"
                    variant="standard"
                    placeholder="e.g: OD39ZZ5697"
                    name="carRegisterationNumber"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.carRegisterationNumber}
                    onChange={(e) => {
                      this.setState({ carRegisterationNumber: e.target.value });
                    }}
                  />
                </Grid>

                {/* center */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <InputLabel
                      sx={{ "& .MuiInputLabel-asterisk": { color: "#d32f2f" } }}
                    >
                      Preferred Service Center Location
                    </InputLabel>
                    <Select
                      label="Preferred Service Center Location"
                      name="prefferedServiceCenterLocation"
                      value={this.state.prefferedServiceCenterLocation}
                      onChange={(e) => {
                        this.setState({
                          prefferedServiceCenterLocation: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="Bhubaneswar">Bhubaneswar</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* date of service */}
                <Grid item xs={12} sm={12}>
                  <TextField
                    margin="dense"
                    label="Preffered Date for Service"
                    name="date"
                    type="datetime-local"
                    fullWidth
                    value={preferredDateforService}
                    onChange={(e) => {
                      this.setState({
                        preferredDateforService: e.target.value,
                      });
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      color: "white",
                      backgroundColor: "#d32f2f",
                      fontFamily: "Gill Sans, sans-serif",
                    }}
                    size="large"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Container>
      </div>
    );
  }
}

export default Contact;
