import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function Edit(props) {
  //addinfo
  const [oldImages, setOldImages] = useState(
    Array.isArray(props.client.viewoldCarById?.image)
      ? props.client.viewoldCarById.image
      : []
  );
  const [newImages, setNewImages] = useState([]); // New images selected by the user

  const [brand, setBrand] = useState(props.client.viewoldCarById?.brand);
  const [carName, setCarName] = useState(props.client.viewoldCarById?.carName);
  const [fuelType, setFuelType] = useState(
    props.client.viewoldCarById?.fuelType
  );
  const [carType, setCarType] = useState(props.client.viewoldCarById?.carType);
  const [price, setPrice] = useState(props.client.viewoldCarById?.price);
  const [discountedPrice, setDiscountedPrice] = useState(
    props.client.viewoldCarById?.discountedPrice
  );
  const [kilometer, setKilometer] = useState(
    props.client.viewoldCarById?.kilometer
  );

  //overview
  const [registerationYear, setRegisterationYear] = useState(
    props.client.viewOverviewById?.registerationYear
  );
  const [manufacturingYear, setManufacturingYear] = useState(
    props.client.viewOverviewById.manufacturingYear
  );
  const [owners, setOwners] = useState(props.client.viewOverviewById.owners);
  const [transmission, setTransmission] = useState(
    props.client.viewOverviewById.transmission
  );
  const [color, setColor] = useState(props.client.viewOverviewById.color);
  const [insurance, setInsurance] = useState(
    props.client.viewOverviewById.insurance
  );
  const [registerationType, setregisterationType] = useState(
    props.client.viewOverviewById.registerationType
  );
  const [description, setDescription] = useState(
    props.client.viewOverviewById.description
  );

  //specification
  const [engine, setEngine] = useState(
    props.client.viewSpecificationById.engine
  );
  //   const [engineType, setEngineType] = useState("");
  const [maxPower, setMaxPower] = useState(
    props.client.viewSpecificationById.maxPower
  );
  const [maxTorque, setMaxTorque] = useState(
    props.client.viewSpecificationById.maxTorque
  );
  const [mileage, setMileage] = useState(
    props.client.viewSpecificationById.mileage
  );
  const [driveTrain, setDriveTrain] = useState(
    props.client.viewSpecificationById.driveTrain
  );
  //   const [turbocharger, setTurboCharger] = useState("");

  const [length, setLength] = useState(
    props.client.viewSpecificationById.length
  );
  const [width, setWidth] = useState(props.client.viewSpecificationById.width);
  const [height, setHeight] = useState(
    props.client.viewSpecificationById.height
  );
  const [wheelbase, setWheelbase] = useState(
    props.client.viewSpecificationById.wheelbase
  );
  const [groundClearance, setGroundClearance] = useState(
    props.client.viewSpecificationById.groundClearance
  );
  const [kerbWeight, setKerbWeight] = useState(
    props.client.viewSpecificationById.kerbWeight
  );

  const [doors, setDoors] = useState(props.client.viewSpecificationById.doors);
  const [seatingCapacity, setSeatingCapacity] = useState(
    props.client.viewSpecificationById.seatingCapacity
  );
  const [rows, setRows] = useState(props.client.viewSpecificationById.rows);
  const [bootspace, setBootspace] = useState(
    props.client.viewSpecificationById.bootspace
  );
  const [fuelTankCapacity, setFuelTankCapacity] = useState(
    props.client.viewSpecificationById.fuelTankCapacity
  );

  const [frontSuspension, setFrontSuspension] = useState(
    props.client.viewSpecificationById.frontSuspension
  );
  const [rearSuspension, setRearSuspension] = useState(
    props.client.viewSpecificationById.rearSuspension
  );
  const [frontbrakeType, setFrontBrakeType] = useState(
    props.client.viewSpecificationById.frontbrakeType
  );
  const [rearBrakeType, setRearBrakeType] = useState(
    props.client.viewSpecificationById.rearBrakeType
  );
  const [minTurningRadius, setMinTurningRadius] = useState(
    props.client.viewSpecificationById.minTurningRadius
  );
  const [steeringType, setSteeringType] = useState(
    props.client.viewSpecificationById.steeringType
  );
  const [wheels, setWheels] = useState(
    props.client.viewSpecificationById.wheels
  );
  const [spareWheel, setSpareWheel] = useState(
    props.client.viewSpecificationById.spareWheel
  );
  const [frontTyres, setFrontTyres] = useState(
    props.client.viewSpecificationById.frontTyres
  );
  const [rearTyres, setRearTyres] = useState(
    props.client.viewSpecificationById.rearTyres
  );

  //feature
  const [airbag, setAirbag] = useState(props.client.viewFetureById.airbag);
  const [tyrePresssure, setTyrePressure] = useState(
    props.client.viewFetureById.tyrePresssure
  );
  const [childSeatAnchorPoints, setchildSeatAnchorPoints] = useState(
    props.client.viewFetureById.childSeatAnchorPoints
  );
  const [seatBeltWarning, setSeatBeltWarning] = useState(
    props.client.viewFetureById.seatBeltWarning
  );
  // const [des1, setDes1] = useState("");

  const [abs, setAbs] = useState(props.client.viewFetureById.abs);
  const [ebd, setEbd] = useState(props.client.viewFetureById.ebd);
  const [ba, setBa] = useState(props.client.viewFetureById.ba);
  const [esp, setEsp] = useState(props.client.viewFetureById.esp);
  const [fourWheel, setFourWheelDrive] = useState(
    props.client.viewFetureById.fourWheel
  );
  const [hillHoldControl, setHillHoldControl] = useState(
    props.client.viewFetureById.hillHoldControl
  );
  const [tcs, setTcs] = useState(props.client.viewFetureById.tcs);
  // const [rideHeightAdjustment, setRideHeightAdjustment] = useState("");
  const [hillDescentControl, setHillDescentControl] = useState(
    props.client.viewFetureById.hillDescentControl
  );
  // const [des2, setDes2] = useState("");

  const [engineImmobiliser, setEngineImmobiliser] = useState(
    props.client.viewFetureById.engineImmobiliser
  );
  const [centeralLocking, setCenteralLocking] = useState(
    props.client.viewFetureById.centeralLocking
  );
  const [speedSensingDoorLock, setSpeedSensingDoorLock] = useState(
    props.client.viewFetureById.speedSensingDoorLock
  );
  const [childSafetyLock, setChildSafetyLock] = useState(
    props.client.viewFetureById.childSafetyLock
  );

  const [ac, setAc] = useState(props.client.viewFetureById.ac);
  const [frontac, setFrontAc] = useState(props.client.viewFetureById.frontac);
  const [rearac, setRearAc] = useState(props.client.viewFetureById.rearac);
  const [heater, setHeater] = useState(props.client.viewFetureById.heater);
  const [vanityMirror, setVanityMirror] = useState(
    props.client.viewFetureById.vanityMirror
  );
  const [antiglareMirror, setAntiGlareMirror] = useState(
    props.client.viewFetureById.antiglareMirror
  );
  const [parkingAssist, setparkingAssist] = useState(
    props.client.viewFetureById.parkingAssist
  );
  const [parkingSensor, setparkingSensor] = useState(
    props.client.viewFetureById.parkingSensor
  );
  const [cruisecontrol, setcruiseControl] = useState(
    props.client.viewFetureById.cruisecontrol
  );
  const [headlightIgnition, setHeadlightIgnition] = useState(
    props.client.viewFetureById.headlightIgnition
  );
  const [keylessstart, setKeylessStart] = useState(
    props.client.viewFetureById.keylessstart
  );
  const [steeringAdjustment, setSteeringAdjustment] = useState(
    props.client.viewFetureById.steeringAdjustment
  );
  const [power, setPower] = useState(props.client.viewFetureById.power);
  // const [des3, setDes3] = useState("");

  // const [des4, setDes4] = useState("");
  const [applecarplay, setApplecarplay] = useState(
    props.client.viewFetureById.applecarplay
  );
  const [androidauto, setAndroidauto] = useState(
    props.client.viewFetureById.androidauto
  );

  const [seatUpholestry, setSeatUpholestry] = useState(
    props.client.viewFetureById.seatUpholestry
  );
  const [drivesideAdjust, setdriverSideAdjust] = useState(
    props.client.viewFetureById.drivesideAdjust
  );
  const [leatherWrapped, setLeatherWrapped] = useState(
    props.client.viewFetureById.leatherWrapped
  );
  const [driverArmrest, setDriverArmrest] = useState(
    props.client.viewFetureById.driverArmrest
  );
  // const [rearPassengerSeatType, setRearPassengerSeatType] = useState("");
  // const [interiors, setInterior] = useState("");
  // const [interiorsColor, setInteriorColor] = useState("");
  const [foldingRearSeat, setFoldingRearSeat] = useState(
    props.client.viewFetureById.foldingRearSeat
  );
  const [splitRearSeat, setSplitRearSeat] = useState(
    props.client.viewFetureById.splitRearSeat
  );
  const [splitThirdRowSeat, setSplitThirdRowSeat] = useState(
    props.client.viewFetureById.splitThirdRowSeat
  );
  const [frontSeatbackPockets, setFrontSeatBackPockets] = useState(
    props.client.viewFetureById.frontSeatbackPockets
  );
  const [headrests, setHeadrests] = useState(
    props.client.viewFetureById.headrests
  );
  // const [des5, setDes5] = useState("");

  const [cupholders, setcupHolders] = useState(
    props.client.viewFetureById.cupholders
  );
  const [driverArmrestStorage, setDriverArmrestStorage] = useState(
    props.client.viewFetureById.driverArmrestStorage
  );
  const [cooledGloveBox, setCooledGloveBox] = useState(
    props.client.viewFetureById.cooledGloveBox
  );
  const [sunGlassHolder, setSunglassHolder] = useState(
    props.client.viewFetureById.sunGlassHolder
  );
  const [thirdRowCupHolder, setThirdRowCupHolder] = useState(
    props.client.viewFetureById.thirdRowCupHolder
  );
  // const [des6, setDes6] = useState("");

  const [orvm, setOrvm] = useState(props.client.viewFetureById.orvm);
  const [powerWindow, setPowerWindow] = useState(
    props.client.viewFetureById.powerWindow
  );
  const [oneTouchDown, setOneTouchDown] = useState(
    props.client.viewFetureById.oneTouchDown
  );
  const [oneTouchUp, setOneTouchUp] = useState(
    props.client.viewFetureById.oneTouchUp
  );
  const [adjustableOrvm, setAdjustableOrvm] = useState(
    props.client.viewFetureById.adjustableOrvm
  );
  const [exteriorDoorHandle, setExteriorDoorHandle] = useState(
    props.client.viewFetureById.exteriorDoorHandle
  );
  const [chromeexteriorDoorHandle, setChromeExteriorDoorHandle] = useState(
    props.client.viewFetureById.chromeexteriorDoorHandle
  );
  const [doorPockets, setDoorPockets] = useState(
    props.client.viewFetureById.doorPockets
  );
  const [bootlidOpener, setBootlidOpener] = useState(
    props.client.viewFetureById.bootlidOpener
  );
  // const [des7, setDes7] = useState("");

  const [sunroof, setsunRoof] = useState(props.client.viewFetureById.sunroof);
  const [roofMountedAntenna, setRoofMountedAntenna] = useState(
    props.client.viewFetureById.roofMountedAntenna
  );
  const [bodyColouredBumpers, setBodyColouredBumpers] = useState(
    props.client.viewFetureById.bodyColouredBumpers
  );
  const [chromeFinish, setChromeFinish] = useState(
    props.client.viewFetureById.chromeFinish
  );
  // const [des8, setDes8] = useState("");

  const [projectorheadlight, setProjectorHeadlight] = useState(
    props.client.viewFetureById.projectorheadlight
  );
  const [autoheadlamps, setAutoheadlamps] = useState(
    props.client.viewFetureById.autoheadlamps
  );
  const [homeheadlamps, setHomeheadlamps] = useState(
    props.client.viewFetureById.homeheadlamps
  );
  const [drl, setDrl] = useState(props.client.viewFetureById.drl);
  const [tailights, setTailLights] = useState(
    props.client.viewFetureById.tailights
  );
  const [cabinLamps, setCabinlamps] = useState(
    props.client.viewFetureById.cabinLamps
  );
  const [lightonVanityMirror, setLightonVanityMirror] = useState(
    props.client.viewFetureById.lightonVanityMirror
  );
  const [rearreadinglamp, setRearreadinglamp] = useState(
    props.client.viewFetureById.rearreadinglamp
  );
  const [gloveBoxlamp, setGloveboxlamp] = useState(
    props.client.viewFetureById.gloveBoxlamp
  );
  const [headlightHeightAdjuster, setHeadlightHEightAdjuster] = useState(
    props.client.viewFetureById.headlightHeightAdjuster
  );
  // const [des9, setDes9] = useState("");

  // const [instantaneousConsumption, setInstantaneousConsumption] = useState("");
  // const [instrumentCluster, setInstrumentCluster] = useState("");
  const [tripMeter, setTripMeter] = useState(
    props.client.viewFetureById.tripMeter
  );
  const [averageFuelConsumption, setAverageFuelConsumption] = useState(
    props.client.viewFetureById.averageFuelConsumption
  );
  // const [averageSpeed, setAverageSpeed] = useState("");
  const [distancetoEmpty, setDistancetoEmpty] = useState(
    props.client.viewFetureById.distancetoEmpty
  );
  const [clock, setClock] = useState(props.client.viewFetureById.clock);
  const [lowFuelWarning, setLowFuelWarning] = useState(
    props.client.viewFetureById.lowFuelWarning
  );
  const [doorAjarWarning, setDooeAjarWarning] = useState(
    props.client.viewFetureById.doorAjarWarning
  );
  const [adjustableCluster, setAdjustableCluster] = useState(
    props.client.viewFetureById.adjustableCluster
  );
  const [gearIndicator, setGearIndicator] = useState(
    props.client.viewFetureById.gearIndicator
  );
  const [shiftIndicator, setShiftIndicator] = useState(
    props.client.viewFetureById.shiftIndicator
  );
  const [tachometer, setTachometer] = useState(
    props.client.viewFetureById.tachometer
  );
  // const [des10, setdes10] = useState("");

  const [display, setDisplay] = useState(props.client.viewFetureById?.display);
  const [musicSystem, setMusicSystem] = useState(
    props.client.viewFetureById.musicSystem
  );
  const [speaker, setSpeaker] = useState(props.client.viewFetureById.speaker);
  const [steeringmountedctrls, setSteeringmountedctrls] = useState(
    props.client.viewFetureById.steeringmountedctrls
  );
  const [voiceCommand, setVoiceCommand] = useState(
    props.client.viewFetureById.voiceCommand
  );
  const [gps, setGps] = useState(props.client.viewFetureById.gps);
  const [bluetooth, setBluetooth] = useState(
    props.client.viewFetureById.bluetooth
  );
  const [aux, setAux] = useState(props.client.viewFetureById.aux);
  const [radio, setRadio] = useState(props.client.viewFetureById.radio);
  const [headUnitSize, setHeadUnitSize] = useState(
    props.client.viewFetureById.headUnitSize
  );
  const [usb, setUsb] = useState(props.client.viewFetureById.usb);
  const { id } = useParams();
  // const [iPod, setIpod] = useState(false);
  // const [des11, setDes11] = useState("");

  // const [warrrantyyear, setWarranty] = useState(false);
  // const [warrrantykm, setWarrantykm] = useState(false);
  const [des12, setdes12] = useState(props.client.viewFetureById.des12);

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setOldImages([]); // Clear old images when new images are selected
    setNewImages(selectedImages); // Set new images
  };
  const handleSubmit1 = (e) => {
    e.preventDefault();
    const imagesData = newImages.map((img, index) => ({
      [`image${index + 1}`]: img,
    }));

    props.editCarinfo(
      props.login.token,
      props.login.user_id,
      props.client.viewoldCarById._id,
      brand,
      carName,
      fuelType,
      carType,
      price,
      discountedPrice,
      kilometer,
      imagesData,
      oldImages
    );
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    // console.log(props.client.oldCarId);
    props.editCarOverview(
      props.login.token,
      props.login.user_id,
      props.client.viewOverviewById._id,
      registerationYear,
      manufacturingYear,
      owners,
      transmission,
      color,
      insurance,
      registerationType,
      description
    );
  };

  const handleSubmit3 = (e) => {
    e.preventDefault();
    props.editCarSpecification(
      props.login.token,
      props.login.user_id,
      props.client.viewSpecificationById._id,
      engine,
      // engineType,
      maxPower,
      maxTorque,
      mileage,
      driveTrain,
      // turbocharger,
      length,
      width,
      height,
      wheelbase,
      groundClearance,
      kerbWeight,
      doors,
      seatingCapacity,
      rows,
      bootspace,
      fuelTankCapacity,
      frontSuspension,
      rearSuspension,
      frontbrakeType,
      rearBrakeType,
      minTurningRadius,
      steeringType,
      wheels,
      spareWheel,
      frontTyres,
      rearTyres
    );
  };

  const handleSubmit4 = (e) => {
    e.preventDefault();
    // console.log(props.client.oldCarId,"features/register" );
    props.editCarFeature(
      props.login.token,
      props.login.user_id,
      props.client.viewFetureById._id,
      airbag,
      tyrePresssure,
      childSeatAnchorPoints,
      seatBeltWarning,
      // des1,
      abs,
      ebd,
      ba,
      esp,
      fourWheel,
      hillHoldControl,
      tcs,
      // rideHeightAdjustment,
      hillDescentControl,
      // des2,
      engineImmobiliser,
      centeralLocking,
      speedSensingDoorLock,
      childSafetyLock,
      ac,
      frontac,
      rearac,
      heater,
      vanityMirror,
      antiglareMirror,
      parkingAssist,
      parkingSensor,
      cruisecontrol,
      headlightIgnition,
      keylessstart,
      steeringAdjustment,
      power,
      // des3,
      applecarplay,
      androidauto,
      // des4,
      seatUpholestry,
      drivesideAdjust,
      leatherWrapped,
      driverArmrest,
      // rearPassengerSeatType,
      // interiors,
      // interiorsColor,
      foldingRearSeat,
      splitRearSeat,
      splitThirdRowSeat,
      frontSeatbackPockets,
      headrests,
      // des5,
      cupholders,
      driverArmrestStorage,
      cooledGloveBox,
      sunGlassHolder,
      thirdRowCupHolder,
      // des6,
      orvm,
      powerWindow,
      oneTouchDown,
      oneTouchUp,
      adjustableOrvm,
      exteriorDoorHandle,
      chromeexteriorDoorHandle,
      doorPockets,
      bootlidOpener,
      // des7,
      sunroof,
      roofMountedAntenna,
      bodyColouredBumpers,
      chromeFinish,
      // des8,
      projectorheadlight,
      autoheadlamps,
      homeheadlamps,
      drl,
      tailights,
      cabinLamps,
      lightonVanityMirror,
      rearreadinglamp,
      gloveBoxlamp,
      headlightHeightAdjuster,
      // des9,
      // instantaneousConsumption,
      // instrumentCluster,
      tripMeter,
      averageFuelConsumption,
      // averageSpeed,
      distancetoEmpty,
      clock,
      lowFuelWarning,
      doorAjarWarning,
      adjustableCluster,
      gearIndicator,
      shiftIndicator,
      tachometer,
      // des10,
      // display,
      musicSystem,
      speaker,
      steeringmountedctrls,
      voiceCommand,
      gps,
      bluetooth,
      aux,
      radio,
      usb,
      headUnitSize,
      // iPod,
      // des11,
      // warrrantyyear,
      // warrrantykm,
      des12
    );
  };

  useEffect(() => {
    if (id) {
      props.viewOldCarById(props.login.token, props.login.user_id, id);
      props.viewOverviewById(props.login.token, props.login.user_id, id);
      props.viewSpecificationById(props.login.token, props.login.user_id, id);
      props.viewFeturesById(props.login.token, props.login.user_id, id);
    }
    // console.log("20pxccccccccccccc", props.client.viewFetureById);
  }, [props.login.token, props.login.user_id, id]);
  return (
    <>
      {/* addinfo */}
      <Box sx={{ padding: "20px" }}>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ marginTop: "70px" }}
        >
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#cf2230",
                      fontFamily:
                        "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                      fontSize: { md: "22px", sm: "20px", xs: "17px" },
                    }}
                  >
                    BASIC INFORMATION
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit1}>
                  <Box>
                    {/* Upload new images */}
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ marginTop: 1, width: "90%" }}
                    >
                      Click to Upload Images
                      <input
                        type="file"
                        hidden
                        multiple
                        onChange={handleImageChange}
                        required
                      />
                    </Button>

                    {/* Preview images */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: 1,
                        marginBottom: 2,
                      }}
                    >
                      {(newImages.length > 0 ? newImages : oldImages).map(
                        (img, index) => (
                          <Box key={index} sx={{ margin: 1 }}>
                            <img
                              src={
                                img instanceof File
                                  ? URL.createObjectURL(img)
                                  : img
                              }
                              alt={`Image ${index + 1}`}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        required
                      >
                        <InputLabel id="fuel-type-label">Brand Name</InputLabel>
                        <Select
                          labelId="brand-name-label"
                          label="Brand Name"
                          name="brand"
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                          required
                        >
                          <MenuItem value="Maruti">Maruti</MenuItem>
                          <MenuItem value="Hyundai">Hyundai</MenuItem>
                          <MenuItem value="Tata">Tata</MenuItem>
                          <MenuItem value="Honda">Honda</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Car Name"
                        variant="outlined"
                        fullWidth
                        name="carName"
                        value={carName}
                        onChange={(e) => setCarName(e.target.value)}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        required
                      >
                        <InputLabel id="fuel-type-label">Fuel Type</InputLabel>
                        <Select
                          labelId="fuel-type-label"
                          label="Fuel Type"
                          name="fuelType"
                          value={fuelType}
                          onChange={(e) => setFuelType(e.target.value)}
                          required
                        >
                          <MenuItem value="Petrol">Petrol</MenuItem>
                          <MenuItem value="Diesel">Diesel</MenuItem>
                          <MenuItem value="CNG">CNG</MenuItem>
                          <MenuItem value="LPG">LPG</MenuItem>
                          <MenuItem value="Electric">Electric</MenuItem>
                          <MenuItem value="Hybrid">Hybrid</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        required
                      >
                        <InputLabel id="car-type-label">Car Type</InputLabel>
                        <Select
                          labelId="car-type-label"
                          label="Car Type"
                          name="carType"
                          value={carType}
                          onChange={(e) => setCarType(e.target.value)}
                          required
                        >
                          <MenuItem value="SUV">SUV</MenuItem>
                          <MenuItem value="Sedan">Sedan</MenuItem>
                          <MenuItem value="Hatchback">Hatchback</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Price"
                        variant="outlined"
                        fullWidth
                        name="price"
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Discounted Price"
                        variant="outlined"
                        fullWidth
                        name="discountedPrice"
                        value={discountedPrice}
                        onChange={(e) => setDiscountedPrice(e.target.value)}
                        type="number"
                        required
                        sx={{ marginBottom: 2 }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField
                        label="Kilometer"
                        variant="outlined"
                        fullWidth
                        name="kilometer"
                        value={kilometer}
                        onChange={(e) => setKilometer(e.target.value)}
                        type="number"
                        required
                        sx={{ marginBottom: 2 }}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#E03012",
                        fontWeight: "bold",
                        width: "150px",
                        "&:hover": {
                          backgroundColor: "#E03012",
                        },
                      }}
                      onClick={handleSubmit1}
                    >
                      UPDATE
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* overview */}
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ marginTop: "71px", marginBottom: "20px" }}
        >
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#cf2230",
                      fontFamily:
                        "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                      fontSize: { md: "22px", sm: "20px", xs: "17px" },
                    }}
                  >
                    OVERVIEW
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="registration-year-label">
                          Registration Year
                        </InputLabel>
                        <Select
                          labelId="registration-year-label"
                          label="Registration Year"
                          name="registeryear"
                          required
                          value={registerationYear}
                          onChange={(e) => setRegisterationYear(e.target.value)}
                        >
                          {[...Array(15)].map((_, i) => {
                            const year = 2024 - i;
                            return (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel>Manufacturing Year</InputLabel>
                        <Select
                          label="Manufacturing Year"
                          name="manufactureyear"
                          required
                          value={manufacturingYear}
                          onChange={(e) => setManufacturingYear(e.target.value)}
                        >
                          {[...Array(15)].map((_, i) => {
                            const year = 2023 - i;
                            return (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel>No Of Owners</InputLabel>
                        <Select
                          label="No Of Owners"
                          name="owner"
                          required
                          value={owners}
                          onChange={(e) => setOwners(e.target.value)}
                        >
                          <MenuItem value="First Owner">First Owner</MenuItem>
                          <MenuItem value="Second Owner">Second Owner</MenuItem>
                          <MenuItem value="Third or More">
                            Third or More
                          </MenuItem>
                          <MenuItem value="Unregistered Car">
                            Unregistered Car
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel>Transmission</InputLabel>
                        <Select
                          label="Transmission"
                          name="transmission"
                          required
                          value={transmission}
                          onChange={(e) => setTransmission(e.target.value)}
                        >
                          <MenuItem value="Automatic">Automatic</MenuItem>
                          <MenuItem value="Manual">Manual</MenuItem>
                          <MenuItem value="Clutchless Manual">
                            Clutchless Manual
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Color"
                        variant="outlined"
                        fullWidth
                        name="color"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Insurance"
                        variant="outlined"
                        fullWidth
                        name="insurance"
                        value={insurance}
                        onChange={(e) => setInsurance(e.target.value)}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel>Registration Type </InputLabel>
                        <Select
                          label="Registration Type"
                          name="registerationType"
                          required
                          value={registerationType}
                          onChange={(e) => setregisterationType(e.target.value)}
                        >
                          <MenuItem value="private">Private</MenuItem>
                          <MenuItem value="commercial">Commercial</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          marginBottom: 2,
                          fontWeight: "bold",
                          color: "#cf2230",
                          fontFamily:
                            "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                          fontSize: { md: "22px", sm: "20px", xs: "17px" },
                        }}
                      >
                        SELLER'S COMMENT
                      </Typography>
                    </Box>
                    <Grid item xs={12}>
                      <TextField
                        label="Description"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          backgroundColor: "#E03012",
                          fontWeight: "bold",
                          width: "150px",
                          "&:hover": {
                            backgroundColor: "#E03012",
                          },
                        }}
                        onClick={handleSubmit2}
                      >
                        UPDATE
                      </Button>
                    </Box>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* specification */}
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ marginTop: "71px", marginBottom: "20px" }}
        >
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#cf2230",
                      fontFamily:
                        "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                      fontSize: { md: "22px", sm: "20px", xs: "17px" },
                    }}
                  >
                    SPECIFICATIONS
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit3}>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      marginBottom: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Engine & Transmission
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Engine(in cc)"
                        variant="outlined"
                        fullWidth
                        value={engine}
                        onChange={(e) => setEngine(e.target.value)}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                                <TextField label="Engine Type" variant="outlined" fullWidth value={engineType} onChange={(e) => setEngineType(e.target.value)} />
                                </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Max Power (bhp@rpm)"
                        variant="outlined"
                        fullWidth
                        value={maxPower}
                        onChange={(e) => setMaxPower(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Max Torque (Nm@rpm)"
                        variant="outlined"
                        fullWidth
                        value={maxTorque}
                        onChange={(e) => setMaxTorque(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Mileage (ARAI)"
                        variant="outlined"
                        fullWidth
                        value={mileage}
                        onChange={(e) => setMileage(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Drive Train</InputLabel>
                        <Select
                          label="driveTrain"
                          name="driveTrain"
                          value={driveTrain}
                          onChange={(e) => setDriveTrain(e.target.value)}
                        >
                          <MenuItem value="fwd">FWD</MenuItem>
                          <MenuItem value="rwd">RWD</MenuItem>
                          <MenuItem value="awd">AWD</MenuItem>
                          <MenuItem value="4">4X4</MenuItem>
                          <MenuItem value="2">2WD</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                                <TextField label="Turbocharger / Supercharger" variant="outlined" fullWidth value={turbocharger} onChange={(e) => setTurboCharger(e.target.value)} />
                                </Grid> */}
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      marginBottom: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Dimensions & Weight
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Length"
                        variant="outlined"
                        fullWidth
                        value={length}
                        onChange={(e) => setLength(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Width"
                        variant="outlined"
                        fullWidth
                        value={width}
                        onChange={(e) => setWidth(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Height"
                        variant="outlined"
                        fullWidth
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Wheelbase"
                        variant="outlined"
                        fullWidth
                        value={wheelbase}
                        onChange={(e) => setWheelbase(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Ground Clearance"
                        variant="outlined"
                        fullWidth
                        value={groundClearance}
                        onChange={(e) => setGroundClearance(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Kerb Weight"
                        variant="outlined"
                        fullWidth
                        value={kerbWeight}
                        onChange={(e) => setKerbWeight(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      marginBottom: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Capacity
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Doors"
                        variant="outlined"
                        fullWidth
                        value={doors}
                        onChange={(e) => setDoors(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Seating Capacity"
                        variant="outlined"
                        fullWidth
                        value={seatingCapacity}
                        onChange={(e) => setSeatingCapacity(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="No of Rows"
                        variant="outlined"
                        fullWidth
                        value={rows}
                        onChange={(e) => setRows(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Bootspace"
                        variant="outlined"
                        fullWidth
                        value={bootspace}
                        onChange={(e) => setBootspace(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Fuel Tank Capacity"
                        variant="outlined"
                        fullWidth
                        value={fuelTankCapacity}
                        onChange={(e) => setFuelTankCapacity(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      marginBottom: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Suspensions, Brakes, Steering & Tyres
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Front Suspension"
                        variant="outlined"
                        fullWidth
                        value={frontSuspension}
                        onChange={(e) => setFrontSuspension(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Rear Suspension"
                        variant="outlined"
                        fullWidth
                        value={rearSuspension}
                        onChange={(e) => setRearSuspension(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Front Brake Type"
                        variant="outlined"
                        fullWidth
                        value={frontbrakeType}
                        onChange={(e) => setFrontBrakeType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Rear Brake Type"
                        variant="outlined"
                        fullWidth
                        value={rearBrakeType}
                        onChange={(e) => setRearBrakeType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Min Turning Radius"
                        variant="outlined"
                        fullWidth
                        value={minTurningRadius}
                        onChange={(e) => setMinTurningRadius(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Steering Type"
                        variant="outlined"
                        fullWidth
                        value={steeringType}
                        onChange={(e) => setSteeringType(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Wheels"
                        variant="outlined"
                        fullWidth
                        value={wheels}
                        onChange={(e) => setWheels(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Spare Wheel"
                        variant="outlined"
                        fullWidth
                        value={spareWheel}
                        onChange={(e) => setSpareWheel(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Front Tyres"
                        variant="outlined"
                        fullWidth
                        value={frontTyres}
                        onChange={(e) => setFrontTyres(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Rear Tyres"
                        variant="outlined"
                        fullWidth
                        value={rearTyres}
                        onChange={(e) => setRearTyres(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#E03012",
                        fontWeight: "bold",
                        width: "150px",
                        "&:hover": {
                          backgroundColor: "#E03012",
                        },
                      }}
                      onClick={handleSubmit3}
                    >
                      UPDATE
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* feature */}
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ marginTop: "71px", marginBottom: "20px" }}
        >
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#cf2230",
                      fontFamily:
                        "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                      fontSize: { md: "22px", sm: "20px", xs: "17px" },
                    }}
                  >
                    FEATURES
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit4}>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Safety
                  </Typography>
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Airbags</InputLabel>
                      <Select
                        label="airbag"
                        name="airbag"
                        value={airbag}
                        onChange={(e) => setAirbag(e.target.value)}
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6">6</MenuItem>
                        <MenuItem value="7">7</MenuItem>
                        <MenuItem value="8">8</MenuItem>
                        <MenuItem value="9">9</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="tyrePressure"
                          color="primary"
                          checked={tyrePresssure} // Assuming tyrePressure is a boolean state
                          onChange={(e) => setTyrePressure(e.target.checked)} // Update state based on checked status
                        />
                      }
                      label="Tyre Pressure Monitoring System (TPMS)"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="childSeatAnchorPoints"
                          color="primary"
                          checked={childSeatAnchorPoints}
                          onChange={(e) =>
                            setchildSeatAnchorPoints(e.target.checked)
                          }
                        />
                      }
                      label="Child Seat Anchor Points"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="seatBeltWarning"
                          color="primary"
                          checked={seatBeltWarning}
                          onChange={(e) => setSeatBeltWarning(e.target.checked)}
                        />
                      }
                      label="Seat Belt Warning"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Braking & Traction
                  </Typography>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Anti-Lock Braking System (ABS)"
                            color="primary"
                            checked={abs}
                            onChange={(e) => setAbs(e.target.checked)}
                          />
                        }
                        label="Anti-Lock Braking System (ABS)"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Electronic Brake-force Distribution (EBD)"
                            color="primary"
                            checked={ebd}
                            onChange={(e) => setEbd(e.target.checked)}
                          />
                        }
                        label="Electronic Brake-force Distribution (EBD)"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Brake Assist (BA)"
                            color="primary"
                            checked={ba}
                            onChange={(e) => setBa(e.target.checked)}
                          />
                        }
                        label="Brake Assist (BA)"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Electronic Stability Program (ESP)"
                            color="primary"
                            checked={esp}
                            onChange={(e) => setEsp(e.target.checked)}
                          />
                        }
                        label="Electronic Stability Program (ESP)"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Four-Wheel-Drive"
                            color="primary"
                            checked={fourWheel}
                            onChange={(e) =>
                              setFourWheelDrive(e.target.checked)
                            }
                          />
                        }
                        label="Four-Wheel-Drive"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Hill Hold Control"
                            color="primary"
                            checked={hillHoldControl}
                            onChange={(e) =>
                              setHillHoldControl(e.target.checked)
                            }
                          />
                        }
                        label="Hill Hold Control"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Traction Control System (TC/TCS)"
                            color="primary"
                            checked={tcs}
                            onChange={(e) => setTcs(e.target.checked)}
                          />
                        }
                        label="Traction Control System (TC/TCS)"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Hill Descent Control"
                            color="primary"
                            checked={hillDescentControl}
                            onChange={(e) =>
                              setHillDescentControl(e.target.checked)
                            }
                          />
                        }
                        label="Hill Descent Control"
                      />
                    </Grid>
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Locks & Security
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="engineImmobiliser"
                          color="primary"
                          checked={engineImmobiliser}
                          onChange={(e) =>
                            setEngineImmobiliser(e.target.checked)
                          }
                        />
                      }
                      label="Engine Immobiliser"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="centralLocking"
                          color="primary"
                          checked={centeralLocking}
                          onChange={(e) => setCenteralLocking(e.target.checked)}
                        />
                      }
                      label="Central Locking"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="speedSensingDoorLock"
                          color="primary"
                          checked={speedSensingDoorLock}
                          onChange={(e) =>
                            setSpeedSensingDoorLock(e.target.checked)
                          }
                        />
                      }
                      label="Speed Sensing Door Lock"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="childSafetyLock"
                          color="primary"
                          checked={childSafetyLock}
                          onChange={(e) => setChildSafetyLock(e.target.checked)}
                        />
                      }
                      label="Child Safety Lock"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Comfort & Convenience
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="ac"
                          color="primary"
                          checked={ac}
                          onChange={(e) => setAc(e.target.checked)}
                        />
                      }
                      label="Air Conditioner"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="frontac"
                          color="primary"
                          checked={frontac}
                          onChange={(e) => setFrontAc(e.target.checked)}
                        />
                      }
                      label="Front AC"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="rearac"
                          color="primary"
                          checked={rearac}
                          onChange={(e) => setRearAc(e.target.checked)}
                        />
                      }
                      label="Rear AC"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="heater"
                          color="primary"
                          checked={heater}
                          onChange={(e) => setHeater(e.target.checked)}
                        />
                      }
                      label="Heater"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Vanity Mirrors on Sun Visors"
                          color="primary"
                          checked={vanityMirror}
                          onChange={(e) => setVanityMirror(e.target.checked)}
                        />
                      }
                      label="Vanity Mirrors on Sun Visors"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Anti-glare Mirrors"
                          color="primary"
                          checked={antiglareMirror}
                          onChange={(e) => setAntiGlareMirror(e.target.checked)}
                        />
                      }
                      label="Anti-glare Mirrors"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="parkingAssist"
                          color="primary"
                          checked={parkingAssist}
                          onChange={(e) => setparkingAssist(e.target.checked)}
                        />
                      }
                      label="Parking Assist"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Parking Sensors"
                          color="primary"
                          checked={parkingSensor}
                          onChange={(e) => setparkingSensor(e.target.checked)}
                        />
                      }
                      label="Parking Sensors"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Cruise Control"
                          color="primary"
                          checked={cruisecontrol}
                          onChange={(e) => setcruiseControl(e.target.checked)}
                        />
                      }
                      label="Cruise Control"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Headlight and Ignition On Reminder"
                          color="primary"
                          checked={headlightIgnition}
                          onChange={(e) =>
                            setHeadlightIgnition(e.target.checked)
                          }
                        />
                      }
                      label="Headlight and Ignition On Reminder"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Keyless Start/ Button Start"
                          color="primary"
                          checked={keylessstart}
                          onChange={(e) => setKeylessStart(e.target.checked)}
                        />
                      }
                      label="Keyless Start/ Button Start"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="SteeringHeightAdjustment"
                          color="primary"
                          checked={steeringAdjustment}
                          onChange={(e) =>
                            setSteeringAdjustment(e.target.checked)
                          }
                        />
                      }
                      label="Steering Height Adjustment"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="12V Power Outlets"
                          color="primary"
                          checked={power}
                          onChange={(e) => setPower(e.target.checked)}
                        />
                      }
                      label="12V Power Outlets"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Mobile App Features
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="applecarplay"
                          color="primary"
                          checked={applecarplay}
                          onChange={(e) => setApplecarplay(e.target.checked)}
                        />
                      }
                      label="Apple Carplay"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="androidauto"
                          color="primary"
                          checked={androidauto}
                          onChange={(e) => setAndroidauto(e.target.checked)}
                        />
                      }
                      label="Android Auto"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Seats & Upholstery
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Seat Upholstery"
                          color="primary"
                          checked={seatUpholestry}
                          onChange={(e) => setSeatUpholestry(e.target.checked)}
                        />
                      }
                      label="Seat Upholstery"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="driverSide"
                          color="primary"
                          checked={drivesideAdjust}
                          onChange={(e) =>
                            setdriverSideAdjust(e.target.checked)
                          }
                        />
                      }
                      label="Driver Side Seat Adjustment"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Leather-wrapped Steering Wheel"
                          color="primary"
                          checked={leatherWrapped}
                          onChange={(e) => setLeatherWrapped(e.target.checked)}
                        />
                      }
                      label="Leather-wrapped Steering Wheel"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Driver Armrest"
                          color="primary"
                          checked={driverArmrest}
                          onChange={(e) => setDriverArmrest(e.target.checked)}
                        />
                      }
                      label="Driver Armrest"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Folding Rear Seat"
                          color="primary"
                          checked={foldingRearSeat}
                          onChange={(e) => setFoldingRearSeat(e.target.checked)}
                        />
                      }
                      label="Folding Rear Seat"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="splitRearSeat"
                          color="primary"
                          checked={splitRearSeat}
                          onChange={(e) => setSplitRearSeat(e.target.checked)}
                        />
                      }
                      label="Split Rear Seat"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Split Third Row Seat"
                          color="primary"
                          checked={splitThirdRowSeat}
                          onChange={(e) =>
                            setSplitThirdRowSeat(e.target.checked)
                          }
                        />
                      }
                      label="Split Third Row Seat"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Front Seatback Pockets"
                          color="primary"
                          checked={frontSeatbackPockets}
                          onChange={(e) =>
                            setFrontSeatBackPockets(e.target.checked)
                          }
                        />
                      }
                      label="Front Seatback Pockets"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Headrests"
                          color="primary"
                          checked={headrests}
                          onChange={(e) => setHeadrests(e.target.checked)}
                        />
                      }
                      label="Headrests"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Storage
                  </Typography>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="cupholders"
                          color="primary"
                          checked={cupholders}
                          onChange={(e) => setcupHolders(e.target.checked)}
                        />
                      }
                      label="Cup Holders"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Driver Armrest Storage"
                          color="primary"
                          checked={driverArmrestStorage}
                          onChange={(e) =>
                            setDriverArmrestStorage(e.target.checked)
                          }
                        />
                      }
                      label="Driver Armrest Storage"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Cooled Glovebox"
                          color="primary"
                          checked={cooledGloveBox}
                          onChange={(e) => setCooledGloveBox(e.target.checked)}
                        />
                      }
                      label="Cooled Glovebox"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Sunglass Holder"
                          color="primary"
                          checked={sunGlassHolder}
                          onChange={(e) => setSunglassHolder(e.target.checked)}
                        />
                      }
                      label="Sunglass Holder"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Third Row Cup Holders"
                          color="primary"
                          checked={thirdRowCupHolder}
                          onChange={(e) =>
                            setThirdRowCupHolder(e.target.checked)
                          }
                        />
                      }
                      label="Third Row Cup Holders"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Doors, Windows, Mirrors & Wipers
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="ORVM Colour"
                          color="primary"
                          checked={orvm}
                          onChange={(e) => setOrvm(e.target.checked)}
                        />
                      }
                      label="Body Color ORVM"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Power Windows"
                          color="primary"
                          checked={powerWindow}
                          onChange={(e) => setPowerWindow(e.target.checked)}
                        />
                      }
                      label="Power Windows"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="One Touch Down"
                          color="primary"
                          checked={oneTouchDown}
                          onChange={(e) => setOneTouchDown(e.target.checked)}
                        />
                      }
                      label="One Touch Down"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="One Touch Up"
                          color="primary"
                          checked={oneTouchUp}
                          onChange={(e) => setOneTouchUp(e.target.checked)}
                        />
                      }
                      label="One Touch Up"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Adjustable ORVMs"
                          color="primary"
                          checked={adjustableOrvm}
                          onChange={(e) => setAdjustableOrvm(e.target.checked)}
                        />
                      }
                      label="Electric Adjustable ORVMs"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Body color Exterior Door Handles"
                          color="primary"
                          checked={exteriorDoorHandle}
                          onChange={(e) =>
                            setExteriorDoorHandle(e.target.checked)
                          }
                        />
                      }
                      label="Body Color Exterior Door Handles"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="chromeExterior Door Handles"
                          color="primary"
                          checked={chromeexteriorDoorHandle}
                          onChange={(e) =>
                            setChromeExteriorDoorHandle(e.target.checked)
                          }
                        />
                      }
                      label="Chrome Exterior Door Handles"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Door Pockets"
                          color="primary"
                          checked={doorPockets}
                          onChange={(e) => setDoorPockets(e.target.checked)}
                        />
                      }
                      label="Door Pockets"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Bootlid Opener"
                          color="primary"
                          checked={bootlidOpener}
                          onChange={(e) => setBootlidOpener(e.target.checked)}
                        />
                      }
                      label="Bootlid Opener"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Exterior
                  </Typography>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Sunroof / Moonroof"
                          color="primary"
                          checked={sunroof}
                          onChange={(e) => setsunRoof(e.target.checked)}
                        />
                      }
                      label="Sunroof / Moonroof"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Roof-mounted Antenna"
                          color="primary"
                          checked={roofMountedAntenna}
                          onChange={(e) =>
                            setRoofMountedAntenna(e.target.checked)
                          }
                        />
                      }
                      label="Roof-mounted Antenna"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Body-coloured Bumpers"
                          color="primary"
                          checked={bodyColouredBumpers}
                          onChange={(e) =>
                            setBodyColouredBumpers(e.target.checked)
                          }
                        />
                      }
                      label="Body-coloured Bumpers"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Chrome Finish Exhaust Pipe"
                          color="primary"
                          checked={chromeFinish}
                          onChange={(e) => setChromeFinish(e.target.checked)}
                        />
                      }
                      label="Chrome Finish Exhaust Pipe"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Lighting
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="projectorHeadlights"
                          color="primary"
                          checked={projectorheadlight}
                          onChange={(e) =>
                            setProjectorHeadlight(e.target.checked)
                          }
                        />
                      }
                      label="Projector Headlights"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Automatic Headlamps"
                          color="primary"
                          checked={autoheadlamps}
                          onChange={(e) => setAutoheadlamps(e.target.checked)}
                        />
                      }
                      label="Automatic Headlamps"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Follow Me Home Headlamps"
                          color="primary"
                          checked={homeheadlamps}
                          onChange={(e) => setHomeheadlamps(e.target.checked)}
                        />
                      }
                      label="Follow Me Home Headlamps"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="drl"
                          color="primary"
                          checked={drl}
                          onChange={(e) => setDrl(e.target.checked)}
                        />
                      }
                      label="DRL"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Taillights"
                          color="primary"
                          checked={tailights}
                          onChange={(e) => setTailLights(e.target.checked)}
                        />
                      }
                      label="LED Taillights"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="cabinLamps"
                          color="primary"
                          checked={cabinLamps}
                          onChange={(e) => setCabinlamps(e.target.checked)}
                        />
                      }
                      label="Cabin Lamps"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Light on Vanity Mirrors"
                          color="primary"
                          checked={lightonVanityMirror}
                          onChange={(e) =>
                            setLightonVanityMirror(e.target.checked)
                          }
                        />
                      }
                      label="Light on Vanity Mirrors"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Rear Reading Lamp"
                          color="primary"
                          checked={rearreadinglamp}
                          onChange={(e) => setRearreadinglamp(e.target.checked)}
                        />
                      }
                      label="Rear Reading Lamp"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Glovebox Lamp"
                          color="primary"
                          checked={gloveBoxlamp}
                          onChange={(e) => setGloveboxlamp(e.target.checked)}
                        />
                      }
                      label="Glovebox Lamp"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Headlight Height Adjuster"
                          color="primary"
                          checked={headlightHeightAdjuster}
                          onChange={(e) =>
                            setHeadlightHEightAdjuster(e.target.checked)
                          }
                        />
                      }
                      label="Headlight Height Adjuster"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Instrumentation
                  </Typography>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Trip Meter"
                          color="primary"
                          checked={tripMeter}
                          onChange={(e) => setTripMeter(e.target.checked)}
                        />
                      }
                      label="Trip Meter"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Average Fuel Consumption"
                          color="primary"
                          checked={averageFuelConsumption}
                          onChange={(e) =>
                            setAverageFuelConsumption(e.target.checked)
                          }
                        />
                      }
                      label="Average Fuel Consumption"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Distance to Empty"
                          color="primary"
                          checked={distancetoEmpty}
                          onChange={(e) => setDistancetoEmpty(e.target.checked)}
                        />
                      }
                      label="Distance to Empty"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="clock"
                          color="primary"
                          checked={clock}
                          onChange={(e) => setClock(e.target.checked)}
                        />
                      }
                      label="Clock"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Low Fuel Level Warning"
                          color="primary"
                          checked={lowFuelWarning}
                          onChange={(e) => setLowFuelWarning(e.target.checked)}
                        />
                      }
                      label="Low Fuel Level Warning"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Door Ajar Warning"
                          color="primary"
                          checked={doorAjarWarning}
                          onChange={(e) => setDooeAjarWarning(e.target.checked)}
                        />
                      }
                      label="Door Ajar Warning"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Adjustable Cluster Brightness"
                          color="primary"
                          checked={adjustableCluster}
                          onChange={(e) =>
                            setAdjustableCluster(e.target.checked)
                          }
                        />
                      }
                      label="Adjustable Cluster Brightness"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Gear Indicator"
                          color="primary"
                          checked={gearIndicator}
                          onChange={(e) => setGearIndicator(e.target.checked)}
                        />
                      }
                      label="Gear Indicator"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Shift Indicator"
                          color="primary"
                          checked={shiftIndicator}
                          onChange={(e) => setShiftIndicator(e.target.checked)}
                        />
                      }
                      label="Shift Indicator"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Tachometer"
                          color="primary"
                          checked={tachometer}
                          onChange={(e) => setTachometer(e.target.checked)}
                        />
                      }
                      label="Tachometer"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Entertainment, Information & Communication
                  </Typography>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Integrated (in-dash) Music System"
                          color="primary"
                          checked={musicSystem}
                          onChange={(e) => setMusicSystem(e.target.checked)}
                        />
                      }
                      label="Integrated (in-dash) Music System"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Speakers"
                          color="primary"
                          checked={speaker}
                          onChange={(e) => setSpeaker(e.target.checked)}
                        />
                      }
                      label="Speakers"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Steering-mounted controls"
                          color="primary"
                          checked={steeringmountedctrls}
                          onChange={(e) =>
                            setSteeringmountedctrls(e.target.checked)
                          }
                        />
                      }
                      label="Steering-mounted controls"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Voice Command"
                          color="primary"
                          checked={voiceCommand}
                          onChange={(e) => setVoiceCommand(e.target.checked)}
                        />
                      }
                      label="Voice Command"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="GPS Navigation System"
                          color="primary"
                          checked={gps}
                          onChange={(e) => setGps(e.target.checked)}
                        />
                      }
                      label="GPS Navigation System"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Bluetooth Compatibility"
                          color="primary"
                          checked={bluetooth}
                          onChange={(e) => setBluetooth(e.target.checked)}
                        />
                      }
                      label="Bluetooth Compatibility"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="AUX Compatibility"
                          color="primary"
                          checked={aux}
                          onChange={(e) => setAux(e.target.checked)}
                        />
                      }
                      label="AUX Compatibility"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="AM/FM Radio"
                          color="primary"
                          checked={radio}
                          onChange={(e) => setRadio(e.target.checked)}
                        />
                      }
                      label="AM/FM Radio"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="USB Compatibility"
                          color="primary"
                          checked={usb}
                          onChange={(e) => setUsb(e.target.checked)}
                        />
                      }
                      label="USB Compatibility"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Head Unit Size"
                          color="primary"
                          checked={headUnitSize}
                          onChange={(e) => setHeadUnitSize(e.target.checked)}
                        />
                      }
                      label="Head Unit Size"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Manufacturer Warranty
                  </Typography>

                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      value={des12}
                      onChange={(e) => setdes12(e.target.value)}
                    />
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#E03012",
                        fontWeight: "bold",
                        width: "150px",
                        "&:hover": {
                          backgroundColor: "#E03012",
                        },
                      }}
                      onClick={handleSubmit4}
                    >
                      UPDATE
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "22px",
            marginBottom: "25px",
          }}
        >
          <Link to="/oldcar">
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#E03012",
                fontWeight: "bold",
                width: "150px",
                "&:hover": {
                  backgroundColor: "#E03012",
                },
              }}
            >
              Submit
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
}
