import React from 'react';
import { Grid, Card, CardContent, Typography, Button, Divider,Box } from '@mui/material';
import Slider from 'react-slick';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
const cardData = [
  {
    id: 1,
    title: 'PLUS 100Y EDITION 7S',
    subtitle: 'HECTORPLUS7 CVT 100Y EDITION',
    price: '₹22,16,800',
    image: require('../../../../images/plus7cvt.webp'), 
    features: [
      '35.56 cm (14 inch) HD Infotainment System',
      'Digital Bluetooth Key Sharing',
      'Panoramic Sunroof',
      'i-Smart with Advanced UI',
    ],
  },
  {
    id: 2,
    title: 'PLUS 100Y EDITION 7S',
    subtitle: 'HECTORPLUS7 DSL 6MT 100Y EDITION',
    price: '₹ ₹22,74,800',
    image: require('../../../../images/plus7dsl.webp'),
    features: [
      '35.56 cm (14 inch) HD Infotainment System',
      'Digital Bluetooth Key Sharing',
      'Panoramic Sunroof',
      'i-Smart with Advanced UI',
    ],
  },

  {
    id: 3,
    title: 'BLACKSTORM',
    subtitle: 'HECTOR PLUS 7S BLACKSTORM CVT',
    price: '₹22,28,800',
    image: require('../../../../images/plus7blackstorm.webp'),
    features: [
      'R 18 Sporty All Black Alloy Wheels',
      'Argyle Inspired Diamond Mesh Dark Chrome Grille',
      'All Black Interior Theme with Gun Metal Grey Accents',
      'Digital Bluetooth Key with Key Sharing function',
      '100 + Voice commands to control  Sunroof, AC & More',
      '35.56 cm HD Portrait Infotainment System',
      'Dual Pane Panoramic Sunroof',
      'Front Ventilated Seats',
    ],
  },

  {
    id: 4,
    title: 'BLACKSTORM',
    subtitle: 'HECTOR PLUS 7S BLACKSTORM DSL 6MT',
    price: '₹22,86,800',
    image: require('../../../../images/plus7dslblack.webp'),
    features: [
      'R 18 Sporty All Black Alloy Wheels',
      'Argyle Inspired Diamond Mesh Dark Chrome Grille',
      'All Black Interior Theme with Gun Metal Grey Accents',
      'Digital Bluetooth Key with Key Sharing function',
      '100 + Voice commands to control  Sunroof, AC & More',
      '35.56 cm HD Portrait Infotainment System',
      'Dual Pane Panoramic Sunroof',
      'Front Ventilated Seats',
    ],
  },
  {
    id: 5,
    title: 'PLUS STYLE 7S',
    subtitle: 'HECTOR PLUS 7 STYLE DSL 6MT',
    price: '₹17,29,800',
    image: require('../../../../images/plus7white.webp'),
    features: [
      'Hill Hold Control',
      'Rear Parking Sensors',
      'Front & Rear Defogger',
      'Speed SensingAuto Door Lock',
      'Traction Control System (TCS)',
      'HeightAdjustable Driver Seat',
    ],
  },
  {
    id: 6,
    title: 'PLUS SELECT PRO 7S',
    subtitle: 'HECTOR PLUS 7 SELECT PRO 6MT',
    price: '₹18,19,800',
    image: require('../../../../images/plus7selectpro.webp'),
    features: [
      'Curtain Airbags',
      'Hill Hold Control',
      '50+ Hinglish Voice Commands',
      'Dual Pane Panoramic Sunroof​',
      'Intelligent Turn Indicator',
      'Cruise Control ',
      'Wireless Charger',
      
    ],
  },
  {
    id: 7,
    title: 'PLUS SELECT PRO 7S',
    subtitle: 'HECTOR PLUS 7 SELECT PRO DSL 6MT',
    price: '₹19,81,800',
    image: require('../../../../images/plus7selectdsl.webp'),
    features: [
      'Curtain Airbags',
      'Hill Hold Control',
      '50+ Hinglish Voice Commands',
      'Dual Pane Panoramic Sunroof​',
      'Intelligent Turn Indicator',
      'Cruise Control ',
      'Wireless Charger',
    ],
  },
  {
    id: 8,
    title: 'PLUS SHARP PRO 7S',
    subtitle: 'HECTOR PLUS 7S SHARP PRO 6MT',
    price: '₹20,62,800',
    image: require('../../../../images/7sharppro.webp'),
    features: [
      '360 Around View High Definition (HD) Camera System',
      'Curtain Airbags',
      'Rain Sensing Wipers',
      'Front Ventilated Seats​',
      'Automatic Powered Tailgate Opening',
      '4-way Power Adjustable Co-Driver Seat',
      '8 Color Ambient Lighting with Voice Commands',
      'Premium Sound System by Infinity',   
    ],
  },
  {
    id: 9,
    title: 'PLUS SHARP PRO 7S',
    subtitle: 'HECTOR PLUS 7S SHARP PRO CVT',
    price: '₹21,96,800',
    image: require('../../../../images/7swhite.webp'),
    features: [
      '360 Around View High Definition (HD) Camera System',
      'Curtain Airbags',
      'Rain Sensing Wipers',
      'Front Ventilated Seats​',
      'Automatic Powered Tailgate Opening',
      '4-way Power Adjustable Co-Driver Seat',
      '8 Color Ambient Lighting with Voice Commands',
      'Premium Sound System by Infinity',
      
    ],
  },
  {
    id: 10,
    title: 'PLUS SHARP PRO 7S',
    subtitle: 'PLUS SHARP PRO 7S',
    price: '₹22,49,800',
    image: require('../../../../images/7swhite2.webp'),
    features: [
      '360 Around View High Definition (HD) Camera System',
      'Curtain Airbags',
      'Rain Sensing Wipers',
      'Front Ventilated Seats​',
      'Automatic Powered Tailgate Opening',
      '4-way Power Adjustable Co-Driver Seat',
      '8 Color Ambient Lighting with Voice Commands',
      'Premium Sound System by Infinity',
    ],
  },
  {
    id: 11,
    title: 'PLUS SAVVY PRO 7S',
    subtitle: 'HECTOR PLUS 7S SAVVY PRO CVT',
    price: '₹22,92,800',
    image: require('../../../../images/7swhite3.webp'),
    features: [
      '360 Around View High Definition (HD) Camera System',
      'Curtain Airbags',
      'Rain Sensing Wipers',
      'Front Ventilated Seats​',
      'Automatic Powered Tailgate Opening',
      '4-way Power Adjustable Co-Driver Seat',
      '8 Color Ambient Lighting with Voice Commands',
      'Premium Sound System by Infinity',
    ],
  },
  
];



const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowForwardIos
      onClick={onClick}
      style={{ 
        color: 'black', 
        fontSize: '30px', 
        position: 'absolute', 
        top: '50%', 
        right: '10px', 
        cursor: 'pointer', 
        zIndex: 1 
      }}
    />
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowBackIos
      onClick={onClick}
      style={{ 
        color: 'black', 
        fontSize: '30px', 
        position: 'absolute', 
        top: '50%', 
        left: '10px', 
        cursor: 'pointer', 
        zIndex: 1 
      }}
    />
  );
};
// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true, // Enable arrows
  nextArrow: <NextArrow />, // Custom next arrow
  prevArrow: <PrevArrow />, // Custom previous arrow
  responsive: [
    {
      breakpoint: 960, // Tablet
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600, // Mobile
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};


const CardGrid = () => {
  // Define card height
  const cardHeight = {md:'950px',xs:'750px',sm:'950px',}; 

  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Typography variant="h2"
        sx={{
          fontSize: { md: '2.08333vw', xs: '4.2vw' },
          color: '#cf2230',
          fontFamily: 'GillSansSemiBold, Arial, sans-serif',
          paddingBottom: '3.125vw',
          fontWeight: '600',
          marginTop: {md:'80px',xs:'40px',sm:'50px'},
          marginBottom:{xs:'10px'}
        }}>
        MG Hector Model Variants
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Slider {...settings}>
            {cardData.map((card) => (
              <div key={card.id}>
                <Card
                  sx={{
                    height: cardHeight, 
                    width: {
                      xs: '75%',  // For small screens
                      sm: '87%',  // For medium screens
                      md: '87%',  // For larger screens
                      lg: '75%'  
                    },
                    margin: '5px auto', 
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    boxSizing: 'border-box', 
                    '@media (min-width: 1024px) and (max-width: 1139px)': {
                      width: '300px',
                    },
                  }}
                >
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: 'GillSansRegular, sans-serif',
                        fontSize:{ xs: '4vw', sm: '2.5vw', md: '2vw', lg: '1.27083vw' },
                        color: '#000',
                        marginBottom: '20px',
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize: { xs: '3vw', sm: '1.6vw', md: '1.5vw', lg: '0.84167vw' },
                        color: '#6e6e6e',
                        marginBottom: { xs: '4vw', sm: '3vw', md: '2vw', lg: '1.0416vw' },
                      }}
                    >
                      {card.subtitle}
                    </Typography>
                    <Button
                      sx={{
                        display: 'inline-block',
                        backgroundColor: '#e1e1e1',
                        borderRadius: '5px',
                        textAlign: 'center',
                        padding: '8px 20px',
                        fontFamily: 'GillSansRegular, sans-serif',
                        fontSize: { xs: '4vw', sm: '2.1vw', md: '1.5vw', lg: '0.8167vw' },
                        color: '#000',
                        marginBottom: { xs: '2.1vw', sm: '2vw', md: '1vw', lg: '1.04167vw' },
                        '&:hover': { backgroundColor: '#d4d4d4' },
                      }}
                    >
                      Book Now
                    </Button>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize: { xs: '5vw', sm: '2.6vw', md: '2vw', lg: '0.84167vw' },
                        color: '#6e6e6e',
                        marginBottom: { xs: '2vw', sm: '1.5vw', md: '1vw', lg: '.52083vw' },
                      }}
                    >
                      Starts From
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Medium, sans-serif',
                        fontSize: { xs: '5vw', sm: '4vw', md: '3vw', lg: '1.875vw' },
                        color: '#000',
                        marginBottom: 0,
                      }}
                    >
                      {card.price}
                    </Typography>
                    <img
  src={card.image}
  alt={card.subtitle}
  style={{ width: '100%', objectFit: 'cover' }}
  sx={{
    height: { xs: '100px', md: '200px' }, // Adjust the height for different screen sizes
  }}
/>

                    <ul className="points" style={{ flexGrow: 1 }}>
  {card.features.map((feature, index) => (
    <li
      key={index}
      style={{
        fontFamily: 'Roboto-Regular, sans-serif',
        fontSize: '1.04167vw', // Default for larger screens
        color: '#000',
        marginBottom: '1.04167vw',
      }}
    >
      <Typography
        component="span"
        sx={{
          fontWeight:'600',
          fontSize: { xs: '10px', sm: '12px', md: '1.04167vw' }, // Responsive font sizes
          '@media (max-width: 425px)': {
            fontSize: '12px', 
          },
        }}
      >
        {feature}
      </Typography>
    </li>
  ))}
</ul>


                    <Divider style={{ color: '#D4D4D5' }} />
                    <Typography
                      sx={{
                        fontFamily: 'Roboto-Regular, sans-serif',
                        fontSize: {md:'.9375vw',xs:'10px'},
                        color: '#000',
                        marginTop: '40px',
                        position: 'absolute',
                        bottom: '30px', 
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      Brochure
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
      {/* <Button
        sx={{
          backgroundColor: '#CF2230',
          color: 'white',
          fontWeight: '700',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'GillSansSemiBold, sans-serif',
          fontSize: { md: '0.95vw', xs: '1.5vw' },
          marginBottom:'40px',
          cursor: 'pointer',
          marginTop: '50px',
          '&:hover': {
            backgroundColor: 'black',
            color: 'white',
          },
        }}
      >
        Compare Variants
      </Button> */}
    </Box>
  );
};

export default CardGrid;
