import { Component } from "react";
import React from "react";
import "./Contact.css";
import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  TextField,
  FormControlLabel,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import Navbar from "../../Home/Components/Nav";
import Footer from "../../Home/Components/Footer";
import office from "../../images/office.webp";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import plant from "../../Pages/images/plant.webp";
import Checkbox from "@mui/material/Checkbox";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      querySubject: "",
      queryType: "",
      query: "",
      agreedToPolicy: false,
    };
  }
  handleGetDirection = () => {
    // Replace with the actual coordinates of your office
    const latitude = 20.25435; // Example: Mg Motors Bhubaneswar latitude
    const longitude = 85.7866; // Example: Mg Motors Bhubaneswar longitude

    // Construct the Google Maps direction URL
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;

    // Open Google Maps in a new tab
    window.open(googleMapsUrl, "_blank");
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      mobile,
      email,
      queryType,
      querySubject,
      query,
      agreedToPolicy,
    } = this.state;
    this.props.AddContactForm(
      firstName,
      lastName,
      mobile,
      email,
      queryType,
      querySubject,
      query,
      agreedToPolicy
    );
  };
  render() {
    const {
      firstName,
      lastName,
      mobile,
      email,
      querySubject,
      queryType,
      query,
      agreedToPolicy,
    } = this.state;
    return (
      <div id="container">
        <Navbar />
        {/* Banner */}
        <div id="banner-image-contact">
          <div id="write">
            <Typography
              variant="h4"
              sx={{
                color: "black",
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                fontWeight: "bold",
                textAlign: "center",
                fontSize: { md: "40px", xs: "16px", sm: "30px" },
                position: "relative",
                bottom: "50px",
              }}
            >
              HAVE A QUERY? CONNECT WITH US HERE
            </Typography>
          </div>
        </div>
        {/* Office Address */}
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src={office}
                alt="Office"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  color: "#D12230",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Office
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                }}
              >
                MG Motor Bhubaneswar (MG Central) <br />
                Plot. No - 29/2094 Dumuduma, Khandagiri, Bhubaneswar, Odisha
                751030
                <br />
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                  textAlign: "center",
                  fontSize: { xs: "10px", sm: "16px", md: "16px" },
                  marginBottom: "20px",
                }}
              >
                Call Timings:24*7
                <br />
                <EmailIcon /> sales@mgcentral.in &nbsp;&nbsp; <LocalPhoneIcon />{" "}
                07941050770
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#D23132",
                    width: "200px",
                    fontWeight: "bolder",
                  }}
                  onClick={this.handleGetDirection} // Attach click handler
                >
                  Get Direction
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Plant Address */}
        {/* <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  mb: 1,
                  fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
                  color: '#D12230',
                  textAlign: 'center',
                  marginBottom: '20px'
                }}
              >
                Plant
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
                  textAlign: 'center',
                  fontSize: { xs: '10px', sm: '16px', md: '16px' },
                  marginBottom: '20px'
                }}
              >
                JSW MG Motor India Private Limited<br />
                Chandrapura Industrial Estate, Halol, District Panchmahal, Gujarat – 389351<br />
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
                  textAlign: 'center',
                  fontSize: { xs: '10px', sm: '16px', md: '16px' },
                  marginBottom: '20px'
                }}
              >
                Call Timings:24*7<br />
                <EmailIcon /> pulsehub@mgmotor.co.in &nbsp;&nbsp; <LocalPhoneIcon /> 1800-1006464
              </Typography>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              >
                <Button variant="contained" sx={{ backgroundColor: '#D23132', width: '200px', fontWeight: 'bolder' }}>
                  Get Direction
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <img
                src={plant}
                alt="Plant"
                style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
              />
            </Grid>
          </Grid>
        </Box> */}

        {/* contact us */}
        <Container
          maxWidth="md"
          style={{ paddingBottom: "20px", backgroundColor: "#F9F9F9" }}
        >
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "6vw", md: "2.08333vw" },
                color: "#cf2230",
                fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
                fontWeight: "400",
                marginTop: "15px",
              }}
            >
              Contact Us
            </Typography>
          </Box>

          {/* Form starts here */}
          <form onSubmit={this.handleSubmit}>
            <Box noValidate autoComplete="off" sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {/* First Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="First Name"
                    type="text"
                    variant="standard"
                    name="firstName"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.firstName}
                    onChange={(e) => {
                      this.setState({ firstName: e.target.value });
                    }}
                  />
                </Grid>

                {/* Last Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Last Name"
                    type="text"
                    variant="standard"
                    name="lastName"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.lastName}
                    onChange={(e) => {
                      this.setState({ lastName: e.target.value });
                    }}
                  />
                </Grid>

                {/* Mobile Number */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Mobile Number"
                    type="number"
                    variant="standard"
                    name="mobile"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.mobile}
                    onChange={(e) => {
                      this.setState({ mobile: e.target.value });
                    }}
                  />
                </Grid>

                {/* Email */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Email"
                    type="email"
                    variant="standard"
                    name="email"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                </Grid>

                {/* Query Type Dropdown */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <InputLabel
                      sx={{ "& .MuiInputLabel-asterisk": { color: "#d32f2f" } }}
                    >
                      Select Query Type
                    </InputLabel>
                    <Select
                      label="Select Query Type"
                      name="queryType"
                      value={this.state.queryType}
                      onChange={(e) => {
                        this.setState({ queryType: e.target.value });
                      }}
                    >
                      <MenuItem value="General Inquiry">
                        General Inquiry
                      </MenuItem>
                      <MenuItem value="Technical Support">
                        Technical Support
                      </MenuItem>
                      <MenuItem value="Feedback">Feedback</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Query Subject */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    label="Query Subject"
                    type="text"
                    variant="standard"
                    name="querySubject"
                    InputLabelProps={{
                      sx: { "& .MuiInputLabel-asterisk": { color: "#d32f2f" } },
                    }}
                    value={this.state.querySubject}
                    onChange={(e) => {
                      this.setState({ querySubject: e.target.value });
                    }}
                  />
                </Grid>

                {/* Query */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Query"
                    type="text"
                    variant="standard"
                    multiline
                    rows={4}
                    name="query"
                    value={this.state.query}
                    onChange={(e) => {
                      this.setState({ query: e.target.value });
                    }}
                  />
                </Grid>

                {/* Privacy Policy Checkbox */}
                <Grid item xs={12} display="flex" alignItems="center">
                  <Checkbox
                    sx={{ marginRight: "0px" }}
                    checked={this.state.agreedToPolicy}
                    onChange={(e) => {
                      this.setState({ agreedToPolicy: e.target.checked });
                    }}
                    required
                  />
                  <Typography sx={{ fontFamily: "Gill Sans, sans-serif" }}>
                    I agree to the privacy policy
                    <span style={{ color: "#d32f2f", marginLeft: "3px" }}>
                      *
                    </span>
                  </Typography>
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      color: "white",
                      backgroundColor: "#d32f2f",
                      fontFamily: "Gill Sans, sans-serif",
                    }}
                    size="large"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Container>
        {/* Footer Section */}
        <Footer />
      </div>
    );
  }
}

export default Contact;
