import React, { Component } from "react";
import { connect } from "react-redux";
import Contact from "../Components/contact";

import { AddContactForm } from "../../../Client/action";

export class Controller extends Component {
  render() {
    return <Contact {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    AddContactForm: (
      firstName,
      lastName,
      mobile,
      email,
      queryType,
      querySubject,
      query,
      agreedToPolicy
    ) => {
      dispatch(
        AddContactForm(
          firstName,
          lastName,
          mobile,
          email,
          queryType,
          querySubject,
          query,
          agreedToPolicy
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
