import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from "../Components/Navbar";

import {
  viewAlladdes,
  uploadImaes,
  updateStatus,
  uploadupdatesImages,
  deleteAddes,
} from "../action";
import {
    onLogout
  } from "../../Auth/Actions";
export class Controller extends Component {
  render() {
    return <Navbar {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAlladdes: (
      searchInput,
      page,
      pageSize,
      selectedDate,
      selectedTime,
      selectedStatus
    ) => {
      dispatch(
        viewAlladdes(
          searchInput,
          page,
          pageSize,
          selectedDate,
          selectedTime,
          selectedStatus
        )
      );
    },

    uploadImaes: (image, page, pageSize) => {
      dispatch(uploadImaes(image, page, pageSize));
    },

    onLogout: () => {
      dispatch(onLogout());
    },
   
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
