import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import bluewindsor from '../../images/Windsor/bluewindsor.webp';
import blackwindsor from '../../images/Windsor/blackwindsor.webp';
import whitewindsor from '../../images/Windsor/whitewindsor.webp';
import greywindsor from '../../images/Windsor/greywindsor.webp';


const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [fixedImage, setFixedImage] = useState(bluewindsor); 

  const carImages = [
    { src: bluewindsor, alt: "blue", color: "#32434B" },
    { src: blackwindsor, alt: "black", color: "#393939" },
    { src: whitewindsor, alt: "White", color: "#DDDDDD" },
    { src: greywindsor, alt: "grey", color: "#B1ACA2" },
  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
      <Carousel 
        activeIndex={activeIndex} 
        onSelect={handleSelect} 
        interval={null} 
        pause={false}
        indicators={false}
        controls={false}
      >
        {carImages.map((car, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={fixedImage} alt={car.alt} />
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Custom Circle Indicators */}
      <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
        {carImages.map((car, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            style={{
              width: "23px",
              height: "24px",
              borderRadius: "50%",
              backgroundColor: activeIndex === index ? car.color : car.color, 
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;