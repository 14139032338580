import React, { Component } from "react";
import { connect } from "react-redux";
import Preowned from "../Components/preowned";

import { viewAllOldCar } from "../action";
export class Controller extends Component {
  render() {
    return <Preowned {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
    oldcar: store.oldcar,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllOldCar: (selectedBudget, selectedFuels, selectedTransmissions, selectedOwners, query,brand) => {
      dispatch(viewAllOldCar(selectedBudget, selectedFuels, selectedTransmissions, selectedOwners, query,brand));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
