import React, { Component } from "react";
import { connect } from "react-redux";
import Service from "../Components/service";

import { viewAllService, deleteService, updateServiceStatus } from "../action";
export class Controller extends Component {
  render() {
    return <Service {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllService: (
      token,
      user_id,
      searchInput,
      page,
      pageSize,
      startDate,
      endDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllService(
          token,
          user_id,
          searchInput,
          page,
          pageSize,
          startDate,
          endDate,
          selectedStatus
        )
      );
    },

    updateServiceStatus: (token, user_id, id, status, page, pageSize) => {
      dispatch(updateServiceStatus(token, user_id, id, status, page, pageSize));
    },
    deleteService: (token, user_id, id, page, pageSize) => {
      dispatch(deleteService(token, user_id, id, page, pageSize));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
