import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import access1 from '../../../../../images/access1.webp';
import access2 from '../../../../../images/access2.webp';
import access3 from '../../../../../images/access3.webp';
import access4 from '../../../../../images/access4.webp';
import inte1 from '../../../../../images/inte1.webp';
import inte2 from '../../../../../images/inte2.jpg';
import inte3 from '../../../../../images/inte3.jpg';
import inte4 from '../../../../../images/inte4.jpg';
import safety1 from '../../../../../images/safety1.webp';
import safety2 from '../../../../../images/safety2.webp';
import safety3 from '../../../../../images/safety3.webp';
import safety4 from '../../../../../images/safety4.webp';
import ess1 from '../../../../../images/ess1.jpg';
import ess2 from '../../../../../images/ess2.webp';
import ess3 from '../../../../../images/ess3.webp';
import ess4 from '../../../../../images/ess4.webp';

class Accessories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'EXTERIOR',
      screenSize: window.innerWidth, 
      selectedImage: this.getTabImages('EXTERIOR')[0],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ screenSize: window.innerWidth });
  };

  getTabImages = (tab) => {
    const tabData = {
      EXTERIOR: [
        {
          src: access1,
          label:'Side Step Board',
          title: 'SIDE STEP BOARD',
          subtitle: 'DESIGNED FOR BEAUTY AND TOUGHNESS',
          description: 'Designed to provide additional support for old people while getting in and out of the vehicle. This side-step board comes with a 100 kg loading capacity.',
        },
        {
          src: access2,
          label:'Hood Branding',
          title: 'HOOD BRANDING',
          subtitle: 'DESIGNED FOR BEAUTY AND TOUGHNESS',
          description: 'Personalise your MG with high-quality additions that accentuate its character and look. Hood branding is available in Chrome and Black. ',
        },
        {
          src: access3,
          label: 'Bumper Corner Protector Front',
          title:'BUMPER CORNER PROTECTOR FRONT',
          subtitle: 'DESIGNED FOR BEAUTY AND TOUGHNESS',
          description: 'It protects your car bumper from scratch and damages.',
        },
        {
          src: access4,
          label: 'Door Protector',
          title:'DOOR PROTECTOR',
          subtitle: 'DESIGNED FOR BEAUTY AND TOUGHNESS',
          description: 'The high quality additions to compliment your car that are bound to turn heads. Specifically designed to add shine and glamour, it comes with high grade chrome quality finish.',
        }
      ],
      INTERIOR: [
        {
          src: inte1,
          label:'Rear Seat Entertainment Unit',
          title: 'REAR SEAT ENTERTAINMENT UNIT',
          subtitle: 'MAKE YOUR DRIVES TRULY EXCEPTIONAL',
          description: 'Best-in-class widescreen entertainment unit with HD resolution and full range of features for world-class in-car entertainment experience.',
        },
        {
          src: inte2,
          label:'Laptop Tray',
          title: "REAR SEAT ENTERTAINMENT UNIT",
          subtitle: 'MAKE YOUR DRIVES TRULY EXCEPTIONAL',
          description: 'Best-in-class widescreen entertainment unit with HD resolution and full range of features for world-class in-car entertainment experience.',
        },
        {
          src: inte3,
          label:'Car Coat Hanger',
          title: 'CAR COAT HANGER',
          subtitle: 'MAKE YOUR DRIVES TRULY EXCEPTIONAL',
          description: 'Always be occasion-ready with wrinkle-free clothes at hand with the easy-to-install car coat hanger.',
        },
        {
          src: inte4,
          label:'Air Purifier',
          title: 'AIR PURIFIER',
          subtitle: 'MAKE YOUR DRIVES TRULY EXCEPTIONAL',
          description: 'Now purify the air in your car and travel hassle-free and comfortably with this portable air purifier, specifically designed to fit in your vehicle cup holder.',
        }
      ],
      SAFETY: [
        {
          src: safety1,
          label:'Tyre Inflator',
          title: 'TYRE INFLATOR',
          subtitle: 'MAKE YOUR JOURNEYS SAFER',
          description: 'Powerful and portable, your MG Tyre Inflator uses a 12-volt vehicle car system to quickly inflate tyres of the car on-the-go. This easy-to-use compact inflator stores neatly under the car seat or in the trunk and helps inflate the tyres anytime.​',
        },
        {
          src: safety2,
          label:'Jumper Start Cable',
          title: 'JUMPER START CABLE',
          subtitle: 'MAKE YOUR JOURNEYS SAFER',
          description: 'Designed to use in emergency conditions while driving, these high-quality jumper start cables come with insulated cover and heavy-duty spring loaded steel clamps.',
        },
        {
          src: safety3,
          label: 'Wind Deflector',
          title:'WIND DEFLECTOR',
          subtitle: 'MAKE YOUR JOURNEYS SAFER',
          description: 'The high quality additions to compliment your car that are bound to turn heads. Specifically designed to add shine and glamour, it comes with high grade chrome quality finish.',
        },
        {
          src: safety4,
          label: '360 DVR',
          title:'360 DVR',
          subtitle: 'MAKE YOUR JOURNEYS SAFER',
          description: 'Capture Blind Spots of the vehicle with this Panoramic View DVR. Do HD video recording and easily store videos through MG App support.',
        }
      ],
      ESSENTIALS: [
        {
          src: ess1,
          label:'3D Mats',
          title: '3D MATS',
          subtitle: 'RAISE YOUR DRIVING EXPERIENCE',
          description: 'The high quality tufted mats with MG branding do not simply give your car a luxurious appearance but also protect it against dirt and grease stains. On top of it, they have anti-slip backing and are easy to maintain.',
        },
        {
          src: ess2,
          label:'Sun Shades',
          title: "SUN SHADES",
          subtitle: 'RAISE YOUR DRIVING EXPERIENCE',
          description: 'Sometimes it is better for the sun to just stay outside. The sun shades have a premium mesh that provide optimum sun-protection and hold back hot air - making your car’s AC more effective.',
        },
        {
          src: ess3,
          label:'Car Cover',
          title: 'CAR COVER',
          subtitle: 'RAISE YOUR DRIVING EXPERIENCE',
          description: 'Always keep your car protected even when it is parked. The MG branded silver car cover is convenient to use and provides resistance against dust, dirt, UV rays and water.',
        },
        {
          src: ess4,
          label:'Screen Guard',
          title: 'SCREEN GUARD',
          subtitle: 'RAISE YOUR DRIVING EXPERIENCE',
          description: 'The screen guard helps protect your screen from scratches.',
        }
      ],
     
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0],
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage, screenSize } = this.state;
    const images = this.getTabImages(selectedTab);

    return (
      <Box sx={{ width: '80%', height:'100%',
    
      }}>
        <Box
          sx={{
            height:'90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' } ,
            marginTop:{xs:'15px'},
           
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '95vw',
              maxWidth: '500px',
              height: 'auto',
              
            }}
            alt={selectedImage.title}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: {md:'space-around', xs:'start',sm:'start'},
              alignItems:'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-35px', md: '0px' } 
            }}
          >
            <Typography variant="h5"
              sx={{
                width: { xs: '90%', md: '330px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}>
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '270px', md: '330px',sm:'100%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw' ,sm:'2.5vw'},
                marginBottom: '12px',
              }}>
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '270px', md: '290px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw',sm:'2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
              }}>
              {selectedImage.description}
            </Typography>
          </Box>
        </Box>
        <Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '10px',
    marginTop: { xs: '30px', md: '50px' },
    marginLeft: { xs: '-25px', md: '0px' } 
  }}
>
  {images.map((img, index) => (
    <Box
      key={index}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { md: 'center', xs: 'start' }
      }}
    >
      <img
        src={img.src}
        style={{
          borderRadius: '10px',
          width: '20vw',
          maxWidth: '250px',
          height: 'auto',
          marginTop: { xs: '10px', md: '40px', sm: '20px' }
        }}
        alt={img.label}
        onClick={() => this.handleImageClick(img)}
      />
      <Typography
        sx={{
          fontFamily: 'Roboto-Medium, Arial, sans-serif',
          fontSize: { xs: '3.5vw', md: '1.04167vw' },
          color: '#000',
          paddingTop: '1.04167vw',
          margin: 0,
          display:{md:'flex',xs:'none'},
          textAlign: { xs: 'left', md: 'left' }, 
          width: '100%' 
        }}
      >
        {img.label}
      </Typography>
    </Box>
  ))}
</Box>

      </Box>
    );
  };

  render() {
    const { selectedTab, screenSize } = this.state;
    const tabs = ['EXTERIOR', 'INTERIOR', 'SAFETY', 'ESSENTIALS'];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: '20px', md: '0' }, 
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' },
            color: '#cf2230',
            fontFamily: 'GillSansSemiBold, Arial, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop: '15px',
          }}
        >
          ACCESSORIES
        </Typography>

        {screenSize <= 768 ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px' }}>
            <Select
              style={{fontWeight:'700'}}
              value={selectedTab}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '75%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' },
                  color: selectedTab === tab ? 'white' : 'black',
                  backgroundColor: selectedTab === tab ? '#E32735' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#E32735',
                    color: 'white',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display: { md: 'flex', xs: 'none' }, border: '4px solid #E32735', margin: '20px 0' }} />

        {this.renderContent()}
      </Box>
    );
  }
}

export default Accessories;
