import React, { Component } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  TextField,
  Button,
  InputAdornment,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  Divider,
  MenuItem,
  Avatar,
  FormControl,
  InputLabel,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Visibility } from "@mui/icons-material";
import { Delete, Edit } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Search as SearchIcon } from "@mui/icons-material";
import "./addes.css";
import moment from "moment";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
      add: false,
      edit: false,
      reject: false,
      selectedEmployee: null,
      searchInput: "",
      page: 0,
      rowsPerPage: 10,
      service_id: "",
      startDate: "",
      endDate: "",
      selectedStatus: "",
      editStatus: false,
      status: "",
    };
    this.debouncedSearch = _.debounce(this.handleSearch, 300);
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.props.viewAllService(
        this.props.login.token,
        this.props.login.user_id,
        this.state.searchInput,
        newPage,
        this.state.rowsPerPage,
        this.state.startDate,
        this.state.endDate,
        this.state.selectedStatus
      );
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      },
      () => {
        this.props.viewAllService(
          this.props.login.token,
          this.props.login.user_id,
          this.state.searchInput,
          0,
          this.state.rowsPerPage,
          this.state.startDate,
          this.state.endDate,
          this.state.selectedStatus
        );
      }
    );
  };

  handleSearch = () => {
    this.props.viewAllService(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      this.state.startDate,
      this.state.endDate,
      this.state.selectedStatus
    );
  };

  handleSearchChange = (event) => {
    const searchInput = event.target.value;
    this.setState({ searchInput }, () => {
      this.debouncedSearch();
    });
  };

  handleDialogOpen = (employee) => {
    this.setState({ selectedEmployee: employee, view: true });
  };

  handleDialogClose = () => {
    this.setState({ view: false, editStatus: false, selectedEmployee: null });
  };

  componentDidMount() {
    this.props.viewAllService(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      this.state.startDate,
      this.state.endDate,
      this.state.selectedStatus
    );
  }

  handleStartDateChange = (event) => {
    this.setState({ startDate: event.target.value });
    this.props.viewAllService(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      event.target.value,
      event.target.endDate,
      this.state.selectedStatus
    );
    console.log("Selected Date:", event.target.value);
  };
  handleEndDateChange = (event) => {
    this.setState({ endDate: event.target.value });
    this.props.viewAllService(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      this.state.startDate,
      event.target.value,
      this.state.selectedStatus
    );
    console.log("Selected Date:", event.target.value);
  };

  handleStatusChange = (event) => {
    this.setState({ selectedStatus: event.target.value });
    this.props.viewAllService(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      this.state.startDate,
      this.state.endDate,
      event.target.value
    );

    console.log("Selected Status:", event.target.value);
  };

  render() {
    const {
      searchInput,
      selectedEmployee,
      view,
      reject,
      service_id,
      page,
      rowsPerPage,
      editStatus,
      status,
    } = this.state;

    return (
      <Grid
        container
        spacing={2}
        style={{ alignItems: "center", marginTop: "50px" }}
      >
        {/* Filter and Pagination Section */}
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            {/* Search Bar */}
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                label="Search"
                size="small"
                onChange={this.handleSearchChange}
                value={searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ width: "100%" }}
              />
            </Grid>

            {/* Start Date Filter */}
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                label="Start Date"
                type="date"
                onChange={this.handleStartDateChange}
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
              />
            </Grid>

            {/* End Date Filter */}
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                label="End Date"
                type="date"
                onChange={this.handleEndDateChange}
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
              />
            </Grid>

            {/* Status Filter */}
            <Grid item xs={12} sm={4} md={3}>
              <Select
                label="Filter by Status"
                onChange={this.handleStatusChange}
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                defaultValue=""
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="Requested">Requested</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
                <MenuItem value="Resolved">Resolved</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
              </Select>
            </Grid>

            {/* Pagination Section (Aligned to the Right) */}
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <TablePagination
                  component="div"
                  count={this.props.client?.all_servicelength || 0}
                  page={page}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage="Rows per page"
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} of ${count}`;
                  }}
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Advertisement Table */}
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            style={{
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                  {[
                    "Sl No",
                    "Name",
                    "Mobile",
                    "Email",
                    "Car Registeration Number",
                    "Preferred Date",
                    "Status",
                    "Action",
                  ].map((header, index) => (
                    <TableCell key={index} align="center">
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        {header}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(this.props.client.all_service) &&
                  this.props.client.all_service.map((item, index) => {
                    const createdAt = new Date(item.preferredDateforService);
                    const date = createdAt.toLocaleDateString(); // Formats date as MM/DD/YYYY
                    const time = createdAt.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    }); // Formats time as HH:MM AM/PM

                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#ffffff" : "#f9f9f9",
                        }}
                      >
                        <TableCell align="center">
                          <Typography variant="body2">{index + 1}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">
                            {item.name} 
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">{item.mobile}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">{item.email}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">
                            {item.carRegisterationNumber}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">{date}</Typography>
                          <Typography variant="body2">{time}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">
                            {item.status}
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                onClick={(e) => {
                                  this.setState({
                                    editStatus: true,
                                    selectedEmployee: item,
                                  });
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="View Details" arrow>
                            <IconButton
                              onClick={() => this.handleDialogOpen(item)}
                            >
                              <Visibility />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Delete" arrow>
                            <IconButton
                              onClick={() => {
                                // this.toggleStatus(item);
                                this.setState({
                                  reject: true,
                                  service_id: item._id,
                                });
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* View Details Dialog */}
        <Dialog
          open={view}
          onClose={this.handleDialogClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Service Details</DialogTitle>
          <DialogContent>
            {selectedEmployee && (
              <Paper
                elevation={3}
                style={{ padding: "20px", borderRadius: "8px" }}
              >
                <Grid container spacing={2}>
                  {/* Profile Avatar */}
                  <Grid item xs={12} container justifyContent="center">
                    <Avatar
                      alt={`${selectedEmployee.name}`}
                      src={selectedEmployee.avatarUrl || "default-avatar.png"} // Default avatar if not provided
                      sx={{ width: 56, height: 56, marginBottom: "16px" }}
                    />
                  </Grid>

                  {/* Employee Name */}
                  <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                      {selectedEmployee.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {/* Contact Information */}
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Email:</strong> {selectedEmployee.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Mobile:</strong> {selectedEmployee.mobile}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Modal:</strong> {selectedEmployee.model}
                    </Typography>
                  </Grid>

                  {/* Query Information */}
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Preffered Service Location:</strong> {selectedEmployee.prefferedServiceCenterLocation}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>preferred Date for Service:</strong>{" "}
                      {moment(selectedEmployee.preferredDateforService).format('LLL')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Car Registeration Number:</strong> {selectedEmployee.carRegisterationNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <strong>Status:</strong> {selectedEmployee.status}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.reject}
          onClose={() => {
            this.setState({ reject: false, service_id: "" });
          }}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to Delete the Service Details?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                this.props.deleteService(
                  this.props.login.token,
                  this.props.login.user_id,
                  this.state.service_id,
                  this.state.page,
                  this.state.rowsPerPage
                );
                this.setState({ reject: false, service_id: "" });
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={() => this.setState({ reject: false })}
              color="secondary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editStatus}
          onClose={this.handleDialogClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Edit Status</DialogTitle>
          <DialogContent>
            {selectedEmployee && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      value={status}
                      onChange={(e) => {
                        this.setState({ status: e.target.value });
                      }}
                      label="Status"
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="In Progress">In Progress</MenuItem>
                      <MenuItem value="Resolved">Resolved</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                this.props.updateServiceStatus(
                  this.props.login.token,
                  this.props.login.user_id,
                  selectedEmployee._id,
                  this.state.status,
                  this.state.page,
                  this.state.rowsPerPage
                );
                this.setState({ editStatus: false, status: "" });
              }}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withParams(Employee);
