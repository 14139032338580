import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import greystorm from '../../../../images/storm/greystorm.webp';
import blackstorm from '../../../../images/storm/blackstorm.webp';
import whitestorm from '../../../../images/storm/whitestorm.webp';

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [fixedImage, setFixedImage] = useState(whitestorm); 

  const carImages = [
    { src: whitestorm, alt: "whitestorm", color: "#FDFCFF" },
    { src: greystorm, alt: "greystorm", color: "#413D3A" },
    { src: blackstorm, alt: "blackstorm", color: "black" },
  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
      <Carousel 
        activeIndex={activeIndex} 
        onSelect={handleSelect} 
        interval={null} 
        pause={false}
        indicators={false}
        controls={false}
      >
        {carImages.map((car, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={fixedImage} alt={car.alt} />
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Custom Circle Indicators */}
      <div style={{ position: "absolute", bottom: "7px", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
        {carImages.map((car, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            style={{
              width: "23px",
              height: "22px",
              borderRadius: "50%",
              backgroundColor: activeIndex === index ? car.color : car.color, 
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
