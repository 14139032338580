import React, { Component } from "react";
import { connect } from "react-redux";
import OldCar from "../Components/OldCar";

import { viewAllOldCar, deleteOldCar, updateOldCarStatus } from "../action";
export class Controller extends Component {
  render() {
    return <OldCar {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewAllOldCar: (
      token,
      user_id,
      searchInput,
      page,
      pageSize,
      startDate,
      endDate,
      selectedStatus
    ) => {
      dispatch(
        viewAllOldCar(
          token,
          user_id,
          searchInput,
          page,
          pageSize,
          startDate,
          endDate,
          selectedStatus
        )
      );
    },
    updateOldCarStatus: (token, user_id, id, status, page, pageSize) => {
      dispatch(updateOldCarStatus(token, user_id, id, status, page, pageSize));
    },

    deleteOldCar: (token, user_id, id, page, pageSize) => {
      dispatch(deleteOldCar(token, user_id, id, page, pageSize));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
