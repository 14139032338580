import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
export default class LoginController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      page: ""
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token") !== null) {
      this.props.fetchToken(localStorage.getItem("token"))
    }
    else {
      localStorage.removeItem("token");
      this.setState({ redirect: true });
      this.props.onLogout();
    }
  }

  render() {
    if (this.state.redirect) {
      return <Link to="/" />;
    }
    return (
      <div />
    );
  }
}