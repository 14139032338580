import React, { Component } from "react";
import { connect } from "react-redux";
import Addfeature from "../Components/Feature";

import {
    addFeature,
} from "../action";
export class Controller extends Component {
    render() {
        return (
            <Addfeature {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        client: store.client,
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        addFeature: (
            
            token,user_id,oldCar_id, 
            airbag,
            tyrePresssure,
            childSeatAnchorPoints,
            seatBeltWarning,
            // des1,
            abs,
            ebd,
            ba,
            esp,
            fourWheel,
            hillHoldControl,
            tcs,
            // rideHeightAdjustment,
            hillDescentControl,
            // des2,
            engineImmobiliser,
            centeralLocking,
            speedSensingDoorLock,
            childSafetyLock,
            ac,
            frontac,
            rearac,
            heater,
            vanityMirror,
            antiglareMirror,
            parkingAssist,
            parkingSensor,
            cruisecontrol,
            headlightIgnition,
            keylessstart,
            steeringAdjustment,
            power,
            // des3,
            applecarplay,
            androidauto,
            // des4,
            seatUpholestry,
            drivesideAdjust,
            leatherWrapped,
            driverArmrest,
            // rearPassengerSeatType,
            // interiors,
            // interiorsColor,
            foldingRearSeat,
            splitRearSeat,
            splitThirdRowSeat,
            frontSeatbackPockets,
            headrests,
            // des5,
            cupholders,
            driverArmrestStorage,
            cooledGloveBox,
            sunGlassHolder,
            thirdRowCupHolder,
            // des6,
            orvm,
            powerWindow,
            oneTouchDown,
            oneTouchUp,
            adjustableOrvm,
            exteriorDoorHandle,
            chromeexteriorDoorHandle,
            doorPockets,
            bootlidOpener,
            // des7,
            sunroof,
            roofMountedAntenna,
            bodyColouredBumpers,
            chromeFinish,
            // des8,
            projectorheadlight,
            autoheadlamps,
            homeheadlamps,
            drl,
            tailights,
            cabinLamps,
            lightonVanityMirror,
            rearreadinglamp,
            gloveBoxlamp,
            headlightHeightAdjuster,
            // des9,
            // instantaneousConsumption,
            // instrumentCluster,
            tripMeter,
            averageFuelConsumption,
            // averageSpeed,
            distancetoEmpty,
            clock,
            lowFuelWarning,
            doorAjarWarning,
            adjustableCluster,
            gearIndicator,
            shiftIndicator,
            tachometer,
            // des10,
            // display,
            musicSystem,
            speaker,
            steeringmountedctrls,
            voiceCommand,
            gps,
            bluetooth,
            aux,
            radio,
            usb,
            headUnitSize,
            // iPod,
            // des11,
            // warrrantyyear,
            // warrrantykm,
            des12
        ) => {
            dispatch(addFeature(
                token,user_id,oldCar_id,
                airbag,
                tyrePresssure,
                childSeatAnchorPoints,
                seatBeltWarning,
                // des1,
                abs,
                ebd,
                ba,
                esp,
                fourWheel,
                hillHoldControl,
                tcs,
                // rideHeightAdjustment,
                hillDescentControl,
                // des2,
                engineImmobiliser,
                centeralLocking,
                speedSensingDoorLock,
                childSafetyLock,
                ac,
                frontac,
                rearac,
                heater,
                vanityMirror,
                antiglareMirror,
                parkingAssist,
                parkingSensor,
                cruisecontrol,
                headlightIgnition,
                keylessstart,
                steeringAdjustment,
                power,
                // des3,
                applecarplay,
                androidauto,
                // des4,
                seatUpholestry,
                drivesideAdjust,
                leatherWrapped,
                driverArmrest,
                // rearPassengerSeatType,
                // interiors,
                // interiorsColor,
                foldingRearSeat,
                splitRearSeat,
                splitThirdRowSeat,
                frontSeatbackPockets,
                headrests,
                // des5,
                cupholders,
                driverArmrestStorage,
                cooledGloveBox,
                sunGlassHolder,
                thirdRowCupHolder,
                // des6,
                orvm,
                powerWindow,
                oneTouchDown,
                oneTouchUp,
                adjustableOrvm,
                exteriorDoorHandle,
                chromeexteriorDoorHandle,
                doorPockets,
                bootlidOpener,
                // des7,
                sunroof,
                roofMountedAntenna,
                bodyColouredBumpers,
                chromeFinish,
                // des8,
                projectorheadlight,
                autoheadlamps,
                homeheadlamps,
                drl,
                tailights,
                cabinLamps,
                lightonVanityMirror,
                rearreadinglamp,
                gloveBoxlamp,
                headlightHeightAdjuster,
                // des9,
                // instantaneousConsumption,
                // instrumentCluster,
                tripMeter,
                averageFuelConsumption,
                // averageSpeed,
                distancetoEmpty,
                clock,
                lowFuelWarning,
                doorAjarWarning,
                adjustableCluster,
                gearIndicator,
                shiftIndicator,
                tachometer,
                // des10,
                // display,
                musicSystem,
                speaker,
                steeringmountedctrls,
                voiceCommand,
                gps,
                bluetooth,
                aux,
                radio,
                usb,
                headUnitSize,
                // iPod,
                // des11,
                // warrrantyyear,
                // warrrantykm,
                des12
            ));
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
