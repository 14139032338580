import React, { Component } from "react";
import { connect } from "react-redux";
import LoginController from "../Components/Controller";
import { setAdminLogin, fetchToken, onLogout } from "../../Auth/Actions";


export class Controller_con extends Component {

  render() {
    return (
      <LoginController {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    setAdminLogin: (payload) => {
      dispatch(setAdminLogin(payload));
    },
    fetchToken: (token) => {
      dispatch(fetchToken(token));
    },
    onLogout: () => {
      dispatch(onLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controller_con);