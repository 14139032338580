import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../Components/Login";
import { close_snack_bar } from "../../Common/snackbar/action";
import { forgotpassword, loginFetch } from "../Actions";
export class Controller extends Component {
    render() {
        return (
            <Login {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        loginFetch: (email, password) => {
            dispatch(loginFetch(email, password));
        },
        forgotpassword: (email) => {
            dispatch(forgotpassword(email));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);