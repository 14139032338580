import {
  ALLOLDCARS,
  VIEWOVERVIEWBYOLDCAR,
  VIEWOLDCARBYID,
  VIEWFETURESBYOLDCARID,
  VIEWSPECIFICATIONBYOLDCAR,
  VIEWCARBYBRAND,
} from "./constant";
const initial_state = {
  all_oldCarslength: 0,
  all_oldCars: [],
  viewOverviewByOldcar: {},
  viewOldcarById: {},
  viewSpecificationByOldcar: {},
  viewFeturesByOldcar: {},
  carByBrand: [],
  carByBrandlength: 0,
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case ALLOLDCARS:
      return (state = {
        ...state,
        all_oldCars: action.payload,
        all_oldCarslength: action.count,
      });
    case VIEWCARBYBRAND:
      return (state = {
        ...state,
        carByBrand: action.payload,
        carByBrandlength: action.count,
      });
    case VIEWOVERVIEWBYOLDCAR:
      return (state = {
        ...state,
        viewOverviewByOldcar: action.payload,
      });
    case VIEWOLDCARBYID:
      return (state = {
        ...state,
        viewOldcarById: action.payload,
      });
    case VIEWSPECIFICATIONBYOLDCAR:
      return (state = {
        ...state,
        viewSpecificationByOldcar: action.payload,
      });
    case VIEWFETURESBYOLDCARID:
      return (state = {
        ...state,
        viewFeturesByOldcar: action.payload,
      });

    default:
      return state;
  }
}
