import React, { Component } from "react";
import { connect } from "react-redux";
import Addoverview from "../Components/overview";

import { addOverview } from "../action";
export class Controller extends Component {
  render() {
    return <Addoverview {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    addOverview: (
      token,
      user_id,
      oldCarId,
      registerationYear,
      manufacturingYear,
      owners,
      transmission,
      color,
      // location,
      insurance,
      registerationType,
      // lastUpdated,
      description
    ) => {
      dispatch(
        addOverview(
          token,
          user_id,
          oldCarId,
          registerationYear,
          manufacturingYear,
          owners,
          transmission,
          color,
          // location,
          insurance,
          registerationType,
          // lastUpdated,
          description
        )
      );
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
