import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: '3.55556vw',
    flexDirection: 'column',
    fontSize:{xs:'18px'}
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: '5.55556vw',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'GillSansSemiBold',
            fontSize: { xs: '2.7vw', md: '1.55556vw', }, // Increase font size for mobile
            backgroundColor: 'transparent',
            color: '#000',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: '#CF2230' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Roboto-Regular',
              fontSize: {md:'1.3vw',xs:'2.7vw',sm:'2.4vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
    'You can explore two seating variants in The Advanced Gloster – a 6 (2+2+2) configuration with second-row captain seats or a 7 (2+3+2) configuration with bench seats.Talk about all variants 4x4, 4x2 and then seats',
    'The Advanced MG Gloster equips a dual-pane panoramic sunroof.',
    "The driver’s seat in the MG Gloster can be adjusted in 12 ways. Additionally, there’s a massage feature on the driver’s side.  Driver side is 12 and co passenger is 8.",
    'The Advanced Gloster features a 31.2 cm Multi-Information Screen Display for all your information and entertainment needs.  ',
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
  id="features"
  sx={{
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Typography
    variant="h2"
    sx={{
      fontSize: { md: '2.08333vw', xs: '4.7vw' },
      color: '#cf2230',
      fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
      paddingBottom: { md: '3.125vw', xs: '5.4vw' },
      fontWeight: '400',
      marginTop: { xs: '50px' },
    }}
  >
    Frequently Asked Questions
  </Typography>
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          fontFamily: 'GillSansSemiBold',
          fontSize: { md: '5.55556vw' },
          backgroundColor: 'transparent',
          color: '#000',
          paddingRight: '4%',
          paddingLeft: '0',
        }}
      >
        {[
          'How many seating variants can I get in the MG Gloster?',
          'How large is the MG Gloster sunroof?',
          'Can the driver seat be adjusted in the MG Gloster?',
          'What is the size of the screen display?',
        ].map((question, index) => (
          <React.Fragment key={index}>
            <QuestionBox
              question={question}
              content={contents[index]}
              expanded={expanded === index}
              onToggle={() => handleToggle(index)}
            />
            {index < contents.length - 1 && (
              <Divider
                sx={{
                  margin: '10px 0',
                  backgroundColor: 'black',
                  height: '2px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Grid>
  </Grid>
</Box>

    </Container>
  );
}
