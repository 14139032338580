import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import greencomet from '../../images/Comet/greencomet.webp';
import greycomet from '../../images/Comet/greycomet.webp';
import whitecomet from '../../images/Comet/whitecomet.webp';
import blackcomet from '../../images/Comet/black.webp';
import whiteblackcomet from '../../images/Comet/whiteblackcommet.webp';

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [fixedImage, setFixedImage] = useState(greencomet); 

  const carImages = [
    { src: greencomet, alt: "greencomet", color: "#B2E061" },
    { src: greycomet, alt: "greycomet", color: "#D1D1DB" },
    { src: whitecomet, alt: "Whitecomet", color: "#EAE9E7" },
    { src: blackcomet, alt: "blackcomet", color: "#242020" },
    { src: whiteblackcomet, alt: "white-blackcomet", color: "#fff" },
  ];

  const handleSelect = (index) => {
    setActiveIndex(index);
    setFixedImage(carImages[index].src); 
  };

  return (
    <div style={{ position: "relative",margin:0,padding:0 }}>
      <Carousel 
        activeIndex={activeIndex} 
        onSelect={handleSelect} 
        interval={null} 
        pause={false}
        indicators={false}
        controls={false}
      >
        {carImages.map((car, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={fixedImage} alt={car.alt} />
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Custom Circle Indicators */}
      <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", display: "flex", gap: "12px" }}>
        {carImages.map((car, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            style={{
              width: "23px",
              height: "24px",
              borderRadius: "50%",
              backgroundColor: activeIndex === index ? car.color : car.color, 
              cursor: "pointer",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;