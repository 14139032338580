import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import access1 from '../../../images/Comet/exter1.webp';
import access2 from '../../../images/Comet/exter2.webp';
import access3 from '../../../images/Comet/exter3.webp';
import access4 from '../../../images/Comet/exter4.webp';
import inte1 from '../../../images/Comet/inte1.webp';
import inte2 from '../../../images/Comet/inte2.webp';
import inte3 from '../../../images/Comet/inte3.webp';
import inte4 from '../../../images/Comet/inte4.webp';
import lit1 from '../../../images/Comet/lit1.webp';
import lit2 from '../../../images/Comet/lit2.webp';
import lit3 from '../../../images/Comet/lit3.webp';
import lit4 from '../../../images/Comet/lit4.webp';
import sticker1 from '../../../images/Comet/sticker1.webp';
import sticker2 from '../../../images/Comet/sticker2.webp';
import sticker3 from '../../../images/Comet/sticker3.webp';
import sticker4 from '../../../images/Comet/sticker4.webp';
import edition1 from '../../../images/Comet/edition1.webp';
import edition2 from '../../../images/Comet/edition2.webp';
import edition3 from '../../../images/Comet/edition3.webp';
import edition4 from '../../../images/Comet/edition4.webp'

class Accessories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'LIMITED EDITION',
      screenSize: window.innerWidth, 
      selectedImage: this.getTabImages('LIMITED EDITION')[0],
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ screenSize: window.innerWidth });
  };

  getTabImages = (tab) => {
    const tabData = {
      "LIMITED EDITION" : [
        {
          src: edition1,
          label:'Exterior',
          title: 'Gamer Edition',
          subtitle: 'Exterior',
          description: 'Doesn’t it hit different when somebody matches your vibe? And MG Comet EV is just the car that will vibe with you because it’s the perfect partner in crime which brings out the fun side of you as you drive the car through the city.',
        },
        {
          src: edition2,
          label:'Steering Wheel Cover-Gamer',
          title: 'Gamer Edition',
          subtitle: 'Steering Wheel Cover-Gamer',
          description: 'Doesn’t it hit different when somebody matches your vibe? And MG Comet EV is just the car that will vibe with you because it’s the perfect partner in crime which brings out the fun side of you as you drive the car through the city.',
        },
        {
          src: edition3,
          label: 'Interior Inserts-Gamer',
          title:'Gamer Edition',
          subtitle: 'Interior Inserts-Gamer',
          description: 'Doesn’t it hit different when somebody matches your vibe? And MG Comet EV is just the car that will vibe with you because it’s the perfect partner in crime which brings out the fun side of you as you drive the car through the city.',
        },
        {
          src: edition4,
          label: 'Body Graphics-Gamer',
          title:'Gamer Edition',
          subtitle: 'Body Graphics-Gamer',
          description: 'Doesn’t it hit different when somebody matches your vibe? And MG Comet EV is just the car that will vibe with you because it’s the perfect partner in crime which brings out the fun side of you as you drive the car through the city.',
        }
      ],
     STICKERS: [
        {
          src: sticker1,
          label:'Blossom',
          title: 'Stickers',
          subtitle: 'Blossom',
          description: 'Make a Statement With Your Car.',
        },
        {
          src: sticker2,
          label:'Emoticon',
          title: "Stickers",
          subtitle: 'Emoticon',
          description: 'Make a Statement With Your Car.',
        },
        {
          src: sticker3,
          label:'Floresta',
          title: 'Stickers',
          subtitle: 'Floresta',
          description: 'Make a Statement With Your Car.',
        },
        {
          src: sticker4,
          label:'Night Cafe',
          title: 'Stickers',
          subtitle: 'Night Cafe',
          description: 'Make a Statement With Your Car.',
        }
      ],
      "LIT PACKS":[
        {
          src: lit1,
          label:'Serenity Lush Pack',
          title: 'Lit-Packs',
          subtitle: 'Serenity Lush Pack',
          description: 'Colour Kit Pack.',
        },
        {
          src: lit2,
          label:'Sundowner Lush Pack',
          title: 'Lit-Packs',
          subtitle: 'Sundowner Lush Pack',
          description: 'Colour Kit Pack.',
        },
        {
          src: lit3,
          label:'Flex Lush Pack',
          title: 'Lit-Packs',
          subtitle: 'Flex Lush Pack',
          description: 'Colour Kit Pack.',
        },
        {
          src: lit4,
          label:'Beach Bae Lush Pack',
          title: 'Lit-Packs',
          subtitle: 'Beach Bae Lush Pack',
          description: 'Colour Kit Pack.',
        },
      ],
      EXTERIORS: [
        {
          src: access1,
          label:'Body side moulding',
          title: 'Exterior',
          subtitle: 'Body side moulding',
          description: 'These high quality chrome insert claddings protect the paint job as well as maintains the premium look of your Comet.',
        },
        {
          src: access2,
          label:'Hood Branding',
          title: 'Exterior',
          subtitle: 'Hood Branding',
          description: 'The high quality additions to compliment your car that are bound to turn heads. Specifically designed to add shine and glamour, it comes with high grade chrome quality finish and is very easy to apply.',
        },
        {
          src: access3,
          label: 'Hood Garnish',
          title:'Exterior',
          subtitle: 'Hood Garnish',
          description: 'The high quality additions to compliment your car that are bound to turn heads. Specifically designed to add shine and glamour, it comes with high grade chrome quality finish.',
        },
        {
          src: access4,
          label: 'Integrated Roof Carrier',
          title:'Exterior',
          subtitle: 'Integrated Roof Carrier',
          description: 'Made from High Quality plastic and aluminum making it light in weight. Modern and unique design with precision fitment makes it an integrated part of the Vehicle. Load bearing capacity is of 60kgs.',
        }
      ],
    
      INTERIORS: [
        {
          src: inte1,
          label:'10.25" Integrated Floating Widescreen',
          title: 'Interior',
          subtitle:'10.25" Integrated Floating Widescreen',
          description: 'This car will never cramp your style because it has all it takes to make every drive a TLC session. And one look at its plush, luxurious interiors you’ll know it’s ‘gram worthy.',
        },
        {
          src: inte2,
          label:'Interior Inserts',
          title: "Interior",
          subtitle: 'Interior Inserts',
          description: 'Interior finishes to offer rich and luxurious look to the car, Easy to install.',
        },
        {
          src: inte3,
          label:'Auxilery Battery Pack',
          title: 'Interior',
          subtitle: 'Auxilery Battery Pack',
          description: 'Portable battery station which can be used to charge devices under 300W, such as mini-fridges, TVs, and gaming consoles and perfect for outdoor adventures or emergency backup power.',
        },
        {
          src: inte4,
          label:'Interior Organiser – Front',
          title: 'Interior',
          subtitle: 'Interior Organiser – Front',
          description: 'Front adds extra storage and keeps items secure in your Comet EV.',
        }
      ],
    
    
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0],
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage, screenSize } = this.state;
    const images = this.getTabImages(selectedTab);

    return (
      <Box sx={{ width: '80%', height: '100%',
    
      }}>
        <Box
          sx={{
            height: '90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' } ,
            marginTop:{xs:'15px'},
            
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '95vw',
              maxWidth: '500px',
              height: '280px',
              
            }}
            alt={selectedImage.title}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: {md:'space-around', xs:'start',sm:'start'},
              alignItems:'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-35px', md: '0px' } 
            }}
          >
            <Typography variant="h5"
              sx={{
                width: { xs: '90%', md: '330px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}>
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '300px', md: '330px',sm:'100%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw' ,sm:'2.5vw'},
                marginBottom: '12px',
              }}>
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '270px', md: '350px',sm:'550px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw',sm:'2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
              }}>
              {selectedImage.description}
            </Typography>
          </Box>
        </Box>
        <Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: { xs: 'center', md: 'flex-start' },
    gap: '10px',
    marginTop: { xs: '30px', md: '50px' },
    marginLeft: { xs: '-25px', md: '0px' } 
  }}
>
  {images.map((img, index) => (
    <Box
      key={index}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { md: 'center', xs: 'start' }
      }}
    >
      <img
        src={img.src}
        style={{
          borderRadius: '10px',
          width: '20vw',
          maxWidth: '250px',
          height: 'auto',
          marginTop: { xs: '10px', md: '40px', sm: '20px' }
        }}
        alt={img.label}
        onClick={() => this.handleImageClick(img)}
      />
      <Typography
        sx={{
          fontFamily: 'Roboto-Medium, Arial, sans-serif',
          fontSize: { xs: '3.5vw', md: '1.04167vw' },
          color: '#000',
          paddingTop: '1.04167vw',
          margin: 0,
          display:{md:'flex',xs:'none'},
          textAlign: { xs: 'left', md: 'left' }, 
          width: '100%' 
        }}
      >
        {img.label}
      </Typography>
    </Box>
  ))}
</Box>

      </Box>
    );
  };

  render() {
    const { selectedTab, screenSize } = this.state;
    const tabs = ['LIMITED EDITION','STICKERS', 'LIT PACKS','INTERIORS','EXTERIORS'];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: '20px', md: '0' }, 
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' },
            color: '#cf2230',
            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop: '15px',
          }}
        >
          ACCESSORIES
        </Typography>

        {screenSize <= 768 ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px' }}>
            <Select
              style={{fontWeight:'700'}}
              value={selectedTab}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '75%',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' },
                  color:'black',
                  fontWeight: selectedTab === tab ? 'bolder' : '500',
                  backgroundColor: selectedTab === tab ? '#B9E170' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#B9E170',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display: { md: 'flex', xs: 'none' }, border: '4px solid #B9E170', margin: '20px 0' }} />

        {this.renderContent()}
      </Box>
    );
  }
}

export default Accessories;
