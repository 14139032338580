import React, { Component } from "react";
import { Box, Typography, Button, Divider,Select,FormControl,MenuItem } from '@mui/material';
import ext1 from '../../../images/zs/ext1.webp';
import ext2 from '../../../images/zs/ext2.webp';
import ext3 from '../../../images/zs/ext3.webp';
import ext4 from '../../../images/zs/ext4.webp';
import int1 from '../../../images/zs/int1.webp'; 
import int2 from '../../../images/zs/int2.webp';
import int3 from '../../../images/zs/int3.webp';
import int4 from '../../../images/zs/int4.webp';
import safe1 from '../../../images/zs/safe1.webp';
import safe2 from '../../../images/zs/safe2.webp';
import safe3 from '../../../images/zs/safe3.webp';
import safe4 from '../../../images/zs/safe4.webp';
import adas1 from '../../../images/zs/adas1.webp';
import adas2 from '../../../images/zs/adas2.webp';
import adas3 from '../../../images/zs/adas3.webp';
import adas4 from '../../../images/zs/adas4.webp';
import i1 from '../../../images/zs/i1.webp';
import i2 from '../../../images/zs/i2.webp';
import i3 from '../../../images/zs/i3.webp';
import i4 from '../../../images/zs/i4.jpg';
import comf1 from '../../../images/zs/comfort1.webp';
import comf2 from '../../../images/zs/comfort2.webp';
import comf3 from '../../../images/zs/comfort3.webp';
import comf4 from '../../../images/zs/comfort4.webp';
import ser1 from '../../../images/zs/shield1.jpg'


class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'EXTERIOR',
      isMobile: window.innerWidth <= 900, // Detect initial mobile view
      selectedImage: this.getTabImages('EXTERIOR')[0], 
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };


  getTabImages = (tab) => {
    const tabData = {
      EXTERIOR: [
        {
          src: ext1,
          title: 'NEW ELECTRIC GRILLE DESIGN',
          subtitle: 'EMBRACE THE FUTURISTIC DESIGN',
          description: 'Dynamic lines and a unique style combined with the wide SUV stance gives the ZS EV its progressive look. With new design elements at front and rear, the MG ZS EV offers a highly distinctive appearance on the road.',
        },
        {
          src: ext2,
          title: 'R17 TOMAHAWK HUB DESIGN ALLOY WHEELS',
          subtitle: 'EMBRACE THE FUTURISTIC DESIGN',
          description: 'The MG ZS EV comes with 17-inch Alloy Wheels (43.18cm) (17") with Tomahawk hub design adding to the bold and futuristic look to the SUV.',
        },
        {
          src: ext3,
          title:'FULL LED HAWKEYE HEADLAMPS',
          subtitle: 'EMBRACE THE FUTURISTIC DESIGN',
          description: 'The MG ZS EV comes with full LED Hawkeye headlamps that accentuates its presence on road.',
        },
        {
          src: ext4,
          title:'LED TAIL LAMPS',
          subtitle: 'EMBRACE THE FUTURISTIC DESIGN',
          description: 'The MG ZS EV comes with full LED tail lamps that accentuates its presence on road.',
        }
      ],
      INTERIOR: [
        {
          src: int1,
          title: 'PLUSH INTERIORS - DUAL TONE ICONIC IVORY',
          subtitle: 'COMFORTABLE IN-CABIN EXPERIENCE',
          description: 'Experience comfort in every drive with MG ZS EV which offers plush interiors and is now also available in the Dual Tone Iconic Ivory Interior theme.',
        },
        {
          src: int2,
          title:'PLUSH INTERIORS – DARK GREY',
          subtitle: 'COMFORTABLE IN-CABIN EXPERIENCE',
          description: 'Experience comfort in every drive with MG ZS EV which offers plush interiors in Dark Grey Interior theme.',
        },
        {
          src: int3,
          title: '25.7 cm HD TOUCHSCREEN INFOTAINMENT',
          subtitle: 'COMFORTABLE IN-CABIN EXPERIENCE',
          description: 'The car comes loaded with the largest-in-segment 25.7 cm HD Touchscreen Infotainment that gives you all the information at a single glance and allows you to manage complete vehicle settings with just a touch.',
        },
        {
          src: int4,
          title: 'FULL DIGITAL CLUSTER WITH 17.78cm EMBEDDED LCD SCREEN',
          subtitle: 'COMFORTABLE IN-CABIN EXPERIENCE',
          description: 'The MG ZS EV features a full digital cluster with the largest in segment 17.78 cm embedded LCD screen that makes your drive more seamless.',
        }
      ],
      SAFETY: [
        {
          src: safe1,
          title: '6 AIRBAGS',
          subtitle: 'SAFE FOR THE ENVIRONMENT. SAFE FOR YOU.',
          description: 'MG ZS EV comes with 6 airbags, which include dual front airbags, front-seat side-impact airbags, and side-curtain airbags that work together with the seatbelts. ​',
        },
        {
          src: safe2,
          title: '360° AROUND VIEW CAMERA',
          subtitle: 'SAFE FOR THE ENVIRONMENT. SAFE FOR YOU.',
          description: 'The MG ZS EV is built on the foundation of safety and is loaded with cutting-edge smart technology like the 360-degree all-around view camera with rear parking sensor.',
        },
        {
          src: safe3,
          title:'HILL DESCENT CONTROL (HDC)',
          subtitle: 'SAFE FOR THE ENVIRONMENT. SAFE FOR YOU.',
          description: 'The Hill Descent Control prevents rollback on a slope when you release the brake and switch to the accelerator pedal. So, you will feel safe while driving in the mountains as well.',
        },
        {
          src: safe4,
          title:'ISOFIX CHILD SEAT ANCHOR',
          subtitle: 'SAFE FOR THE ENVIRONMENT. SAFE FOR YOU.',
          description: 'The MG ZS EV is built on the foundation of safety and relies on cutting-edge smart technology.  This ensures that whether the journey is long or short, you are safe at every turn of the path.',
        }
      ],
      ADAS: [
        {
          src: adas1,
          title: 'LANE FUNCTION',
          subtitle: '17 AUTONOMOUS LEVEL-2 FEATURES',
          description: 'Lane Functions like Lane Departure Warning, Emergency Lane Keep, Lane Keep Assist help keep the vehicle within the lane lines to prevent the vehicle from deviation due to distraction. In case of any unintentional lane departure, it warns the driver through visual and acoustic signals.',
        },
        {
          src: adas2,
          title:'SPEED ASSIST SYSTEM (SAS)SPEED ASSIST SYSTEM (SAS)',
          subtitle: '17 AUTONOMOUS LEVEL-2 FEATURES',
          description: 'Speed assist system monitors traffic sign on the road from which it is possible to infer speed limit. The system alerts the driver in case of over speeding. It can also slow down the car to the speed limit automatically in intelligent mode. You can eve set your own speed limit in the manual mode.',
        },
        {
          src: adas3,
          title: 'FRONT COLLISION WARNING (FCW)',
          subtitle: '17 AUTONOMOUS LEVEL-2 FEATURES',
          description: 'Forward Collision Prevention system alerts the driver about a probable collision with a four-wheeler, two-wheeler or a pedestrian when the gap becomes too close. In case the driver takes no action on the alert then the vehicle applies autonomous deceleration. ',
        },
        {
          src: adas4,
          title: 'ADAPTIVE CRUISE CONTROL (ACC)',
          subtitle: '17 AUTONOMOUS LEVEL-2 FEATURES',
          description: 'Adaptive Cruise Control is an advanced version of cruise control and senses the road ahead. It enables the vehicle to control its acceleration and braking to achieve desired speed while maintaining a safe distance from cars ahead.',
        }
      ],
      "i-SMART": [
        {
          src: i1,
          title: 'i-SMART TECHNOLOGY',
          subtitle: '75+ CONNECTED FEATURES',
          description: 'i-SMART technology combines hardware, software, services and applications to make your driving experience easier and smoother. The MG ZS EV offers 75+ connected car features, ushering in an era of convenient connected drives.',
        },
        {
          src: i2,
          title: 'DIGITAL KEY WITH BLUETOOTH® TECHNOLOGY',
          subtitle: '75+ CONNECTED FEATURES',
          description: 'Now lock, unlock, start and even drive the car with a Digital Key. Simply activate it with Bluetooth technology in your smartphone.',
        },
        {
          src: i3,
          title:'INBUILT MAPMYINDIA NAVIGATION MAPS',
          subtitle: '75+ CONNECTED FEATURES',
          description: 'Experience superior navigation with AI-powered maps engine and intelligent voice interaction system.',
        },
        {
          src: i4,
          title:'CHARGING STATION SEARCH',
          subtitle: '75+ CONNECTED FEATURES',
          description: 'Search the charging stations on the in-built maps as well as on the i-SMART app. You can also easily locate nearby charging station by giving a voice command.',
        }
      ],
      COMFORT: [
        {
          src: comf1,
          title:'DUAL PANE PANORAMIC SKY ROOF',
          subtitle: 'COMFORTABLE AND CONVENIENT',
          description: 'MG ZS EV with its Panoramic Sky roof lets you enjoy the ride in the comfort of extra light and fresh air.'
        },
        {
          src: comf2,
          title: "REAR AC VENT",
          subtitle: 'COMFORTABLE AND CONVENIENT',
          description: 'The MG ZS EV offers comfort in the rear-seating zone thanks to the Rear AC Vents that adds to the comfort of rear seat passengers.',
        },
        {
          src: comf3,
          title: 'WIRELESS PHONE CHARGING',
          subtitle: 'COMFORTABLE AND CONVENIENT',
          description: 'Charge your smartphone now on the go with the Wireless Phone Charging function offered in MG ZS EV.',
        },
        {
          src: comf4,
          title: 'DRIVING MODES',
          subtitle: 'COMFORTABLE AND CONVENIENT',
          description: 'The MG ZS EV offers 3 distinct driving modes for a personalized drive. Eco Mode maximizes the range. Normal Mode balances range and driving performance. Sport Mode delivers an exhilarated driving experience. ​',
        }
      ],
      SERVICE: [
        {
          src: ser1,
          title:'MG SHIELD',
          subtitle: 'COMPREHENSIVE OWNERSHIP PACKAGE',
          description: 'MG e-Shield for ZS EV is a thoughtfully designed ownership package that offers assurance and convenience to all ZS EV owners. ',
        },
        
      ],
    };
    
    return tabData[tab] || [];
  };

  handleTabChange = (tab) => {
    const images = this.getTabImages(tab);
    this.setState({
      selectedTab: tab,
      selectedImage: images[0], // Default to first image of the selected tab
    });
  };

  handleImageClick = (image) => {
    this.setState({ selectedImage: image });
  };

  renderContent = () => {
    const { selectedTab, selectedImage } = this.state;
    const images = this.getTabImages(selectedTab);
  
    return (
      <Box sx={{ width: '80%', height: '100%',
    
      }}>
        <Box
          sx={{
            height: '90%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'space-around' },
            alignItems: { xs: 'center', md: 'start' },
            gap: '20px',
            flexWrap: 'no-wrap',
            marginLeft: { xs: '-5px', md: '0px' },
           
          }}
        >
          <img
            src={selectedImage.src}
            style={{
              borderRadius: '10px',
              width: '97vw',
              maxWidth: '500px',
              height: 'auto',
            }}
            alt={selectedImage.title}
          />
  
          <style>
            {`
              @media (max-width: 768px) { /* Tablet and smaller */
                img {
                  width: 80vw; /* Adjust the width for tablet view */
                  max-width: 100%; /* Ensure it doesn't exceed container width */
                }
              }
  
              @media (max-width: 480px) { /* Mobile view */
                img {
                  width: 90vw; /* Increase width for mobile view */
                  max-width: 100%;
                }
              }
            `}
          </style>
  
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: { md: 'space-around', xs: 'start', sm: 'start' },
              alignItems: 'start',
              width: '100%',
              maxWidth: '500px',
              marginLeft: { xs: '-25px', md: '0px' }
            }}
          >
            <Typography
              variant="h5"
              sx={{
                width: { xs: '90%', md: '510px' },
                fontFamily: 'GillSansRegular, Arial, sans-serif',
                color: '#000',
                fontSize: { xs: '4vw', md: '1.5625vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.title}
            </Typography>
            <Typography
              sx={{
                width: { xs: '90%', md: '330px', sm: '90%' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                color: '#6e6e6e',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.5vw' },
                marginBottom: '12px',
              }}
            >
              {selectedImage.subtitle}
            </Typography>
            <Typography
              sx={{
                width: { xs: '100%', md: '460px', lg: '450px' },
                fontFamily: 'Roboto-Regular, Arial, sans-serif',
                fontSize: { xs: '3.5vw', md: '1.04167vw', sm: '2.1vw' },
                color: '#000',
                marginBottom: '2.08333vw',
                paddingTop: '1.30208vw',
                '@media (min-width: 1024px) and (max-width: 1139px)': {
                  width: '280px',
                },
              }}
            >
              {selectedImage.description}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: { xs: 'center', md: 'flex-start' },
            gap: '10px',
            marginTop: { xs: '10px', md: '50px', lg: '20px' },
            marginLeft: { xs: '-10px', md: '0px' },
          }}
        >
          {images.map((img, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: { md: 'center', xs: 'start' }
              }}
            >
              <img
                src={img.src}
                style={{
                  borderRadius: '10px',
                  width: '20vw',
                  maxWidth: '250px',
                  height: 'auto',
                  marginTop: { xs: '10px', md: '40px', sm: '20px' }
                }}
                alt={img.title}
                onClick={() => this.handleImageClick(img)}
              />
             
            </Box>
          ))}
        </Box>
      </Box>
    );
  };
  


  render() {
    const { selectedTab, isMobile } = this.state;
    const tabs = ['EXTERIOR', 'INTERIOR', 'SAFETY', 'ADAS', 'i-SMART', 'COMFORT', 'SERVICE'];

    return (
      <Box
        id="features"
        sx={{
          height: 'auto',
          width: '100%',
          // minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '6vw', md: '2.08333vw' }, 
            color: '#cf2230',
            fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
            paddingBottom: '3.125vw',
            fontWeight: '400',
            marginTop:'20px'
          }}
        >
          A Car That Has It All
        </Typography>

        {isMobile ? (
          <FormControl fullWidth sx={{ width: '80%', marginBottom: '20px', }}>
            <Select
              value={selectedTab}
              style={{fontWeight:'700'}}
              onChange={(e) => this.handleTabChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Tab Selection' }}
            >
              {tabs.map((tab) => (
                <MenuItem key={tab} value={tab}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Box
            sx={{
              height: '15px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            {tabs.map((tab) => (
              <Button
                key={tab}
                onClick={() => this.handleTabChange(tab)}
                sx={{
                  fontFamily: 'GillSansRegular, Arial, sans-serif',
                  fontSize: { xs: '4vw', md: '1.25vw' }, 
                  color:'black',
                  fontWeight: selectedTab === tab ? 'bolder' : '500',
                  backgroundColor: selectedTab === tab ? '#EAE9E7' : 'transparent',
                  '&:hover': {
                    backgroundColor: '#EAE9E7',
                    color: 'black',
                  },
                }}
              >
                {tab}
              </Button>
            ))}
          </Box>
        )}

        <Divider sx={{ width: '80%', display:{md:'flex',xs:'none'},border: '4px solid #E32735', margin: '20px 0' }} />

        {this.renderContent()}

        {/* <Button
          sx={{
            backgroundColor: '#CF2230',
            color: 'white',
            fontWeight: '700',
            fontFamily: 'GillSansSemiBold , sans-serif',
            fontSize: { xs: '3.5vw', md: '0.95vw',sm:'2.2vw' }, 
            cursor: 'pointer',
            marginTop:'30px',
            '&:hover': {
              backgroundColor: 'black',
              color: 'white',
            },
          }}
        >
          Virtual Showroom
        </Button> */}
      </Box>
    );
  }
}

export default Features;
