import React from 'react';
import { Button, Typography, Box, Grid } from '@mui/material';
import Astor from '../../Brochures/Astor.pdf';
import MgAstorBlackstorm from '../../Brochures/AstorBlackstorm.pdf';
import AstorAccessoriesBrochure from '../../Brochures/AstorAccessories.pdf';
import Hector from '../../Brochures/Hector.pdf'
import Hectorsmart from '../../Brochures/Hectori-smart.pdf';
import HectorAdas from '../../Brochures/HectorAdas.pdf';
import HectorShield from '../../Brochures/Shield.pdf';
import HectorBlackstorm from '../../Brochures/HectorBlackstorm.pdf'
import HectorAccessories from '../../Brochures/HectorAccessories.pdf'
import HectorSnowstorm from '../../Brochures/HectorSnowstorm.pdf'
import Hector6s from '../../Brochures/Hector6s.pdf'
import Hector6ssmart from '../../Brochures/hector6ssmart.pdf'
import Hector6sAdas from '../../Brochures/hector6sadas.pdf'
import Hector6sShield from '../../Brochures/hector6sshield.pdf';
import Hector6sAccessories from '../../Brochures/hector6saccess.pdf';
import Hector7s from '../../Brochures/Hector7s.pdf'
import Hector7ssmart from '../../Brochures/hector7ssmart.pdf'
import Hector7sAdas from '../../Brochures/hector7sadas.pdf'
import Hector7sShield from '../../Brochures/hector7sshield.pdf';
import Hector7sAccessories from '../../Brochures/hector7saccess.pdf';
import Zs from '../../Brochures/zsev.pdf'
import Zssmart from '../../Brochures/zssmart.pdf'
import ZsAccessories from '../../Brochures/zsaccess.pdf';
import Gloster from '../../Brochures/gloster.pdf'
import Glosterblackstorm from '../../Brochures/glosterblack.pdf';
import GlosterAccessories from '../../Brochures/glosteraccess.pdf';
import Glosterstormseries from '../../Brochures/glosterstorm.pdf'
import Comet from '../../Brochures/comet.pdf'
import Windsor from '../../Brochures/windsor.pdf';
import WindsorAccessories from '../../Brochures/windsoraccess.pdf'
const BrochurePage = () => {
  return (
    <Box textAlign="center" sx={{ p: 4, marginTop: '90px' }}>
      <Typography variant="h4" sx={{fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",
               color:'#D02630',}} gutterBottom>MG ASTOR</Typography>
      <Grid container spacing={2} justifyContent="center" mb={4}>
        <Grid item xs={12} sm={2.9} md={1.75}>
          <Button
            variant="contained"
            color="error"
            href={Astor}
            download="MG_Astor_Brochure.pdf"
            sx={{fontWeight:'800',fontSize:'10px'}}
          >
            MG Astor Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={2.9} md={1.75}>
          <Button
            variant="contained"
            color="error"
            href={MgAstorBlackstorm}
            download="MG_Astor_Blackstorm.pdf"
            sx={{fontWeight:'800',fontSize:'10px'}}
          >
            MG Astor Blackstorm
          </Button>
        </Grid>
        <Grid item xs={12} sm={2.9} md={1.75}>
          <Button
            variant="contained"
            color="error"
            href={AstorAccessoriesBrochure}
            download="Accessories_Brochure.pdf"
            sx={{fontWeight:'800',fontSize:'10px'}}
          >
            Accessories Brochure
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",color:'#D02630',marginTop:'30px'}} gutterBottom>MG HECTOR</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={3} md={1.58}>
          <Button
            variant="contained"
            color="error"
            href={Hector}
            download="MG_Hector_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            MG Hector Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.45}>
          <Button
            variant="contained"
            color="error"
            href={Hectorsmart}
            download="i-Smart_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            i-Smart Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.29}>
          <Button
            variant="contained"
            color="error"
            href={HectorAdas}
            download="ADAS_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            ADAS Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.52}>
          <Button
            variant="contained"
            color="error"
            href={HectorShield}
            download="Shield_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            MG Shield Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3.7} md={2.15}>
          <Button
            variant="contained"
            color="error"
            href={HectorBlackstorm}
            download="HectorBlackstorm_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Hector Blackstorm Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.65}>
          <Button
            variant="contained"
            color="error"
            href={HectorAccessories}
            download="Accessories_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Accessories Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3.7} md={2.15}>
          <Button
            variant="contained"
            color="error"
            href={HectorSnowstorm}
            download="HectorSnowstorm_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Hector Snowstorm Brochure
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",color:'#D02630',marginTop:'30px'}} gutterBottom>MG HECTOR Plus 6 Seater</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={3} md={1.88}>
          <Button
            variant="contained"
            color="error"
            href={Hector6s}
            download="MG_Hector_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Hector Plus Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.45}>
          <Button
            variant="contained"
            color="error"
            href={Hector6ssmart}
            download="i-Smart_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            i-Smart Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.29}>
          <Button
            variant="contained"
            color="error"
            href={Hector6sAdas}
            download="ADAS_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            ADAS Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.52}>
          <Button
            variant="contained"
            color="error"
            href={Hector6sShield}
            download="Shield_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            MG Shield Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.65}>
          <Button
            variant="contained"
            color="error"
            href={Hector6sAccessories}
            download="Accessories_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Accessories Brochure
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",color:'#D02630',marginTop:'30px'}} gutterBottom>MG HECTOR Plus 7 Seater</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={2.9} md={1.88}>
          <Button
            variant="contained"
            color="error"
            href={Hector7s}
            download="MG_Hector_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Hector Plus Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={2.5} md={1.45}>
          <Button
            variant="contained"
            color="error"
            href={Hector7ssmart}
            download="i-Smart_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            i-Smart Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={2.5} md={1.29}>
          <Button
            variant="contained"
            color="error"
            href={Hector7sAdas}
            download="ADAS_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            ADAS Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.52}>
          <Button
            variant="contained"
            color="error"
            href={Hector7sShield}
            download="Shield_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            MG Shield Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.65}>
          <Button
            variant="contained"
            color="error"
            href={Hector7sAccessories}
            download="Accessories_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Accessories Brochure
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",color:'#D02630',marginTop:'30px'}} gutterBottom>MG ZS EV</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={3} md={1.28}>
          <Button
            variant="contained"
            color="error"
            href={Zs}
            download="MG_Hector_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            ZS EV Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.45}>
          <Button
            variant="contained"
            color="error"
            href={Zssmart}
            download="i-Smart_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            i-Smart Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.65}>
          <Button
            variant="contained"
            color="error"
            href={ZsAccessories}
            download="Accessories_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Accessories Brochure
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",color:'#D02630',marginTop:'30px'}} gutterBottom>MG Gloster</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={3} md={1.78}>
          <Button
            variant="contained"
            color="error"
            href={Gloster}
            download="MG_Gloster_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            MG Gloster Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.85}>
          <Button
            variant="contained"
            color="error"
            href={Glosterblackstorm}
            download="GlosterBlackstorm_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
           MG Gloster Blackstorm
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.65}>
          <Button
            variant="contained"
            color="error"
            href={GlosterAccessories}
            download="Accessories_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Accessories Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={2.05}>
          <Button
            variant="contained"
            color="error"
            href={Glosterstormseries}
            download="GlosterStormseries_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
           Gloster Storm Series
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",color:'#D02630',marginTop:'30px'}} gutterBottom>MG COMET EV</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={3} md={1.78}>
          <Button
            variant="contained"
            color="error"
            href={Comet}
            download="MG_Comet_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            MG Comet EV Brochure
          </Button>
        </Grid>
       
      </Grid>

      <Typography variant="h4" sx={{fontFamily: "Gill Sans, Gill Sans MT, Calibri, sans-serif",color:'#D02630',marginTop:'30px'}} gutterBottom>MG WINDSOR EV</Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={3} md={1.78}>
          <Button
            variant="contained"
            color="error"
            href={Windsor}
            download="MG_Windsor_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Windsor EV Brochure
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1.78}>
          <Button
            variant="contained"
            color="error"
            href={WindsorAccessories}
            download="WindsorAccessories_Brochure.pdf"
            sx={{ fontWeight: '800',fontSize:'10px' }}
          >
            Accessories Brochure
          </Button>
        </Grid>
       
      </Grid>

    </Box>
  );
};

export default BrochurePage;
