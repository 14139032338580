import React, { Component } from "react";
import { connect } from "react-redux";
import Viewpage from "../Components/Viewpage";

import {
  viewOldCarById,
  viewOverviewById,
  viewSpecificationById,
  viewFeturesById,
} from "../action";
export class Controller extends Component {
  render() {
    return <Viewpage {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewOldCarById: (token, user_id, id) => {
      dispatch(viewOldCarById(token, user_id, id));
    },

    viewOverviewById: (token, user_id, id) => {
      dispatch(viewOverviewById(token, user_id, id));
    },

    viewSpecificationById: (token, user_id, id) => {
      dispatch(viewSpecificationById(token, user_id, id));
    },
    viewFeturesById: (token, user_id, id) => {
      dispatch(viewFeturesById(token, user_id, id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
