import React, { Component } from "react";
import { connect } from "react-redux";
import Overview from "../Component/overview";

import { viewOverviewByOldCarId ,viewOldCarById,viewSpecificationByOldCarId,viewFeturesById,viewCarsByBrand} from "../../Preownedcars/action";
export class Controller extends Component {
  render() {
    return <Overview {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    client: store.client,
    oldcar: store.oldcar,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    viewOverviewByOldCarId: (oldcar_id) => {
      dispatch(viewOverviewByOldCarId(oldcar_id));
    },
    viewOldCarById: (oldcar_id) => {
        dispatch(viewOldCarById(oldcar_id));
      },
      viewFeturesById: (oldcar_id) => {
        dispatch(viewFeturesById(oldcar_id));
      },
      viewSpecificationByOldCarId: (oldcar_id) => {
          dispatch(viewSpecificationByOldCarId(oldcar_id));
        },
        viewCarsByBrand: (id,brand) => {
          dispatch(viewCarsByBrand(id,brand));
        },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);
